import { useToggle } from 'react-use';

import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import CalendarControlsProvider from 'shared/filters/CalendarControls/CalendarControlsProvider';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import StatusFilterProvider from 'shared/filters/StatusFilter/StatusFilterProvider';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import PriorityFilter, {
  PriorityFilterProvider,
} from 'shared/filters/PriorityFilter';
import UserFilter, { UserFilterProvider } from 'shared/filters/UserFilter';
import TimelineFilter, {
  TimelineFilterProvider,
} from 'shared/filters/TimelineFilter';
import ThemeFilter, { ThemeFilterProvider } from 'shared/filters/ThemeFilter';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import CalendarControls from 'shared/filters/CalendarControls';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import FiltersButton from 'shared/filters/FiltersButton';
import StatusFilter from 'shared/filters/StatusFilter';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';

import StrategyBoardContent from './StrategyBoardContent';
import StrategyBoardHeader from './StrategyBoardHeader';
import { useStrategyBoard } from './StrategyBoardProvider';
import StrategyBoardProvider from './StrategyBoardProvider/StrategyBoardProvider';
import StrategyBoardAskSophieButton from './StrategyBoardAskSophieButton';
import {
  StrategyBoardViewSettingsButton,
  StrategyBoardViewSettingsProvider,
} from './StrategyBoardViewSettings';

const StrategyBoard = () => {
  const { isStrategyBoardLoading } = useStrategyBoard();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  if (isStrategyBoardLoading) return <Spinner.Circle />;

  return (
    <Layout.Content variant={'contained'} hasPadding={false}>
      <StrategyBoardHeader>
        <CalendarControls />
        <CompletedFilterButton />
        <FiltersButton>
          <ThemeFilter />
          <StatusFilter />
          <PriorityFilter />
          <UserFilter />
          <TimelineFilter />
          <CollaboratingTeamsFilter />
        </FiltersButton>
        <ToggleExpandIconButton
          isExpanded={areAllSectionsExpanded}
          onToggle={toggleAreAllSectionsExpanded}
        />
        <StrategyBoardViewSettingsButton />
        <StrategyBoardAskSophieButton />
      </StrategyBoardHeader>

      <StrategyBoardContent areAllSectionsExpanded={areAllSectionsExpanded} />
    </Layout.Content>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ChipGroupProvider>
        <CollaboratingTeamsFilterProvider storeToSession={true}>
          <ThemeFilterProvider storeToSession={true}>
            <StatusFilterProvider storeToSession={true}>
              <PriorityFilterProvider storeToSession={true}>
                <UserFilterProvider storeToSession={true}>
                  <TimelineFilterProvider storeToSession={true}>
                    <StrategyBoardProvider>
                      <StrategyBoardViewSettingsProvider>
                        <StrategyBoard />
                      </StrategyBoardViewSettingsProvider>
                    </StrategyBoardProvider>
                  </TimelineFilterProvider>
                </UserFilterProvider>
              </PriorityFilterProvider>
            </StatusFilterProvider>
          </ThemeFilterProvider>
        </CollaboratingTeamsFilterProvider>
      </ChipGroupProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
