import type { PropsWithChildren } from 'react';

import { useTeamAdapter } from 'team/TeamAdapter';

import SelectThemeInputForOrgProvider from '../SelectThemeInputForOrgProvider';
import SelectThemeInputForOrgUnitProvider from '../SelectThemeInputForOrgUnitProvider';

const SelectThemeInputProvider = ({ children }: PropsWithChildren) => {
  const { teamAdapter } = useTeamAdapter();

  if (teamAdapter.isOrgUnit) {
    return (
      <SelectThemeInputForOrgUnitProvider orgUnitId={teamAdapter.keyArg}>
        {children}
      </SelectThemeInputForOrgUnitProvider>
    );
  }
  return (
    <SelectThemeInputForOrgProvider>{children}</SelectThemeInputForOrgProvider>
  );
};

export default SelectThemeInputProvider;
