import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { ObjectivesSort } from 'actions/actions.type';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';

export type StrategyObjectivesActionsContextValue = {
  isStrategyActionsLoading: boolean;
  objectives: Maybe<ActionsObjectiveFragment[]>;
  objectivesSort: ObjectivesSort;
  objectivesWithActions: Maybe<ActionsObjectiveFragment[]>;
  setObjectivesSort: Dispatch<SetStateAction<ObjectivesSort>>;
};

export const StrategyObjectivesActionsContext = createContext<
  StrategyObjectivesActionsContextValue | undefined
>(undefined);
