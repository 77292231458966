import type { NavigateFunction } from 'react-router-dom';

import type { TeamAdapter } from 'team/TeamAdapter';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import type { StrategyBoardItem } from 'strategy/StrategyBoard/StrategyBoard.type';

export const getStatusIndicator = (item: StrategyBoardItem) => {
  switch (item.__typename) {
    case 'Objective':
      return item.currentObjectiveStatus?.statusIndicator;
    case 'Metric':
      return item.currentMetricStatus?.statusIndicator;
    case 'Initiative':
      return item.currentInitiativeStatus?.statusIndicator;
    case 'Action':
      return item.currentStatus?.statusIndicator;
  }
};

export const onStrategyItemPress = (
  navigate: NavigateFunction,
  teamAdapter: TeamAdapter,
  onEditAction: () => void,
  item: StrategyBoardItem,
) => {
  switch (item.__typename) {
    case 'Objective':
      return navigate(
        objectiveRoutesPaths.root({
          params: {
            teamSlug: teamAdapter.toParam(),
            objectiveId: item.id,
          },
        }),
      );
    case 'Metric':
      return navigate(
        objectiveRoutesPaths.metrics.metric.root({
          params: {
            teamSlug: teamAdapter.toParam(),
            objectiveId: item.objective?.id || '',
            metricId: item.id,
          },
        }),
      );
    case 'Initiative':
      return navigate(
        objectiveRoutesPaths.initiatives.initiative.root({
          params: {
            teamSlug: teamAdapter.toParam(),
            objectiveId: item.objective?.id || '',
            initiativeId: item.id,
          },
        }),
      );
    case 'Action':
      return onEditAction();
  }
};
