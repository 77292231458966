import { NavLink } from 'react-router-dom';
import type { ComponentType, SVGProps } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import type { LinkTabItem } from '../LinkTabs.type';
import styles from '../LinkTabs.module.scss';

type LinkTabProps = {
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  item: LinkTabItem;
};

const LinkTab = ({ item }: LinkTabProps) => {
  const { icon: Icon } = item;

  return (
    <>
      <NavLink
        to={item.to || '-'}
        end={item.end}
        className={({ isActive }) =>
          cn(styles.navLink, {
            [styles.navLinkActive]: isActive || item.isActive,
          })
        }
        key={item.name}
        preventScrollReset={true}
        onClick={(event) => {
          if (item.onPress) {
            event.preventDefault();
            item.onPress();
          }
        }}
      >
        <span className={styles.navLinkContent}>
          {Icon && <Icon className={styles.icon} />}
          {item.name}
        </span>
      </NavLink>

      <motion.div className={styles.line} />
    </>
  );
};

export default LinkTab;
