import { useMemo } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { TeamAdapter } from 'team/TeamAdapter';

import { useUser } from './UserProvider';

export default function useUserPrimaryTeamAdapter() {
  const { activeOrg } = useActiveOrg();
  const { user } = useUser();

  return useMemo(
    () =>
      user.primaryOrgUnit
        ? TeamAdapter.fromOrgUnit(user.primaryOrgUnit, activeOrg.orgKey)
        : TeamAdapter.fromOrg(activeOrg),
    [activeOrg, user.primaryOrgUnit],
  );
}
