import { useMemo } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { TeamAdapter } from 'team/TeamAdapter';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useUser } from 'user/UserProvider';

type MyTeamRoutePathReturnType = {
  isMatch: boolean;
  routePath: string;
};

export const useMyTeamRoutePath = (): MyTeamRoutePathReturnType => {
  const { user } = useUser();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const teamAdapter = useMemo(
    () =>
      user.primaryOrgUnit && user.org
        ? TeamAdapter.fromOrgUnit(user.primaryOrgUnit, user.org.orgKey)
        : TeamAdapter.fromOrg(activeOrg),
    [activeOrg, user.org, user.primaryOrgUnit],
  );

  const routePath = teamRoutesPaths.root({
    params: { teamSlug: teamAdapter.toParam() },
  });

  const isMatch =
    !user.isInActiveOrg || orgUnit?.id === user.primaryOrgUnit?.id;

  return { isMatch, routePath };
};
