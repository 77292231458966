import { useNavigate, useSearchParams, Navigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import EditStrategyWizard from 'strategy/EditStrategyWizard';

import { useStrategyRoom } from '../StrategyRoomProvider';

const StrategyRoomEditStrategy = () => {
  const [searchParams] = useSearchParams();
  const strategyId = searchParams.get('strategyId');

  const navigate = useNavigate();

  const { refetchStrategies } = useStrategyRoom();

  const handleClose = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const handleSuccess = useCallback(() => {
    refetchStrategies();
    handleClose();
  }, [refetchStrategies, handleClose]);

  const strategy = useMemo(() => {
    if (strategyId) {
      return {
        id: strategyId,
      };
    }
    return undefined;
  }, [strategyId]);

  if (strategy) {
    return (
      <EditStrategyWizard
        strategy={strategy}
        isOpen={true}
        onClose={handleClose}
        onSuccess={handleSuccess}
      />
    );
  }

  return <Navigate to={'../'} />;
};

export default StrategyRoomEditStrategy;
