import { useNavigate } from 'react-router-dom';
import type { To } from 'react-router-dom';
import type { PropsWithChildren } from 'react';
import { useCallback } from 'react';
import styled from '@emotion/styled';

import StatusMark from 'shared/status/StatusMark';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import UserAvatar from 'user/UserAvatar';
import type { UserProfile } from 'user/user.type';
import { transientOptions } from 'shared/utils/emotion.utils';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import type { OrgUnitForTeamImageFragment } from 'orgUnit/OrgUnit.graphql.generated';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';

import Flex from '../Flex';

const Row = styled('li', transientOptions)<{ $hasContent: boolean }>`
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 8px;
  display: flex;
  height: 45px;
  overflow: hidden;
  padding: 7.5px 10px 7.5px 15px;
  align-items: center;
  gap: 8px;
  cursor: ${(props) => (props.$hasContent ? 'pointer' : 'default')};

  &:hover {
    background-color: ${({ $hasContent, theme }) =>
      $hasContent ? theme.color.hoverLight : theme.color.white};
  }
`;

const NameContainer = styled.div`
  flex-grow: 1;
  align-items: center;
  display: flex;
  gap: 8px;
  min-width: 0; /* Ensure flex items can shrink */
`;

const NameText = styled('div', transientOptions)<{ $hasContent: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ $hasContent, theme }) =>
    $hasContent ? 'inherit' : theme.color.neutral1};

  &:hover {
    text-decoration: ${(props) => (props.$hasContent ? 'underline' : 'none')};
  }
`;

type Props = PropsWithChildren<{
  className?: string;
  linkTo?: To;
  orgUnit?: OrgUnitForTeamImageFragment & OrgUnitWithAuthorizedActions;
  statusIndicator?: Maybe<StatusIndicator>;
  textContent: Maybe<string>;
  user?: Maybe<UserProfile>;
}>;

const OverviewRow = ({
  user,
  orgUnit,
  linkTo,
  textContent,
  statusIndicator,
  children,
}: Props) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    () => linkTo && navigate(linkTo),
    [linkTo, navigate],
  );

  const hasContent = !!linkTo;

  return (
    <Row onClick={handleClick} $hasContent={hasContent}>
      {linkTo && <StatusMark statusIndicator={statusIndicator} />}

      <NameContainer>
        <NameText $hasContent={hasContent}>{textContent}</NameText>
        {children}
      </NameContainer>

      <Flex gap={4}>
        {linkTo && <TeamAvatar orgUnit={orgUnit} size={'nano'} />}

        {user && <UserAvatar user={user} />}
      </Flex>
    </Row>
  );
};

export default OverviewRow;
