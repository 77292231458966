import { ReactComponent as STRATEGY_ANALYSIS } from 'shared/static/assessment-category/strategy-analysis.svg';
import { ReactComponent as STRESS_TEST } from 'shared/static/assessment-category/stress-test.svg';
import { ReactComponent as RISK } from 'shared/static/assessment-category/risk.svg';
import { ReactComponent as FUTURE } from 'shared/static/assessment-category/future.svg';
import { ReactComponent as FINANCIAL } from 'shared/static/assessment-category/financial.svg';
import { ReactComponent as SUSTAINABILITY } from 'shared/static/assessment-category/sustainability.svg';

const imageByAssessmentCategory = {
  STRATEGY_ANALYSIS,
  STRESS_TEST,
  RISK,
  FUTURE,
  FINANCIAL,
  SUSTAINABILITY,
};

export default imageByAssessmentCategory;
