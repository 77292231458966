import type {
  PromptKey,
  StrategyAssessmentType,
} from 'types.graphql.generated';

const mainPromptKeyByAssessmentType: Record<StrategyAssessmentType, PromptKey> =
  {
    PESTEL: 'AI_ANALYSIS_PESTEL',
    PORTERS_FIVE_FORCES: 'AI_ANALYSIS_PORTERS_FIVE_FORCES',
    CUSTOMER_SEGMENTATION: 'AI_ANALYSIS_CUSTOMER_SEGMENTATION_FRAMEWORK',
    DOUBLE_MATERIALITY: 'AI_ANALYSIS_DOUBLE_MATERIALITY',
    STAKEHOLDER_ANALYSIS: 'AI_ANALYSIS_STAKEHOLDER_ANALYSIS',
    ANSOFF: 'AI_ANALYSIS_ANSOFF_MATRIX',
    BLUE_OCEAN: 'AI_ANALYSIS_BLUE_OCEAN',
    VRIO: 'AI_ANALYSIS_VRIO',
    COMPETITIVE_STRATEGIES: 'AI_ANALYSIS_COMPETITIVE_STRATEGIES',
    M_AND_A: 'AI_ANALYSIS_M_AND_A',
    VALUE_CHAIN: 'AI_ANALYSIS_VALUE_CHAIN',
    PLAYING_TO_WIN: 'AI_ANALYSIS_PLAYING_TO_WIN',
    CUSTOMER_JOURNEY: 'AI_ANALYSIS_CUSTOMER_JOURNEY',
    THREE_HORIZONS: 'AI_ANALYSIS_THREE_HORIZONS',
    SWOT: 'AI_ANALYSIS_SWOT',
    BSC_SWOT: 'AI_ANALYSIS_BSC_SWOT',
    GE_PORTFOLIO_ANALYSIS: 'AI_ANALYSIS_PORTFOLIO_ANALYSIS',
    BSC_STRATEGY_MAP: 'AI_ANALYSIS_BSC_STRATEGY_MAP',
    BSC_STRATEGY_BOARD: 'AI_ANALYSIS_BSC_STRATEGY_BOARD',
    STRATEGY_DIAMOND: 'AI_ANALYSIS_STRATEGY_DIAMOND',
    BUSINESS_CANVAS: 'AI_ANALYSIS_BUSINESS_CANVAS',
    MCKINSEY_7S: 'AI_ANALYSIS_MCKINSEY_7S',
    RISK_FRAMEWORKS: 'AI_ANALYSIS_RISK_FRAMEWORK',
    STRATEGIC_RISKS: 'AI_ANALYSIS_STRATEGIC_RISKS',
    OBJECTIVE_RISKS: 'AI_ANALYSIS_OBJECTIVE_RISKS',
    RISK_MAP: 'AI_ANALYSIS_RISK_MAP',
    UNSDG: 'AI_ANALYSIS_UNSDG',
    ESG: 'AI_ANALYSIS_ESG',
    OBJECTIVES: 'AI_ANALYSIS_SUSTAINABILITY_STRATEGY_MAP',
    FRAMEWORKS: 'AI_ANALYSIS_SUSTAINABILITY_FRAMEWORKS',
    TAXONOMY: 'AI_ANALYSIS_EU_TAXONOMY',
    CSRD: 'AI_ANALYSIS_CSRD',
    TCFD: 'AI_ANALYSIS_TCFD',
    SB_TI: 'AI_ANALYSIS_SBTI',
    GRI: 'AI_ANALYSIS_GRI',
    TWO_BY_TWO: 'AI_ANALYSIS_TWO_BY_TWO_SCENARIO',
    FUTURE_OPPORTUNITIES: 'AI_ANALYSIS_FUTURE_OPPORTUNITIES',
    FINANCIAL_HEALTH: 'AI_ANALYSIS_FINANCIAL_HEALTH',
    FINANCIAL_BENCHMARK: 'AI_ANALYSIS_FINANCIAL_BENCHMARK',
    REVENUE_ANALYSIS: 'AI_ANALYSIS_REVENUE_STREAMS',
    FINANCIAL_GROWTH: 'AI_ANALYSIS_FINANCIAL_GROWTH',
    FINANCIAL_RISK: 'AI_ANALYSIS_FINANCIAL_RISK',
    STRATEX: 'AI_ANALYSIS_STRATEX',
  };

export default mainPromptKeyByAssessmentType;
