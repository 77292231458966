import type { AssessmentCategory } from 'assessment/assessment.type';

import imageByAssessmentCategory from './imageByAssessmentCategory';

export type AssessmentImageProps = {
  type: AssessmentCategory;
};

const AssessmentCategoryImage = ({ type }: AssessmentImageProps) => {
  const Image = imageByAssessmentCategory[type];

  return <Image />;
};

export default AssessmentCategoryImage;
