import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FetchResult } from '@apollo/client';
import { useMap } from 'react-use';
import { useErrorHandler } from 'react-error-boundary';
import { isAfter, isSameDay } from 'date-fns';
import { sortBy } from 'lodash';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { InitialStep } from 'shared/components/Steps';
import Steps, { useSteps } from 'shared/components/Steps';
import type { MetricStatus, MetricStatusInput } from 'types.graphql.generated';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import { useToasts } from 'shared/toast/useToasts';
import { useTeamAdapter } from 'team/TeamAdapter';
import { withSteps } from 'shared/components/Steps/withSteps';
import { initializeComments } from 'shared/utils/textItem.utils';
import PreviousInsightsProvider from 'status/PreviousInsightsSidecard/PreviousInsightsProvider';

import MetricStatusWizardScore from './MetricStatusWizardScore';
import MetricStatusWizardStatus from './MetricStatusWizardStatus';
import MetricStatusWizardComments from './MetricStatusWizardComments';
import MetricStatusWizardControls from './MetricStatusWizardControls';
import type {
  MetricStatusValues,
  MetricStatusWizardStepId,
} from './MetricStatusWizard.type';
import { resolveMetricStatusInput } from './MetricStatusWizard.utils';
import type { MetricStatusWizardQuery } from './MetricStatusWizard.graphql.generated';
import { useMetricStatusWizardQuery } from './MetricStatusWizard.graphql.generated';

const steps: Record<MetricStatusWizardStepId, InitialStep> = {
  score: {
    id: 'score',
    nameTranslationKey: 'metric.metricStatusWizard.steps.score.name',
    form: 'metricStatusWizardScoreForm',
  },
  status: {
    id: 'status',
    nameTranslationKey: 'metric.metricStatusWizard.steps.status.name',
    form: 'metricStatusWizardStatusForm',
  },
  comments: {
    id: 'comments',
    nameTranslationKey: 'metric.metricStatusWizard.steps.comments.name',
    form: 'metricStatusWizardCommentsForm',
  },
};

export type MetricStatusForStatusWizard = Pick<
  MetricStatus,
  | 'id'
  | 'statusValue'
  | 'forecastValue'
  | 'statusDateTime'
  | 'statusIndicator'
  | 'comment'
  | 'complete'
  | 'successComments'
  | 'challengeComments'
  | 'actionComments'
>;

type MetricStatusWizardProps = {
  buttonLabel: string;
  completeStatus?: boolean;
  headerTitle: string;
  metricId: string;
  onSubmit: (input: MetricStatusInput) => Promise<FetchResult>;
  status?: MetricStatusForStatusWizard;
} & Pick<ModalProps, 'onClose' | 'isOpen'>;

const MetricStatusWizard = ({
  metricId,
  status,
  isOpen,
  onClose,
  onSubmit,
  completeStatus = false,
  headerTitle,
  buttonLabel,
}: MetricStatusWizardProps) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const { addToast } = useToasts();

  const { goToNextStep, submitSteps, resetSteps } = useSteps();

  const onError = useErrorHandler();

  const { data } = useMetricStatusWizardQuery({
    variables: { metricId },
    onError,
    skip: !isOpen,
  });

  const initialValues: MetricStatusValues = {
    score: {
      status: status?.statusValue || null,
      forecast: status?.forecastValue || null,
      date: status?.statusDateTime || new Date(),
    },
    status: {
      statusIndicator: status?.statusIndicator.value || '',
      comment: status?.comment || '',
      complete: status?.complete || completeStatus,
    },
    comments: {
      successes: initializeComments(status?.successComments),
      challenges: initializeComments(status?.challengeComments),
      actions: initializeComments(status?.actionComments),
    },
  };

  const [values, { set: setValues, setAll: setAllValues }] =
    useMap<MetricStatusValues>(initialValues);

  useEffect(() => {
    resetSteps();
    setAllValues(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChangeScore = useCallback(
    (values: MetricStatusValues['score']) => setValues('score', values),
    [setValues],
  );

  const handleChangeStatus = useCallback(
    (values: MetricStatusValues['status']) => setValues('status', values),
    [setValues],
  );

  const handleChangeComments = useCallback(
    (values: MetricStatusValues['comments']) => setValues('comments', values),
    [setValues],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);

    submitSteps(
      async () => {
        try {
          const result = await onSubmit(
            resolveMetricStatusInput({ status, values, metricId, teamAdapter }),
          );

          if (!result.errors) {
            addToast({
              children: t('metric.metricStatusWizard.successToast'),
              variant: 'success',
            });
          }
        } finally {
          setIsSubmitting(false);
        }
      },
      () => setIsSubmitting(false),
    );
  }, [
    addToast,
    metricId,
    onSubmit,
    status,
    submitSteps,
    t,
    teamAdapter,
    values,
  ]);

  if (!data) return null;

  const currentTarget = getCurrentTarget(values.score?.date, data.metric);

  return (
    <Modal
      heading={headerTitle}
      isOpen={isOpen}
      onClose={onClose}
      size={'full'}
      scrollType={'outer'}
      hasBackground={false}
      renderHeaderContent={() => <Steps.Navigation />}
      renderFooterContent={() => (
        <MetricStatusWizardControls
          isLoading={isSubmitting}
          buttonLabel={buttonLabel}
        />
      )}
    >
      <Steps.Item id={'score'}>
        {({ onStepError, onStepComplete }) => (
          <PreviousInsightsProvider
            objectiveId={data.metric.objective?.id}
            metricId={metricId}
          >
            <MetricStatusWizardScore
              id={steps.score.form}
              initialValues={values.score}
              onChange={handleChangeScore}
              onSubmit={(_values, _formikHelpers, submitOptions) => {
                onStepComplete();
                if (submitOptions.goToNextStep) {
                  goToNextStep();
                }
              }}
              onError={onStepError}
              metric={data.metric}
              currentTarget={currentTarget}
            />
          </PreviousInsightsProvider>
        )}
      </Steps.Item>
      <Steps.Item id={'status'}>
        {({ onStepComplete, onStepError }) => (
          <PreviousInsightsProvider
            objectiveId={data.metric.objective?.id}
            metricId={data.metric.id}
          >
            <MetricStatusWizardStatus
              id={steps.status.form}
              initialValues={values.status}
              onChange={handleChangeStatus}
              onSubmit={(_values, _formikHelpers, submitOptions) => {
                onStepComplete();
                if (submitOptions.goToNextStep) {
                  goToNextStep();
                }
              }}
              onError={onStepError}
              metric={data.metric}
              statusDate={values.score?.date}
              statusValue={values.score?.status || null}
              forecastValue={values.score?.forecast || null}
              completeStatus={completeStatus}
              currentTarget={currentTarget}
            />
          </PreviousInsightsProvider>
        )}
      </Steps.Item>
      <Steps.Item id={'comments'}>
        {({ onStepComplete, onStepError }) => (
          <PreviousInsightsProvider
            objectiveId={data.metric.objective?.id}
            metricId={metricId}
            formId={steps.comments.form}
          >
            <MetricStatusWizardComments
              id={steps.comments.form}
              initialValues={values.comments}
              onChange={handleChangeComments}
              onSubmit={(_values, _formikHelpers, submitOptions) => {
                onStepComplete();
                if (submitOptions.submitWizard) {
                  handleSubmit();
                }
              }}
              onError={onStepError}
              metric={{
                ...data.metric,
                ...(values.status?.statusIndicator && {
                  currentMetricStatus: {
                    statusIndicator: new StatusIndicator(
                      values.status?.statusIndicator,
                    ),
                  },
                }),
              }}
            />
          </PreviousInsightsProvider>
        )}
      </Steps.Item>
    </Modal>
  );
};

export default withSteps(MetricStatusWizard, Object.values(steps));

function getCurrentTarget(
  statusDate: Date | null | undefined,
  metric: MetricStatusWizardQuery['metric'],
) {
  if (!statusDate) return;

  return sortBy(metric.periodicTargets, (target) =>
    target.targetDate.getTime(),
  ).find(
    (target) =>
      isSameDay(target.targetDate, statusDate) ||
      isAfter(target.targetDate, statusDate),
  );
}
