import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import {
  assessmentCategories,
  type AssessmentCategory,
} from 'assessment/assessment.type';
import Flex from 'shared/components/Flex';
import type { StrategyAssessmentType } from 'types.graphql.generated';
import assessmentTypesByCategory from 'assessment/assessmentTypesByCategory';
import LinkTabs from 'shared/components/LinkTabs';
import { getAssessmentCategoryName } from 'assessment/assessment.utils';

import AssessmentTypeCard from './AssessmentTypeCard';
import SophieGuideBanner from './SophieGuideBanner';

type Props = {
  category: AssessmentCategory;
  setSelectedCategory: (category: AssessmentCategory) => void;
  setSelectedType: (type: StrategyAssessmentType) => void;
};

const AssessmentTypeSelection = ({
  category,
  setSelectedCategory,
  setSelectedType,
}: Props) => {
  const { t } = useTranslation();

  const types = assessmentTypesByCategory[category];

  const tabItems = useMemo(() => {
    return assessmentCategories.map((eachCategory) => ({
      key: eachCategory,
      name: getAssessmentCategoryName(t, eachCategory),
      onPress: () => setSelectedCategory(eachCategory),
      isActive: category === eachCategory,
    }));
  }, [category, setSelectedCategory, t]);

  return (
    <Flex direction={'column'} gap={40}>
      <SophieGuideBanner
        header={t('assessment.typeSelection.header')}
        content={t('assessment.typeSelection.content')}
      />

      <LinkTabs items={tabItems} />
      <Flex wrap={'wrap'} gap={16}>
        {types.map((type) => (
          <AssessmentTypeCard
            key={type}
            type={type}
            onPress={() => setSelectedType(type)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default AssessmentTypeSelection;
