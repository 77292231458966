import { useCallback } from 'react';
import type { Key } from 'react-aria-components';
import { useTranslation } from 'react-i18next';

import { useUserFilter } from './useUserFilter';
import UserFilterRow from './UserFilterRow';
import FilterMultiSelect from '../FilterMultiSelect';

const UserFilter = () => {
  const { t } = useTranslation();

  const { availableUsers, selectedUserIds, setSelectedUsers } = useUserFilter();

  const onSelectionChange = useCallback(
    (selectedKeys: Key[]) =>
      setSelectedUsers(Array.from([...selectedKeys]) as string[]),
    [setSelectedUsers],
  );

  const selectionLabel =
    selectedUserIds.length === 0
      ? t('user.allUsers')
      : `${selectedUserIds.length} ${t('user.user', {
          count: selectedUserIds.length,
        })}`;

  return (
    <FilterMultiSelect
      items={availableUsers}
      selectedKeys={selectedUserIds}
      onSelectionChange={onSelectionChange}
      fieldLabel={t('user.userMultiSelect.label')}
      selectionLabel={selectionLabel}
      renderItem={(item) => <UserFilterRow item={item} />}
    />
  );
};

export default UserFilter;
