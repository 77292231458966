import { ReactComponent as ANSOFF } from 'shared/static/assessment-banner/ansoff_matrix.svg';
import { ReactComponent as BLUE_OCEAN } from 'shared/static/assessment-banner/blue_ocean.svg';
import { ReactComponent as BSC_STRATEGY_BOARD } from 'shared/static/assessment-banner/bsc_strategy_board.svg';
import { ReactComponent as BSC_SWOT } from 'shared/static/assessment-banner/bsc_swot.svg';
import { ReactComponent as BUSINESS_CANVAS } from 'shared/static/assessment-banner/business_canvas.svg';
import { ReactComponent as COMPETITIVE_STRATEGIES } from 'shared/static/assessment-banner/competitive_strategies.svg';
import { ReactComponent as CSRD } from 'shared/static/assessment-banner/csrd.svg';
import { ReactComponent as CUSTOMER_JOURNEY } from 'shared/static/assessment-banner/customer_journey.svg';
import { ReactComponent as CUSTOMER_SEGMENTATION } from 'shared/static/assessment-banner/customer_segmentation.svg';
import { ReactComponent as DOUBLE_MATERIALITY } from 'shared/static/assessment-banner/double_materiality.svg';
import { ReactComponent as ESG } from 'shared/static/assessment-banner/esg.svg';
import { ReactComponent as M_AND_A } from 'shared/static/assessment-banner/m_and_a.svg';
import { ReactComponent as OBJECTIVES } from 'shared/static/assessment-banner/objectives.svg';
import { ReactComponent as MCKINSEY_7S } from 'shared/static/assessment-banner/mckinsey_7s.svg';
import { ReactComponent as PESTEL } from 'shared/static/assessment-banner/pestel.svg';
import { ReactComponent as PLAYING_TO_WIN } from 'shared/static/assessment-banner/playing_to_win.svg';
import { ReactComponent as PORTERS_FIVE_FORCES } from 'shared/static/assessment-banner/porters.svg';
import { ReactComponent as STAKEHOLDER_ANALYSIS } from 'shared/static/assessment-banner/stakeholders.svg';
import { ReactComponent as STRATEGY_DIAMOND } from 'shared/static/assessment-banner/strategy_diamond.svg';
import { ReactComponent as BSC_STRATEGY_MAP } from 'shared/static/assessment-banner/strategy_map.svg';
import { ReactComponent as SWOT } from 'shared/static/assessment-banner/swot.svg';
import { ReactComponent as TAXONOMY } from 'shared/static/assessment-banner/taxonomy.svg';
import { ReactComponent as TCFD } from 'shared/static/assessment-banner/tcfd.svg';
import { ReactComponent as THREE_HORIZONS } from 'shared/static/assessment-banner/three_horizons.svg';
import { ReactComponent as VALUE_CHAIN } from 'shared/static/assessment-banner/value_chain.svg';
import { ReactComponent as VRIO } from 'shared/static/assessment-banner/vrio.svg';
import { ReactComponent as FINANCIAL_BENCHMARK } from 'shared/static/assessment-banner/financial_benchmark.svg';
import { ReactComponent as FINANCIAL_GROWTH } from 'shared/static/assessment-banner/financial_growth.svg';
import { ReactComponent as FINANCIAL_HEALTH } from 'shared/static/assessment-banner/financial_health.svg';
import { ReactComponent as FINANCIAL_RISK } from 'shared/static/assessment-banner/financial_risk.svg';
import { ReactComponent as FRAMEWORKS } from 'shared/static/assessment-banner/frameworks.svg';
import { ReactComponent as FUTURE_OPPORTUNITIES } from 'shared/static/assessment-banner/future_opportunities.svg';
import { ReactComponent as TWO_BY_TWO } from 'shared/static/assessment-banner/two_by_two.svg';
import { ReactComponent as UNSDG } from 'shared/static/assessment-banner/unsdg.svg';
import { ReactComponent as GE_PORTFOLIO_ANALYSIS } from 'shared/static/assessment-banner/ge_portfolio.svg';
import { ReactComponent as GRI } from 'shared/static/assessment-banner/gri.svg';
import { ReactComponent as OBJECTIVE_RISKS } from 'shared/static/assessment-banner/objective_risks.svg';
import { ReactComponent as REVENUE_ANALYSIS } from 'shared/static/assessment-banner/revenue_analysis.svg';
import { ReactComponent as RISK_FRAMEWORKS } from 'shared/static/assessment-banner/risk_frameworks.svg';
import { ReactComponent as RISK_MAP } from 'shared/static/assessment-banner/risk_map.svg';
import { ReactComponent as SB_TI } from 'shared/static/assessment-banner/sbti.svg';
import { ReactComponent as STRATEGIC_RISKS } from 'shared/static/assessment-banner/strategic_risks.svg';
import { ReactComponent as STRATEX } from 'shared/static/assessment-banner/stratex.svg';

const bannerImageByAssessmentType = {
  ANSOFF,
  BLUE_OCEAN,
  BSC_STRATEGY_BOARD,
  BSC_STRATEGY_MAP,
  BSC_SWOT,
  BUSINESS_CANVAS,
  COMPETITIVE_STRATEGIES,
  CSRD,
  CUSTOMER_JOURNEY,
  CUSTOMER_SEGMENTATION,
  DOUBLE_MATERIALITY,
  ESG,
  FINANCIAL_BENCHMARK,
  FINANCIAL_GROWTH,
  FINANCIAL_HEALTH,
  FINANCIAL_RISK,
  FRAMEWORKS,
  FUTURE_OPPORTUNITIES,
  GE_PORTFOLIO_ANALYSIS,
  GRI,
  MCKINSEY_7S,
  M_AND_A,
  OBJECTIVES,
  OBJECTIVE_RISKS,
  PESTEL,
  PLAYING_TO_WIN,
  PORTERS_FIVE_FORCES,
  REVENUE_ANALYSIS,
  RISK_FRAMEWORKS,
  RISK_MAP,
  SB_TI,
  STAKEHOLDER_ANALYSIS,
  STRATEGIC_RISKS,
  STRATEGY_DIAMOND,
  STRATEX,
  SWOT,
  TAXONOMY,
  TCFD,
  THREE_HORIZONS,
  TWO_BY_TWO,
  UNSDG,
  VALUE_CHAIN,
  VRIO,
};

export default bannerImageByAssessmentType;
