import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { OrgUnit, Org } from 'types.graphql.generated';
import type { TeamCardProps } from 'team/TeamCard';
import TeamCard from 'team/TeamCard';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import Tag from 'shared/components/Tag';
import Space from 'shared/components/Space';
import { useUser } from 'user/UserProvider';

import OrgUnitCardProvider, { useOrgUnitCard } from './OrgUnitCardProvider';
import OrgUnitContextMenu from '../OrgUnitContextMenu';

export type OrgUnitCardProps = {
  orgKey: Org['orgKey'];
  orgUnitId: OrgUnit['id'];
  withLabel?: boolean;
  withMenu?: boolean;
} & Pick<TeamCardProps, 'headingLevel' | 'className'>;

const OrgUnitCard = ({
  headingLevel,
  orgKey,
  withMenu = true,
  withLabel = false,
  className,
}: OrgUnitCardProps) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { orgUnit } = useOrgUnitCard();

  const navigate = useNavigate();

  const teamAdapter = useMemo(
    () => TeamAdapter.fromOrgUnit(orgUnit, orgKey),
    [orgUnit, orgKey],
  );

  const handleTeamCardAction = useCallback<
    NonNullable<TeamCardProps['onAction']>
  >(
    (action) => {
      switch (action) {
        case 'name': {
          navigate(
            teamRoutesPaths.root({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
        case 'membersCount': {
          navigate(
            teamRoutesPaths.members.root({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
        case 'childTeamsCount': {
          navigate(
            teamRoutesPaths.teamHierarchy({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
      }
    },
    [teamAdapter, navigate],
  );

  const isPrimaryTeam = user.primaryOrgUnit?.id === orgUnit.id;

  const label = useMemo<ReactNode>(() => {
    if (withLabel && isPrimaryTeam) {
      return <Tag variant={'alert'}>{t('team.primaryTeam')}</Tag>;
    }
    return null;
  }, [t, withLabel, isPrimaryTeam]);

  const onDelete = useCallback(
    () => navigate(teamRoutesPaths.myTeams()),
    [navigate],
  );

  const extra = useMemo<ReactNode>(
    () => (
      <Space>
        {label}
        {withMenu && (
          <OrgUnitContextMenu orgUnit={orgUnit} onDelete={onDelete} />
        )}
      </Space>
    ),
    [label, onDelete, orgUnit, withMenu],
  );

  return (
    <TeamCard
      orgUnit={orgUnit}
      backgroundImage={orgUnit.backgroundPhotoUrl}
      childTeamsCount={orgUnit.childOrgUnitTree.length}
      membersCascadingCount={orgUnit.orgUnitMembershipsCascadingTotalCount}
      headingLevel={headingLevel}
      extra={extra}
      onAction={handleTeamCardAction}
      className={className}
      strategyName={orgUnit.currentStrategy?.name}
    />
  );
};

const OrgUnitCardWithProvider = (props: OrgUnitCardProps) => (
  <OrgUnitCardProvider orgUnitId={props.orgUnitId}>
    <OrgUnitCard {...props} />
  </OrgUnitCardProvider>
);

export default OrgUnitCardWithProvider;
