import styled from '@emotion/styled';
import { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { ReactComponent as ArrowLeftIcon } from 'shared/static/icons/icon-arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { ReactComponent as SeparatorIcon } from 'shared/static/icons/icon-separator.svg';
import Select from 'shared/components/Select';

import Button from '../../components/Button';
import Flex from '../../components/Flex';
import { useCalendarControls } from './useCalendarControls';
import type {
  CalendarCheck,
  CalendarInterval,
} from './CalendarControls.context';

const StyledButton = styled(Button)`
  width: 42px;
  height: 42px;

  svg {
    color: ${(props) => props.theme.color.black};
    width: 24px;
    height: 24px;
  }
`;

const formatByInterval = {
  year: 'yyyy',
  quarter: 'QQQ yyyy',
  month: 'MMM yyyy',
  week: '#II yyyy',
};

type Option = {
  check?: CalendarCheck;
  children?: Option[];
  groupId: undefined | 'overlap' | 'include';
  id: string;
  interval?: CalendarInterval;
  title: string;
};

const CalendarControls = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    calendarInterval,
    calendarCheck,
    onCalendarIntervalChange,
    calendarDate,
    isCurrentDate,
    onCurrentDate,
    onNextDate,
    onPreviousDate,
  } = useCalendarControls();

  const groups = useMemo(
    () => [
      { id: 'overlap', title: t('calendar.check.overlap') },
      { id: 'include', title: t('calendar.check.include') },
    ],
    [t],
  );

  const options = useMemo<Option[]>(
    () => [
      {
        id: 'all',
        interval: 'all' as CalendarInterval,
        check: undefined,
        title: t('calendar.interval.all'),
        groupId: undefined,
      },
      {
        id: 'year-overlap',
        interval: 'year',
        check: 'overlap' as CalendarCheck,
        title: t('calendar.interval.overlapYear'),
        groupId: 'overlap',
      },
      {
        id: 'quarter-overlap',
        interval: 'quarter' as CalendarInterval,
        check: 'overlap' as CalendarCheck,
        title: t('calendar.interval.overlapQuarter'),
        groupId: 'overlap',
      },
      {
        id: 'month-overlap',
        interval: 'month' as CalendarInterval,
        check: 'overlap' as CalendarCheck,
        title: t('calendar.interval.overlapMonth'),
        groupId: 'overlap',
      },
      {
        id: 'week-overlap',
        interval: 'week' as CalendarInterval,
        check: 'overlap' as CalendarCheck,
        title: t('calendar.interval.overlapWeek'),
        groupId: 'overlap',
      },

      {
        id: 'year-include',
        interval: 'year' as CalendarInterval,
        check: 'include' as CalendarCheck,
        title: t('calendar.interval.withinYear'),
        groupId: 'include',
      },
      {
        id: 'quarter-include',
        interval: 'quarter' as CalendarInterval,
        check: 'include' as CalendarCheck,
        title: t('calendar.interval.withinQuarter'),
        groupId: 'include',
      },
      {
        id: 'month-include',
        interval: 'month' as CalendarInterval,
        check: 'include' as CalendarCheck,
        title: t('calendar.interval.withinMonth'),
        groupId: 'include',
      },
      {
        id: 'week-include',
        interval: 'week' as CalendarInterval,
        check: 'include' as CalendarCheck,
        title: t('calendar.interval.withinWeek'),
        groupId: 'include',
      },
    ],
    [t],
  );

  const selectedOption = options.find(
    (item) =>
      item.interval === calendarInterval && item.check === calendarCheck,
  );

  const label =
    calendarInterval === 'all'
      ? t('calendar.interval.all')
      : capitalize(format(calendarDate, formatByInterval[calendarInterval]));

  return (
    <div css={css({ flex: '1' })}>
      <Flex gap={8} justifyContent={'center'}>
        <Select<Option>
          fieldLabel={t('calendar.fieldLabel')}
          valueLabel={label}
          options={options}
          groups={groups}
          triggerStyle={css`
            width: 120px;
            background-color: ${theme.color.backgroundDark};
            border-color: ${theme.color.backgroundDark};
            font-weight: bold;

            &:hover {
              background-color: ${theme.color.backgroundDark};
              border-color: ${theme.color.backgroundDark};
            }
          `}
          value={selectedOption}
          onChange={(item) =>
            item?.interval &&
            onCalendarIntervalChange(item.interval, item.check)
          }
        />
        <Flex gap={4}>
          <StyledButton
            variant={'icon'}
            icon={ArrowLeftIcon}
            iconSize={'medium'}
            onPress={onPreviousDate}
            isDisabled={!onPreviousDate}
          />
          <StyledButton
            variant={'icon'}
            icon={SeparatorIcon}
            iconSize={'medium'}
            onPress={onCurrentDate}
            isDisabled={isCurrentDate}
          />
          <StyledButton
            variant={'icon'}
            icon={ArrowRightIcon}
            iconSize={'medium'}
            onPress={onNextDate}
            isDisabled={!onPreviousDate}
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default CalendarControls;
