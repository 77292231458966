import { useTranslation } from 'react-i18next';

import type {
  StrategyItemListFormProps,
  StrategyItemListFormValues,
} from 'strategy/StrategyItemListForm';
import StrategyItemListForm from 'strategy/StrategyItemListForm';
import Heading from 'shared/components/Heading';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import Sidesheets from 'shared/sidesheets';

export type StrategyWizardValuesValues = StrategyItemListFormValues;

type StrategyWizardValuesProps = Pick<
  StrategyItemListFormProps,
  'id' | 'className' | 'initialValues' | 'onChange'
>;

const StrategyWizardValues = (props: StrategyWizardValuesProps) => {
  const { t } = useTranslation();

  const renderForm = () => (
    <StrategyItemListForm
      {...props}
      renderItemsHeading={() => (
        <Heading level={4} as={5}>
          {t('whatIs', {
            term: t('strategy.value_specific'),
          })}
        </Heading>
      )}
      isSymbolDisplayed={true}
      nameLabel={t('strategy.strategyWizard.steps.values.form.nameLabel')}
    />
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('strategy.strategyWizard.steps.values.infoCard.heading')}
      contentKey={'strategy.strategyWizard.steps.values.infoCard.content'}
      sideSheetTitle={t('sidesheets.context.strategy.heading')}
      orgTermKey={'strategy.value'}
    >
      <Sidesheets context={'strategy'} wizardStep={'values'} />
    </InfoCard>
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
    />
  );
};

export default StrategyWizardValues;
