import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import useHandleError from 'shared/errors/useHandleError';
import UserProfileForm from 'user/UserProfileForm';
import type { UserInput } from 'types.graphql.generated';
import { userFormId } from 'user/UserProfileForm/UserProfileForm';
import {
  TeamMembersFromOrgDocument,
  TeamMembersFromOrgUnitDocument,
} from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider.graphql.generated';
import { resolveOrgUnitInputInitialValue } from 'orgUnit/SelectOrgUnitInput/SelectOrgUnitInput.utils';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import { useEditUserProfileModalQuery } from './EditUserProfileModal.graphql.generated';
import { useUpdateUserProfileMutation } from './UpdateUserProfile.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
};

const EditUserProfileModal = ({ userId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { activeOrg } = useActiveOrg();

  const onError = useHandleError();

  const { data } = useEditUserProfileModalQuery({
    variables: { userId },
    onError,
    skip: !isOpen,
  });

  const [updateUserProfile, { loading }] = useUpdateUserProfileMutation({
    onError,
    refetchQueries: [
      TeamMembersFromOrgDocument,
      TeamMembersFromOrgUnitDocument,
    ],
  });

  const handleSubmit = useCallback(
    async (input: UserInput) => {
      const result = await updateUserProfile({ variables: { input } });

      if (!result.errors) {
        addToast({
          children: t('user.editProfile.toast.success'),
          variant: 'success',
        });
        onClose();
      }
    },
    [addToast, onClose, t, updateUserProfile],
  );

  const initialValues = useMemo(
    () =>
      data
        ? {
            id: data.user.id,
            email: data.user.email,
            photoUrl: data.user.photoUrl || '',
            displayName: data.user.displayName || '',
            authorities: data.user.authorities,
            orgUnit: resolveOrgUnitInputInitialValue(
              activeOrg,
              data.user.primaryOrgUnit,
            ),
          }
        : undefined,
    [activeOrg, data],
  );

  return (
    <Dialog
      headerTitle={t('user.editProfile.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      size={'small'}
      formId={userFormId}
      isConfirmDisabled={loading}
    >
      {initialValues ? (
        <UserProfileForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      ) : (
        <Spinner.Circle />
      )}
    </Dialog>
  );
};

export default EditUserProfileModal;
