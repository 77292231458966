import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import type { SetOptional } from 'type-fest';
import * as yup from 'yup';

import Form from 'shared/form/Form';
import type { FormProps } from 'shared/form/Form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useActiveOrg } from 'org/ActiveOrgProvider';

export type StrategyItemFormValues = {
  description: string;
  id?: string;
  name: string;
  owner: string;
};

export type StrategyItemFormProps = SetOptional<
  Pick<
    FormProps<StrategyItemFormValues>,
    'id' | 'className' | 'initialValues' | 'onChange'
  >,
  'initialValues'
>;

const StrategyItemForm = ({
  initialValues: initialValuesProp,
  id,
  onChange,
  className,
}: StrategyItemFormProps) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const initialValues = {
    name: initialValuesProp?.name || '',
    description: initialValuesProp?.description || '',
    owner:
      initialValuesProp?.owner ||
      orgUnit?.orgUnitLead?.email ||
      activeOrg.orgLead?.email ||
      '',
  };

  const validationSchema = yup.object({
    owner: yup.string().email().notRequired(),
  });

  return (
    <UserEmailInputProvider>
      <Form
        onChange={onChange}
        initialValues={initialValues}
        validationSchema={validationSchema}
        id={id}
        className={className}
      >
        {({ hasError }) => (
          <>
            <FieldBox
              name={'name'}
              label={t('strategy.strategyItemForm.name.label')}
            >
              <Field name={'name'} component={TextInputField} />
            </FieldBox>
            <FieldBox
              name={'description'}
              label={t('strategy.strategyItemForm.description.label')}
            >
              <Field name={'description'} component={TextInputField} />
            </FieldBox>
            <FieldBox
              name={'owner'}
              label={t('strategy.strategyItemForm.owner.label')}
              hasError={hasError('owner')}
            >
              <Field
                name={'owner'}
                component={UserEmailInputField}
                hasError={hasError('owner')}
              />
            </FieldBox>
          </>
        )}
      </Form>
    </UserEmailInputProvider>
  );
};

export default StrategyItemForm;
