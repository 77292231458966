import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Key } from 'react-aria-components';
import { last, without } from 'lodash';

import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';

import type { PriorityFilterValue } from './PriorityFilter.context';
import { usePriorityFilter } from './usePriorityFilter';
import { getPriorityLabel } from './PriorityFilter.utils';
import FilterMultiSelect from '../FilterMultiSelect';

export type PriorityFilterSelectItemId = PriorityFilterValue | 'ALL';

export type PriorityFilterSelectItem = { id: PriorityFilterSelectItemId };

const PriorityFilter = () => {
  const { t } = useTranslation();

  const { priorities } = useStrategyProfilePriorities();

  const {
    priorities: selectedPriorities,
    setPriorities,
    clearPriorities,
  } = usePriorityFilter();

  const items = useMemo(
    () =>
      (
        [
          'ALL',
          'UNKNOWN',
          ...(priorities || []).map((priority) => priority.number),
        ] satisfies PriorityFilterSelectItemId[]
      ).map((id) => ({ id, title: getPriorityLabel(t, id, priorities) })),
    [priorities, t],
  );

  const selectedKeys = useMemo(
    () => (selectedPriorities.length === 0 ? ['ALL'] : selectedPriorities),
    [selectedPriorities],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Key[]) =>
      last(selectedKeys) === 'ALL'
        ? clearPriorities()
        : setPriorities(
            without([...selectedKeys], 'ALL') as PriorityFilterValue[],
          ),

    [clearPriorities, setPriorities],
  );

  const selectionLabel = selectedKeys.includes('ALL')
    ? t('priority.allPriority')
    : selectedKeys.length === 0
    ? t('none')
    : t('priority.priorityMultiSelect.priorityCount', {
        count: selectedKeys.length,
      });

  return (
    <FilterMultiSelect
      items={items}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      fieldLabel={t('priority.priorityMultiSelect.label')}
      selectionLabel={selectionLabel}
    />
  );
};

export default PriorityFilter;
