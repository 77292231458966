import { ReactComponent as SwitchOnIcon } from 'shared/static/icons/icon-switch-on.svg';
import { ReactComponent as SwitchOffIcon } from 'shared/static/icons/icon-switch-off.svg';

type Props = {
  onToggle: () => void;
  value: boolean;
};

const Switch = ({ value, onToggle }: Props) => {
  return (
    <div onClick={onToggle}>{value ? <SwitchOnIcon /> : <SwitchOffIcon />}</div>
  );
};

export default Switch;
