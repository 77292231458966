import Flex from 'shared/components/Flex';
import type { Metric, MetricStatus } from 'types.graphql.generated';

type Props = {
  hideUnitOfMeasure?: boolean;
  metric: Pick<Metric, 'unitOfMeasure' | 'startValue' | 'targetValue'> & {
    currentMetricStatus?: Pick<MetricStatus, 'statusValue'>;
  };
};

const MetricScoreContent = ({ metric, hideUnitOfMeasure }: Props) => {
  const { unitOfMeasure, startValue, targetValue, currentMetricStatus } =
    metric;

  const hasTargetValue = targetValue !== undefined && targetValue !== null;

  const currentValueLabel = currentMetricStatus?.statusValue ?? startValue;

  const targetValueLabel = hasTargetValue ? targetValue : '-';

  return (
    <Flex alignItems={'center'} gap={4}>
      {unitOfMeasure && !hideUnitOfMeasure && <div>{unitOfMeasure}</div>}

      <Flex gap={1.5}>
        <div>{currentValueLabel}</div>

        {hasTargetValue && (
          <>
            <div>{'/'}</div>
            <div>{targetValueLabel}</div>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default MetricScoreContent;
