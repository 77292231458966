import { Field } from 'formik';
import type { SetOptional } from 'type-fest';
import { useTranslation } from 'react-i18next';
import type { FocusEvent } from 'react';
import { useMemo } from 'react';
import ms from 'ms';
import { css } from '@emotion/react';
import * as yup from 'yup';

import Form from 'shared/form/Form';
import type { FormProps } from 'shared/form/Form/Form';
import FieldBox from 'shared/form/FieldBox';
import { DatePickerField } from 'shared/components/DatePicker';
import Space from 'shared/components/Space';
import { ReactComponent as TimeStoperIcon } from 'shared/static/icons/icon-time-stoper.svg';
import { ReactComponent as NotificationIcon } from 'shared/static/icons/icon-notification.svg';
import { ReactComponent as UserIcon } from 'shared/static/icons/icon-user.svg';
import { TextInputField } from 'shared/components/TextInput';
import type { Report } from 'types.graphql.generated';
import { useSteps } from 'shared/components/Steps';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import useSubmission from 'shared/providers/SubmissionProvider/useSubmission';
import { SelectField } from 'shared/components/Select';

import styles from './RequestStatusUpdateForm.module.scss';
import {
  createInitialValues,
  getCutoffIntervalUnitFieldOptions,
  resolveInitialValues,
  UpdateReportWithStatusRequestDates,
} from './RequestStatusUpdateForm.utils';
import {
  RequestStatusUpdateFormReportDocument,
  useRequestStatusUpdateFormReportQuery,
  useRequestStatusUpdateReportStageMutation,
} from './RequestStatusUpdateForm.graphql.generated';
import type { RequestStatusUpdateFormValues } from './RequestStatusUpdateForm.type';
import RequestStatusUpdatePreview from './RequestStatusUpdatePreview';

export const RequestStatusUpdateFormUpdateReportSubmissionId =
  'RequestStatusUpdateForm';

export type RequestStatusUpdateFormProps = SetOptional<
  Pick<FormProps<RequestStatusUpdateFormValues>, 'className' | 'initialValues'>,
  'initialValues'
> & {
  report: Pick<Report, 'id'>;
};

const RequestStatusUpdateForm = ({
  report,
  ...restProps
}: RequestStatusUpdateFormProps) => {
  const { t } = useTranslation();
  const { completeStep } = useSteps();

  const onError = useHandleError();

  const { data: reportData } = useRequestStatusUpdateFormReportQuery({
    variables: { id: report.id },
    onError,
    pollInterval: ms('30s'),
  });

  const [updateReportStage] = useRequestStatusUpdateReportStageMutation({
    onError,
    refetchQueries: [RequestStatusUpdateFormReportDocument],
  });

  const initialValues = useMemo(() => {
    if (reportData?.report) {
      return resolveInitialValues(t, reportData.report);
    }
    return createInitialValues(t);
    // Only resolve initial values once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.report.id]);

  const validationSchema = yup.object({
    deadlineDate: yup.date().nullable().required(),
    cutoffInterval: yup
      .object()
      .test(
        'hasCutoffInterval',
        t('report.requestStatusUpdateForm.cutoffInterval.invalid'),
        (value) => yup.number().required().min(0).isValidSync(value.amount),
      ),
  });

  const [_, setIsSubmitting] = useSubmission(
    RequestStatusUpdateFormUpdateReportSubmissionId,
  );

  const handleSubmit = async (formValues: RequestStatusUpdateFormValues) => {
    try {
      setIsSubmitting(true);
      await updateReportStage({
        variables: {
          id: report.id,
          reportStage: 'STATUS_REQUESTED',
          ownerEmail: formValues.owner,
        },
      });
    } finally {
      setIsSubmitting(false);
    }
    completeStep('statusUpdate');
  };

  if (!reportData) {
    return <Spinner.Circle />;
  }

  return (
    <div className={styles.container}>
      {reportData?.report.reportStage !== 'STATUS_REQUESTED' ? (
        <Form
          {...restProps}
          id={'statusRequest'}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          autoFocus={{
            isEnabled: false,
          }}
          className={styles.form}
        >
          <UpdateReportWithStatusRequestDates report={report} />
          <FieldBox
            name={'deadlineDate'}
            label={t('report.requestStatusUpdateForm.deadlineDate.label')}
            icon={TimeStoperIcon}
            hasMargin={false}
          >
            <Field
              name={'deadlineDate'}
              component={DatePickerField}
              withTime={true}
              placeholder={t(
                'report.requestStatusUpdateForm.deadlineDate.placeholder',
              )}
            />
          </FieldBox>
          <FieldBox
            name={'cutoffInterval'}
            label={t('report.requestStatusUpdateForm.cutoffInterval.label')}
            icon={NotificationIcon}
            hasMargin={false}
          >
            <Space>
              <Field
                name={'cutoffInterval.amount'}
                component={TextInputField}
                placeholder={t(
                  'report.requestStatusUpdateForm.cutoffInterval.amount.placeholder',
                )}
                variant={'outlined'}
                containerClassName={styles.cutoffIntervalAmountFieldContainer}
                onFocus={(event: FocusEvent<HTMLInputElement>) => {
                  event.target.select();
                }}
              />
              <Field
                name={'cutoffInterval.unit'}
                component={SelectField}
                fieldLabel={t(
                  'report.requestStatusUpdateForm.cutoffInterval.unit',
                )}
                options={getCutoffIntervalUnitFieldOptions(t)}
                triggerStyle={css({ minWidth: '100px' })}
              />
            </Space>
          </FieldBox>
          <div
            css={css`
              width: 250px;
            `}
          >
            <FieldBox
              name={'owner'}
              label={t('report.requestStatusUpdateForm.owner.label')}
              hasMargin={false}
              icon={UserIcon}
            >
              <UserEmailInputProvider>
                <Field name={'owner'} component={UserEmailInputField} />
              </UserEmailInputProvider>
            </FieldBox>
          </div>
        </Form>
      ) : null}

      {reportData && <RequestStatusUpdatePreview report={reportData.report} />}
    </div>
  );
};

export default RequestStatusUpdateForm;
