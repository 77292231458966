import { toolkitRoutesPaths } from './toolkit.routing.paths';

export const groups = [
  'develop',
  'strategize',
  'operationalize',
  'prioritize',
  'coordinate',
  'collaborate',
  'manage',
  'learn',
  'evaluate',
  'plan',
  'do',
  'act',
  'lead',
  'createAwareness',
  'driveChange',
  'motivate',
  'buildCulture',
  'liveValues',
  'execute',
  'play',
  'monitor',
] as const;

export type ToolGroup = (typeof groups)[number];

export type ToolCategory = {
  groups: ToolGroup[];
  id: string;
};

export const categories: ToolCategory[] = [
  {
    id: 'strategyDevelopment',
    groups: [
      'develop',
      'strategize',
      'operationalize',
      'coordinate',
      'collaborate',
      'manage',
      'learn',
      'evaluate',
      'plan',
      'do',
      'act',
    ],
  },
  {
    id: 'strategyEngagement',
    groups: [
      'lead',
      'coordinate',
      'createAwareness',
      'driveChange',
      'motivate',
      'buildCulture',
      'liveValues',
      'execute',
      'learn',
      'play',
    ],
  },
  {
    id: 'strategyInsights',
    groups: [
      'strategize',
      'coordinate',
      'manage',
      'driveChange',
      'execute',
      'monitor',
      'learn',
      'evaluate',
    ],
  },
  {
    id: 'accessManagement',
    groups: [],
  },
];

export type ToolCategoryId = (typeof categories)[number]['id'];

type ToolAvailability = 'AVAILABLE' | 'COMING_SOON';

export type Tool = {
  availability: ToolAvailability;
  category: ToolCategoryId;
  getLink?: (teamSlug: string) => string;
  groups: ToolGroup[];
  id: string;
};

export const tools: Tool[] = [
  {
    id: 'tipsAndTricks',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'learn'],
  },
  {
    id: 'askSophie_strategyDevelopment',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: [
      'develop',
      'strategize',
      'operationalize',
      'learn',
      'evaluate',
      'plan',
    ],
  },
  {
    id: 'strategyRoom',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'plan'],
  },
  {
    id: 'strategyRollOut',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'operationalize', 'coordinate', 'plan'],
  },
  {
    id: 'tailoredStrategyOperationalization',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['operationalize', 'plan'],
  },
  {
    id: 'strategyLingo',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop'],
  },
  {
    id: 'sustainableStrategy',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'operationalize'],
  },
  {
    id: 'strategyIn2Steps',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'operationalize', 'learn', 'plan'],
  },
  {
    id: 'clarityOfMission',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'plan'],
  },
  {
    id: 'visionStatement',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'plan'],
  },
  {
    id: 'longTermGoals',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'plan'],
  },
  {
    id: 'strategicChoices',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'plan'],
  },
  {
    id: 'strategicThemes',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: [
      'develop',
      'strategize',
      'operationalize',
      'coordinate',
      'manage',
      'plan',
    ],
  },
  {
    id: 'strategicObjectives',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: [
      'operationalize',
      'prioritize',
      'coordinate',
      'collaborate',
      'manage',
      'plan',
    ],
  },
  {
    id: 'measureWhatMatters',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: [
      'operationalize',
      'prioritize',
      'manage',
      'learn',
      'evaluate',
      'plan',
    ],
  },
  {
    id: 'strategicInitiatives',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: [
      'operationalize',
      'prioritize',
      'coordinate',
      'collaborate',
      'manage',
      'plan',
      'do',
      'act',
    ],
  },
  {
    id: 'strategicInitiativesCalendar',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: [
      'operationalize',
      'prioritize',
      'coordinate',
      'manage',
      'plan',
      'do',
    ],
  },
  {
    id: 'agileActionPlans',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['operationalize', 'collaborate', 'manage', 'plan', 'do', 'act'],
  },
  {
    id: 'prioritization',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'operationalize', 'prioritize', 'plan'],
  },
  {
    id: 'powerPointStrategyPresentations',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'operationalize', 'plan'],
  },
  {
    id: 'stratEx',
    availability: 'COMING_SOON',
    category: 'strategyDevelopment',
    groups: ['operationalize', 'prioritize', 'coordinate', 'plan'],
  },
  {
    id: 'initiativeAlignmentMatrix',
    availability: 'COMING_SOON',
    category: 'strategyDevelopment',
    groups: ['operationalize', 'prioritize', 'coordinate', 'evaluate', 'plan'],
  },
  {
    id: 'metricAlignmentMatrix',
    availability: 'COMING_SOON',
    category: 'strategyDevelopment',
    groups: ['operationalize', 'prioritize', 'evaluate', 'plan'],
  },
  {
    id: 'collaborativeStrategy',
    availability: 'COMING_SOON',
    category: 'strategyDevelopment',
    groups: [
      'develop',
      'strategize',
      'operationalize',
      'collaborate',
      'evaluate',
      'plan',
    ],
  },
  {
    id: 'stopStartContinue',
    availability: 'COMING_SOON',
    category: 'strategyDevelopment',
    groups: [
      'operationalize',
      'prioritize',
      'evaluate',
      'coordinate',
      'plan',
      'act',
    ],
  },
  {
    id: 'strategyAnalysis',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'learn', 'evaluate', 'plan'],
  },
  {
    id: 'strategicRisk',
    availability: 'COMING_SOON',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'operationalize', 'manage', 'plan'],
  },
  {
    id: 'strategicHorizon',
    availability: 'AVAILABLE',
    category: 'strategyDevelopment',
    groups: ['develop', 'strategize', 'operationalize', 'plan'],
  },
  {
    id: 'strategyRoadmap',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'play'],
  },
  {
    id: 'strategicLeadership',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['lead', 'driveChange', 'buildCulture', 'execute'],
  },
  {
    id: 'strategicEmployeeship',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['driveChange', 'buildCulture', 'execute'],
  },
  {
    id: 'positiveReinforcers',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['driveChange', 'motivate', 'buildCulture', 'liveValues'],
  },
  {
    id: 'dynamicStrategyTeams',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['coordinate', 'driveChange', 'execute'],
  },
  {
    id: 'strategicResponsibilities',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['lead', 'coordinate', 'driveChange', 'execute'],
  },
  {
    id: 'organizationalValues',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'driveChange', 'buildCulture', 'liveValues'],
  },
  {
    id: 'myContribution',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'driveChange', 'motivate', 'execute'],
  },
  {
    id: 'walkOfStrategy',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'driveChange', 'motivate'],
    getLink: (teamSlug: string) =>
      toolkitRoutesPaths.walkOfStrategy.root({ params: { teamSlug } }),
  },
  {
    id: 'oneThing',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['driveChange', 'motivate', 'execute'],
    getLink: (teamSlug: string) =>
      toolkitRoutesPaths.oneThing.root({ params: { teamSlug } }),
  },
  {
    id: 'askSophie_strategyEngagement',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: [
      'develop',
      'strategize',
      'operationalize',
      'learn',
      'evaluate',
      'plan',
    ],
  },
  {
    id: 'blueJamMobile',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'driveChange'],
  },
  {
    id: 'valueLikes',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: [
      'createAwareness',
      'driveChange',
      'motivate',
      'buildCulture',
      'liveValues',
    ],
  },
  {
    id: 'liveStrategyFeed',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: [
      'createAwareness',
      'driveChange',
      'buildCulture',
      'liveValues',
      'learn',
    ],
  },
  {
    id: 'dailyStrategyAds',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: [
      'createAwareness',
      'driveChange',
      'buildCulture',
      'liveValues',
      'learn',
    ],
  },
  {
    id: 'strategyGame',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'driveChange', 'motivate', 'learn', 'play'],
  },
  {
    id: 'strategyPoll',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: ['createAwareness', 'driveChange', 'motivate', 'learn'],
  },
  {
    id: 'commitmentManagement',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: ['lead', 'driveChange', 'motivate', 'execute'],
  },
  {
    id: 'peopleAlignmentMatrix',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: ['lead', 'coordinate'],
  },
  {
    id: 'strategyStories',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: [
      'createAwareness',
      'driveChange',
      'motivate',
      'buildCulture',
      'learn',
    ],
  },
  {
    id: 'strategyELearning',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: [
      'createAwareness',
      'driveChange',
      'motivate',
      'buildCulture',
      'learn',
      'play',
    ],
  },
  {
    id: 'gamification',
    availability: 'COMING_SOON',
    category: 'strategyEngagement',
    groups: [
      'createAwareness',
      'driveChange',
      'motivate',
      'buildCulture',
      'learn',
      'play',
    ],
  },
  {
    id: 'lineOfSight',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['coordinate', 'driveChange'],
  },
  {
    id: 'followUps',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['manage', 'driveChange', 'execute', 'monitor', 'learn'],
  },
  {
    id: 'strategyBoard',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['strategize', 'coordinate', 'manage'],
  },
  {
    id: 'powerPointInsightsReports',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['manage', 'driveChange', 'execute', 'monitor', 'learn'],
  },
  {
    id: 'liveStrategyInsights',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['manage', 'driveChange', 'execute', 'monitor', 'learn'],
  },
  {
    id: 'askSophie_strategyInsights',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: [
      'develop',
      'strategize',
      'operationalize',
      'learn',
      'evaluate',
      'plan',
    ],
  },
  {
    id: 'forecasting',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['manage', 'driveChange', 'execute', 'monitor', 'learn'],
  },
  {
    id: 'snapshotInsightsReport',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['learn'],
  },
  {
    id: 'strategyNewsletter',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['coordinate', 'monitor', 'learn'],
  },
  {
    id: 'strategyBlackHoles',
    availability: 'COMING_SOON',
    category: 'strategyInsights',
    groups: ['driveChange', 'monitor'],
  },
  {
    id: 'strategyExecutionAssessments',
    availability: 'COMING_SOON',
    category: 'strategyInsights',
    groups: ['strategize', 'driveChange', 'execute', 'evaluate'],
  },
  {
    id: 'bestPracticeAssessments',
    availability: 'COMING_SOON',
    category: 'strategyInsights',
    groups: ['strategize', 'driveChange', 'evaluate'],
  },
  {
    id: 'strategyRadar',
    availability: 'COMING_SOON',
    category: 'strategyInsights',
    groups: ['manage', 'monitor'],
  },
  {
    id: 'strategyEngagementTracker',
    availability: 'COMING_SOON',
    category: 'strategyInsights',
    groups: ['manage', 'driveChange', 'execute', 'monitor'],
  },
  {
    id: 'strategyEffectiveness',
    availability: 'COMING_SOON',
    category: 'strategyInsights',
    groups: ['strategize', 'manage', 'driveChange', 'evaluate'],
  },
  {
    id: 'strategyStressTest',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['strategize', 'manage', 'driveChange', 'evaluate'],
  },
  {
    id: 'tailoredDashboards',
    availability: 'AVAILABLE',
    category: 'strategyInsights',
    groups: ['coordinate', 'manage', 'monitor', 'learn', 'evaluate'],
  },
  {
    id: 'metricsAlignment',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['coordinate', 'driveChange'],
  },
  {
    id: 'initiativeCoordination',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['coordinate', 'driveChange'],
  },
  {
    id: 'insightHistory',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['monitor', 'learn', 'evaluate'],
  },
  {
    id: 'oneClickReporting',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['monitor', 'learn', 'evaluate'],
  },
  {
    id: 'oneClickRequests',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['monitor', 'learn', 'evaluate'],
  },
  {
    id: 'strategyFilters',
    availability: 'AVAILABLE',
    category: 'strategyEngagement',
    groups: ['coordinate', 'evaluate'],
  },
  {
    id: 'singleSignOn',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'authentication',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'userProvisioning',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'secure',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'encrypted',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'gdprCompliant',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'multiLanguage',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'advancedAccessControl',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'sensitiveInfo',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
  {
    id: 'multiOrganization',
    availability: 'AVAILABLE',
    category: 'accessManagement',
    groups: [],
  },
];
