import { uniqBy } from 'lodash';

import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';
import type { StatusFilterValue } from 'shared/filters/StatusFilter';
import { testStatusIndicatorAgainstIds } from 'shared/filters/StatusFilter';
import {
  testPriorityFilters,
  type PriorityFilterValue,
} from 'shared/filters/PriorityFilter';

import type { StrategyBoardObjective } from '../StrategyBoard.type';

export const filterThemesByStatusIndicator = (
  objectives: StrategyBoardObjective[],
  statuses: StatusFilterValue[],
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testStatusIndicatorAgainstIds(
        statuses,
        objective.currentObjectiveStatus?.statusIndicator,
      ),
    initiatives: objective.initiatives.filter((initiative) =>
      testStatusIndicatorAgainstIds(
        statuses,
        initiative.currentInitiativeStatus?.statusIndicator,
      ),
    ),
    metrics: objective.metrics.filter((metric) =>
      testStatusIndicatorAgainstIds(
        statuses,
        metric.currentMetricStatus?.statusIndicator,
      ),
    ),
    actions: objective.actions.filter((action) =>
      testStatusIndicatorAgainstIds(
        statuses,
        action.currentStatus?.statusIndicator,
      ),
    ),
  }));

export const filterThemesByCompleted = (
  objectives: StrategyBoardObjective[],
  showCompleted: boolean,
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      (showCompleted || !objective.currentObjectiveStatus?.complete),
    initiatives: objective.initiatives.filter(
      (initiative) =>
        showCompleted || !initiative.currentInitiativeStatus?.complete,
    ),
    metrics: objective.metrics.filter(
      (metric) => showCompleted || !metric.currentMetricStatus?.complete,
    ),
    actions: objective.actions.filter(
      (action) => showCompleted || !action.currentStatus?.complete,
    ),
  }));

export const filterThemesByPriority = (
  objectives: StrategyBoardObjective[],
  priorities: PriorityFilterValue[],
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testPriorityFilters(priorities, objective.priority),
    initiatives: objective.initiatives.filter((initiative) =>
      testPriorityFilters(priorities, initiative.priority),
    ),
    metrics: objective.metrics.filter((metric) =>
      testPriorityFilters(priorities, metric.priority),
    ),
    actions: objective.actions.filter((action) =>
      testPriorityFilters(priorities, action.priority),
    ),
  }));

export const filterThemesByUser = (
  objectives: StrategyBoardObjective[],
  userIds: string[],
): StrategyBoardObjective[] => {
  if (userIds.length === 0) {
    return objectives;
  }

  return objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      userIds.some((value) => value === objective.owner?.id),
    initiatives: objective.initiatives.filter((initiative) =>
      userIds.some((value) => value === initiative.owner?.id),
    ),
    metrics: objective.metrics.filter((metric) =>
      userIds.some((value) => value === metric.owner?.id),
    ),
    actions: objective.actions.filter((action) =>
      userIds.some((value) => value === action.owner?.id),
    ),
  }));
};

export const filterThemesByTheme = (
  objectives: StrategyBoardObjective[],
  themeIds: string[],
): StrategyBoardObjective[] => {
  if (themeIds.length === 0) {
    return objectives;
  }

  return objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      themeIds.some((value) => value === objective.theme?.id),
    initiatives: themeIds.some((value) => value === objective.theme?.id)
      ? objective.initiatives
      : [],
    metrics: themeIds.some((value) => value === objective.theme?.id)
      ? objective.metrics
      : [],
    actions: themeIds.some((value) => value === objective.theme?.id)
      ? objective.actions
      : [],
  }));
};

export const filterThemesByCollaboratingTeams = (
  objectives: StrategyBoardObjective[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const filterThemesByDates = (
  objectives: StrategyBoardObjective[],
  start: Maybe<Date>,
  end: Maybe<Date>,
  check?: CalendarCheck,
): StrategyBoardObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testStrategyItemAgainstInterval(objective, { start, end }, check),
    initiatives: objective.initiatives.filter((initiative) =>
      testStrategyItemAgainstInterval(initiative, { start, end }, check),
    ),
    metrics: objective.metrics.filter((metric) =>
      testStrategyItemAgainstInterval(metric, { start, end }, check),
    ),
    actions: objective.actions.filter((action) =>
      testStrategyItemAgainstInterval(action, { start, end }, check),
    ),
  }));

export const getObjectivesOwners = (objectives: StrategyBoardObjective[]) =>
  uniqBy(
    objectives.flatMap((objective) => {
      return [
        objective.owner,
        ...objective.metrics.map((metric) => metric.owner),
        ...objective.initiatives.map((metric) => metric.owner),
      ].filter(Boolean);
    }),
    (user) => user.id,
  );
