import type { Merge } from 'type-fest';
import type { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as CircleAlertIcon } from 'shared/static/icons/icon-circle-alert.svg';
import { ReactComponent as ProcessIcon } from 'shared/static/icons/icon-process.svg';

import type { Step } from '../Steps.type';
import styles from './StepsButton.module.scss';

type StepsButtonProps = Merge<
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>,
  {
    step: Step;
  }
>;

const StepsButton = ({ step, className, ...restProps }: StepsButtonProps) => {
  const {
    name,
    nameTranslationKey,
    isActive,
    isCompleted,
    isError,
    isInProgress,
    isDisabled,
  } = step;

  const { t } = useTranslation();

  const getIcon = () => {
    if (isError) {
      return CircleAlertIcon;
    } else if (isCompleted) {
      return CheckIcon;
    } else if (isInProgress) {
      return ProcessIcon;
    }
    return null;
  };

  const renderIcon = () => {
    const Icon = getIcon();
    if (Icon) {
      return <Icon className={styles.stepsButtonIcon} />;
    }
    return null;
  };

  return (
    <button
      type={'button'}
      {...restProps}
      className={cn(
        styles.stepsButton,
        {
          [styles.stepsButtonActive]: isActive,
          [styles.stepsButtonCompleted]: isCompleted,
          [styles.stepsButtonError]: isError,
          [styles.stepsButtonInProgress]: isInProgress,
          [styles.stepsButtonDisabled]: isDisabled,
        },
        className,
      )}
    >
      {renderIcon()}
      <span className={styles.stepsButtonName}>
        {nameTranslationKey ? t(nameTranslationKey) : name}
      </span>
    </button>
  );
};

export default StepsButton;
