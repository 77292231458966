import type { InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import type { Merge } from 'type-fest';

import type { Animation } from 'shared/types/animation.type';
import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as MinusIcon } from 'shared/static/icons/icon-minus.svg';

import styles from './Checkbox.module.scss';

const iconAnimation: Animation = {
  animate: { scale: 1 },
  initial: { scale: 0 },
  exit: {
    scale: 0,
  },
  transition: {
    duration: 0.15,
    ease: 'easeIn',
  },
};

type CheckboxProps = Merge<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>,
  {
    isIndeterminate?: boolean;
  }
>;

// todo reimplement using React Aria
// https://react-spectrum.adobe.com/react-aria/useCheckbox.html
const Checkbox = ({
  isIndeterminate,
  checked,
  onFocus,
  onBlur,
  children,
  className,
  ...restProps
}: CheckboxProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const Icon = isIndeterminate ? MinusIcon : CheckIcon;
  const isIconDisplayed = checked || isIndeterminate;

  return (
    <div
      data-checked={checked}
      className={cn(
        styles.checkbox,
        {
          [styles.checkboxChecked]: isIconDisplayed,
          [styles.checkboxFocused]: isFocused,
        },
        className,
      )}
    >
      <label className={styles.checkboxLabel}>
        <div className={styles.checkboxIndicator}>
          <AnimatePresence>
            {isIconDisplayed && (
              <motion.div
                {...iconAnimation}
                className={styles.checkboxIconContainer}
              >
                <Icon className={styles.checkboxIcon} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <input
          {...restProps}
          checked={checked}
          type={'checkbox'}
          onBlur={(event) => {
            setIsFocused(false);
            onBlur?.(event);
          }}
          onFocus={(event) => {
            setIsFocused(true);
            onFocus?.(event);
          }}
          className={styles.checkboxInput}
        />
        <span>{children}</span>
      </label>
    </div>
  );
};

export default Checkbox;
