import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'shared/components/Select';
import { useUserTenants } from 'user/UserTenantsProvider/useUserTenants';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import type { SelectUserTenantInputOption } from './SelectUserTenantInput.type';
import SelectUserTenantInputRow from './SelectUserTenantInputRow';

export type Props = {
  onChange: (value: Maybe<SelectUserTenantInputOption>) => void;
  value: Maybe<SelectUserTenantInputOption>;
};

const SelectUserTenantInput = () => {
  const { t } = useTranslation();
  const { activeOrg, setActiveOrg } = useActiveOrg();

  const { tenants } = useUserTenants();

  const options = useMemo(
    () =>
      tenants.map((tenant) => ({
        id: tenant.id,
        value: tenant,
        title: tenant.displayName || tenant.domainNames[0] || tenant.orgKey,
      })),
    [tenants],
  );

  const handleChange = useCallback(
    (option: Maybe<SelectUserTenantInputOption>) => {
      if (option) {
        setActiveOrg(option.value.orgKey);
      }
    },
    [setActiveOrg],
  );

  if (options.length === 1) return null;
  const currentValueOption = options.find(
    (option) => option.value.orgKey === activeOrg.orgKey,
  );

  return (
    <Select<SelectUserTenantInputOption>
      fieldLabel={t('tenantSelect.fieldLabel')}
      value={currentValueOption}
      onChange={handleChange}
      options={options}
      renderOption={(option) => (
        <SelectUserTenantInputRow key={option.id} item={option} />
      )}
    />
  );
};

export default SelectUserTenantInput;
