import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { transientOptions } from 'shared/utils/emotion.utils';
import StatusMark from 'shared/status/StatusMark';
import UserAvatar from 'user/UserAvatar';
import Flex from 'shared/components/Flex';
import LinkIcon from 'objective/LinkIcon';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import LockedLink from 'shared/components/LockedLink';
import Tooltip from 'shared/components/Tooltip';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useTeamAdapter } from 'team/TeamAdapter';
import useDialogState from 'shared/hooks/useDialogState';
import ActionStatusModal from 'actions/ActionStatusModal';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import MetricScore from 'metric/MetricScore/MetricScore';
import CompletionRate from 'shared/components/CompletionRate';

import type { StrategyBoardItem } from '../../StrategyBoard.type';
import { useStrategyBoardViewSettings } from '../../StrategyBoardViewSettings';
import StrategyBoardItemTooltip, {
  getInitiativeMilestoneAverageCompletion,
} from '../StrategyBoardItemTooltip';
import {
  getStatusIndicator,
  onStrategyItemPress,
} from './StrategyBoardItemCard.utils';

const Container = styled('div', transientOptions)<{
  $isDisabled?: boolean;
  $isLocked: boolean;
}>`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  color: ${({ theme }) => theme.color.typoPrimary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  overflow: hidden;
  padding: 5px 8px 5px 8px;
  position: relative;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
  text-decoration: none;
  cursor: ${({ $isLocked }) => (!$isLocked ? 'pointer' : 'inherit')};

  &:hover {
    background-color: ${({ theme, $isLocked }) =>
      !$isLocked && theme.color.hoverLight};
    opacity: 1;
  }
`;

const ItemName = styled.div`
  margin-top: 3.5px;
  flex-grow: 1;

  svg {
    flex-shrink: 0;
    color: ${({ theme }) => theme.color.typoSecondary};
    width: 12px;
    height: 12px;
  }
`;

const AvatarContainer = styled.div`
  height: 28px;
  width: 28px;
`;

type Props = {
  isExpanded?: boolean;
  item: StrategyBoardItem;
  rowObjectiveId: string;
};

const StrategyBoardItemCard = ({ item, rowObjectiveId, isExpanded }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { teamAdapter } = useTeamAdapter();

  const {
    isUserVisible,
    isOrgUnitVisible,
    isMetricsVisible,
    isMetricScoreVisible,
    isInitiativesVisible,
    isInitiativeMilestonesVisible,
    isActionsVisible,
    isActionProgressVisible,
  } = useStrategyBoardViewSettings();

  const { owner, orgUnit } = item;

  const statusIndicator = getStatusIndicator(item);

  const canRead = canPerformStrategyElementAction(item, 'READ');
  const isLocked = !canRead;

  const {
    isOpen: isEditActionOpen,
    onOpen: onOpenEditAction,
    onClose: onCloseEditAction,
  } = useDialogState();

  const onPress = useCallback(
    () => onStrategyItemPress(navigate, teamAdapter, onOpenEditAction, item),
    [item, navigate, onOpenEditAction, teamAdapter],
  );

  const isLink =
    item.__typename !== 'Objective' &&
    item.objective &&
    item.objective.id !== rowObjectiveId;

  const isDisabled = item.__typename === 'Objective' && !item.isFiltersMatch;

  const shouldShowMetricScore =
    item.__typename === 'Metric' && isMetricScoreVisible;

  const shouldShowInitiativeMilestones =
    item.__typename === 'Initiative' && isInitiativeMilestonesVisible;

  const shouldShowActionProgress =
    item.__typename === 'Action' && isActionProgressVisible;

  const initiativeMilestoneAverageCompletion =
    item.__typename === 'Initiative'
      ? getInitiativeMilestoneAverageCompletion(item)
      : undefined;

  return (
    <>
      <Tooltip
        placement={
          !isMetricsVisible && !isInitiativesVisible && !isActionsVisible
            ? 'bottom right'
            : 'right'
        }
        content={<StrategyBoardItemTooltip item={item} />}
        isInline={true}
        triggerStyle={css({ height: isExpanded ? '100%' : undefined })}
      >
        <Container
          $isDisabled={isDisabled}
          $isLocked={isLocked}
          onClick={onPress}
          css={isExpanded && css({ height: '100%' })}
        >
          <Flex gap={8}>
            <Flex alignItems={'center'} css={css({ height: 28 })}>
              <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
            </Flex>

            <ItemName>
              <LockedLink onPress={onPress} isActive={canRead} isInline={true}>
                {item.name}
              </LockedLink>
              {isLink && (
                <LinkIcon isInline={true} css={css({ marginLeft: 8 })} />
              )}

              {item.__typename === 'Objective' && item.parentObjective && (
                <>
                  <ChevronRightIcon css={css({ margin: '-3px 4px 0 4px' })} />
                  <LockedLink
                    to={objectiveRoutesPaths.root({
                      params: {
                        teamSlug: teamAdapter.toParam(),
                        objectiveId: item.parentObjective?.id || '',
                      },
                    })}
                    isActive={canPerformStrategyElementAction(
                      item.parentObjective,
                      'READ',
                    )}
                    isInline={true}
                    color={theme.color.typoSecondary}
                  >
                    {item.parentObjective.name}
                  </LockedLink>
                </>
              )}
            </ItemName>
          </Flex>

          {(shouldShowMetricScore ||
            shouldShowInitiativeMilestones ||
            shouldShowActionProgress ||
            isOrgUnitVisible ||
            isUserVisible) && (
            <Flex alignSelf={'flex-end'} justifyContent={'flex-end'} gap={4}>
              {shouldShowMetricScore && (
                <MetricScore metric={item} hideUnitOfMeasure={true} />
              )}

              {shouldShowInitiativeMilestones && (
                <CompletionRate
                  completionRate={initiativeMilestoneAverageCompletion}
                  complete={initiativeMilestoneAverageCompletion === 100}
                />
              )}

              {shouldShowActionProgress && (
                <CompletionRate
                  completionRate={item.currentStatus?.completionRate}
                  complete={item.currentStatus?.complete}
                />
              )}

              {isOrgUnitVisible && (
                <TeamAvatar orgUnit={orgUnit} size={'nano'} />
              )}

              {isUserVisible &&
                (owner ? <UserAvatar user={owner} /> : <AvatarContainer />)}
            </Flex>
          )}
        </Container>
      </Tooltip>

      {item.__typename === 'Action' && (
        <ActionStatusModal
          actionId={item.id}
          isOpen={isEditActionOpen}
          onClose={onCloseEditAction}
        />
      )}
    </>
  );
};

export default StrategyBoardItemCard;
