import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';
import cn from 'classnames';

import Space from 'shared/components/Space';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import { SliderField2 } from 'shared/components/Slider/V2';
import { date } from 'shared/services/date.service';
import Divider from 'shared/components/Divider';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { shortenEmail } from 'shared/utils/string.utils';

import type { ActionStatusModalActionFragment } from './ActionStatusModal.graphql.generated';
import styles from './ActionStatusModal.module.scss';

type Props = {
  action: ActionStatusModalActionFragment;
};

const ActionStatusFormFields = ({ action }: Props) => {
  const { t } = useTranslation();

  const actionCreator =
    action?.auditRecord.createBy?.displayName ??
    action?.auditRecord.createBy?.email;

  const shortenedActionCreator =
    action?.auditRecord.createBy?.displayName ??
    shortenEmail(action?.auditRecord.createBy?.email);

  const canAddStatus = canPerformStrategyElementAction(action, 'WRITE');

  return (
    <Space direction={'vertical'} size={'big'} className={styles.formContainer}>
      <Space direction={'vertical'} size={'big'}>
        {canAddStatus && (
          <>
            <Divider hasMargin={false} />
            <FieldBox
              name={'completionRate'}
              label={t('initiative.milestoneStatusForm.completionRate.label')}
              contentClassName={styles.completionRate}
            >
              <Field
                disabled={!canAddStatus}
                name={'completionRate'}
                component={TextInputField}
                max={100}
                size={'small'}
                type={'number'}
                className={styles.completionRateTextInput}
              />
              <Field
                disabled={!canAddStatus}
                component={SliderField2}
                size={'small'}
                name={'completionRate'}
                marks={{
                  0: 0,
                  25: 25,
                  50: 50,
                  75: 75,
                  100: 100,
                }}
                className={styles.completionRateSliderInput}
              />
            </FieldBox>
          </>
        )}
        <Space className={styles.auditRecord}>
          <div className={styles.auditRecordText} title={actionCreator}>
            <Trans
              i18nKey={'actions.attributes.createdBy'}
              values={{
                user: shortenedActionCreator,
                date: action.auditRecord.createDateTime
                  ? date.format(action.auditRecord.createDateTime, 'dd MMM yyy')
                  : '-',
              }}
            />
          </div>
          <div
            className={cn(
              styles.auditRecordText,
              styles.auditRecordTextLastUpdate,
            )}
          >
            <Trans
              i18nKey={'actions.attributes.lastUpdate'}
              values={{
                date: action.currentStatus?.statusDateTime
                  ? date.format(
                      action.currentStatus.statusDateTime,
                      'dd MMM yyyy',
                    )
                  : action.auditRecord.createDateTime
                  ? date.format(action.auditRecord.createDateTime, 'dd MMM yyy')
                  : '-',
              }}
            />
          </div>
        </Space>
      </Space>
    </Space>
  );
};

export default ActionStatusFormFields;
