import { forwardRef } from 'react';

import ContributionSection from 'contribution/ContributionSection';
import ContributionTeamList from 'contribution/ContributionTeamList';
import ContributionNoSection from 'contribution/ContributionNoSection';
import type { Contribution } from 'contribution/ContributionTeamList/ContributionTeamList';
import TeamHeading from 'team/TeamHeading';

import type { ContributionStrategySectionUserFragment } from './ContributionStrategySection.graphql.generated';

type ContributionStrategySectionProps = {
  contributions: Contribution[];
  description: string;
  emptyMessage: string;
  title: string;
  user: ContributionStrategySectionUserFragment;
};

const ContributionStrategySection = forwardRef<
  HTMLElement | null,
  ContributionStrategySectionProps
>(({ user, contributions, title, description, emptyMessage }, ref) => {
  const userOrgUnitTree = user.primaryOrgUnit
    ? [
        user.primaryOrgUnit,
        ...[...user.primaryOrgUnit.parentOrgUnitTree].reverse(),
      ]
    : [];

  const sortedOrgUnits = [
    ...userOrgUnitTree,
    ...user.contributingTo.orgUnits.filter(
      (orgUnit) =>
        !userOrgUnitTree.some((userOrgUnit) => userOrgUnit.id === orgUnit.id),
    ),
  ];

  const orgContributions = contributions.filter(
    (strategyElement) => !strategyElement.orgUnit,
  );

  const contributionsByOrgUnit = sortedOrgUnits.map((orgUnit) => [
    orgUnit,
    contributions.filter(
      (strategyElement) => strategyElement.orgUnit?.id === orgUnit.id,
    ),
  ]) as [
    ContributionStrategySectionUserFragment['contributingTo']['orgUnits'][0],
    ContributionStrategySectionUserFragment['contributingTo']['asOwner'],
  ][];

  const hasContributions = contributions.length > 0;

  return (
    <ContributionSection innerRef={ref}>
      <ContributionSection.Header heading={title} description={description} />
      {hasContributions ? (
        <>
          {contributionsByOrgUnit.map(([orgUnit, contributions]) => (
            <ContributionTeamList
              heading={
                <TeamHeading
                  isPrimaryTeam={orgUnit.id === user.primaryOrgUnit?.id}
                  orgUnit={orgUnit}
                />
              }
              key={orgUnit.id}
              contributions={contributions}
            />
          ))}
          {user.org && orgContributions.length > 0 && (
            <ContributionTeamList
              heading={<TeamHeading isPrimaryTeam={false} />}
              key={user.org.id}
              contributions={orgContributions}
            />
          )}
        </>
      ) : (
        <ContributionNoSection description={emptyMessage} />
      )}
    </ContributionSection>
  );
});

export default ContributionStrategySection;
