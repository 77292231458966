import type { PropsWithChildren, ReactNode } from 'react';
import {
  Focusable,
  Tooltip as AriaTooltip,
  OverlayArrow,
  TooltipTrigger,
} from 'react-aria-components';
import styled from '@emotion/styled';
import type { Placement } from 'react-aria';
import hexToRgba from 'hex-to-rgba';
import type { SerializedStyles } from '@emotion/react';
import { css, useTheme } from '@emotion/react';

const StyledTrigger = styled.span`
  display: flex;
  align-items: center;
`;

const StyledAriaTooltip = styled(AriaTooltip)`
  background: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 0 0.75rem ${(props) => hexToRgba(props.theme.color.black, 0.1)};
  max-width: 350px;

  &[data-placement='top'] {
    margin-bottom: 8px;
    --origin: translateY(4px);
  }

  &[data-placement='bottom'] {
    margin-top: 8px;
    --origin: translateY(-4px);
    & .react-aria-OverlayArrow svg {
      transform: rotate(180deg);
    }
  }

  &[data-placement='right'] {
    margin-left: 8px;
    --origin: translateX(-4px);
    & .react-aria-OverlayArrow svg {
      transform: rotate(90deg);
    }
  }

  &[data-placement='left'] {
    margin-right: 8px;
    --origin: translateX(4px);
    & .react-aria-OverlayArrow svg {
      transform: rotate(-90deg);
    }
  }

  & .react-aria-OverlayArrow svg {
    display: block;
    fill: ${(props) => props.theme.color.strokeMedium};
  }
`;

type Props = PropsWithChildren<{
  avoidButton?: boolean;
  className?: string;
  content?: ReactNode;
  delay?: number;
  isDisabled?: boolean;
  isInline?: boolean;
  isOpen?: boolean;
  placement?: Placement;
  triggerStyle?: SerializedStyles;
}>;

const Tooltip = ({
  children,
  content,
  delay = 1000,
  placement = 'top',
  className,
  isInline = false,
  isOpen,
  avoidButton = false,
  isDisabled,
  triggerStyle,
}: Props) => {
  const theme = useTheme();

  return isDisabled ? (
    <>{children}</>
  ) : (
    <TooltipTrigger delay={delay} isOpen={isOpen}>
      {avoidButton ? (
        children
      ) : (
        <Focusable excludeFromTabOrder={true}>
          <StyledTrigger
            tabIndex={-1}
            role={'button'}
            className={className}
            css={[isInline && css({ display: 'inline-block' }), triggerStyle]}
          >
            {children}
          </StyledTrigger>
        </Focusable>
      )}
      {content && (
        <StyledAriaTooltip placement={placement} arrowBoundaryOffset={8}>
          {content}

          <OverlayArrow>
            <svg width={12} height={12}>
              <path
                d={'M0 0,L6 6,L12 0'}
                fill={theme.color.white}
                stroke={theme.color.strokeMedium}
                strokeWidth={'1'}
              />
            </svg>
          </OverlayArrow>
        </StyledAriaTooltip>
      )}
    </TooltipTrigger>
  );
};

export default Tooltip;
