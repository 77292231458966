import { ReactComponent as ANSOFF } from 'shared/static/assessment/ansoff_matrix.svg';
import { ReactComponent as BLUE_OCEAN } from 'shared/static/assessment/blue_ocean.svg';
import { ReactComponent as BSC_STRATEGY_BOARD } from 'shared/static/assessment/bsc_strategy_board.svg';
import { ReactComponent as BSC_SWOT } from 'shared/static/assessment/bsc_swot.svg';
import { ReactComponent as BUSINESS_CANVAS } from 'shared/static/assessment/business_canvas.svg';
import { ReactComponent as COMPETITIVE_STRATEGIES } from 'shared/static/assessment/competitive_strategies.svg';
import { ReactComponent as CSRD } from 'shared/static/assessment/csrd.svg';
import { ReactComponent as CUSTOMER_JOURNEY } from 'shared/static/assessment/customer_journey.svg';
import { ReactComponent as CUSTOMER_SEGMENTATION } from 'shared/static/assessment/customer_segmentation.svg';
import { ReactComponent as DOUBLE_MATERIALITY } from 'shared/static/assessment/double_materiality.svg';
import { ReactComponent as ESG } from 'shared/static/assessment/esg.svg';
import { ReactComponent as M_AND_A } from 'shared/static/assessment/m_and_a.svg';
import { ReactComponent as OBJECTIVES } from 'shared/static/assessment/objectives.svg';
import { ReactComponent as MCKINSEY_7S } from 'shared/static/assessment/mckinsey_7s.svg';
import { ReactComponent as PESTEL } from 'shared/static/assessment/pestel.svg';
import { ReactComponent as PLAYING_TO_WIN } from 'shared/static/assessment/playing_to_win.svg';
import { ReactComponent as PORTERS_FIVE_FORCES } from 'shared/static/assessment/porters.svg';
import { ReactComponent as STAKEHOLDER_ANALYSIS } from 'shared/static/assessment/stakeholders.svg';
import { ReactComponent as STRATEGY_DIAMOND } from 'shared/static/assessment/strategy_diamond.svg';
import { ReactComponent as BSC_STRATEGY_MAP } from 'shared/static/assessment/strategy_map.svg';
import { ReactComponent as SWOT } from 'shared/static/assessment/swot.svg';
import { ReactComponent as TAXONOMY } from 'shared/static/assessment/taxonomy.svg';
import { ReactComponent as TCFD } from 'shared/static/assessment/tcfd.svg';
import { ReactComponent as THREE_HORIZONS } from 'shared/static/assessment/three_horizons.svg';
import { ReactComponent as VALUE_CHAIN } from 'shared/static/assessment/value_chain.svg';
import { ReactComponent as VRIO } from 'shared/static/assessment/vrio.svg';
import { ReactComponent as FINANCIAL_BENCHMARK } from 'shared/static/assessment/financial_benchmark.svg';
import { ReactComponent as FINANCIAL_GROWTH } from 'shared/static/assessment/financial_growth.svg';
import { ReactComponent as FINANCIAL_HEALTH } from 'shared/static/assessment/financial_health.svg';
import { ReactComponent as FINANCIAL_RISK } from 'shared/static/assessment/financial_risk.svg';
import { ReactComponent as FRAMEWORKS } from 'shared/static/assessment/frameworks.svg';
import { ReactComponent as FUTURE_OPPORTUNITIES } from 'shared/static/assessment/future_opportunities.svg';
import { ReactComponent as TWO_BY_TWO } from 'shared/static/assessment/two_by_two.svg';
import { ReactComponent as UNSDG } from 'shared/static/assessment/unsdg.svg';
import { ReactComponent as GE_PORTFOLIO_ANALYSIS } from 'shared/static/assessment/ge_portfolio.svg';
import { ReactComponent as GRI } from 'shared/static/assessment/gri.svg';
import { ReactComponent as OBJECTIVE_RISKS } from 'shared/static/assessment/objective_risks.svg';
import { ReactComponent as REVENUE_ANALYSIS } from 'shared/static/assessment/revenue_analysis.svg';
import { ReactComponent as RISK_FRAMEWORKS } from 'shared/static/assessment/risk_frameworks.svg';
import { ReactComponent as RISK_MAP } from 'shared/static/assessment/risk_map.svg';
import { ReactComponent as SB_TI } from 'shared/static/assessment/sbti.svg';
import { ReactComponent as STRATEGIC_RISKS } from 'shared/static/assessment/strategic_risks.svg';
import { ReactComponent as STRATEX } from 'shared/static/assessment/stratex.svg';

const imageByAssessmentType = {
  ANSOFF,
  BLUE_OCEAN,
  BSC_STRATEGY_BOARD,
  BSC_STRATEGY_MAP,
  BSC_SWOT,
  BUSINESS_CANVAS,
  COMPETITIVE_STRATEGIES,
  CSRD,
  CUSTOMER_JOURNEY,
  CUSTOMER_SEGMENTATION,
  DOUBLE_MATERIALITY,
  ESG,
  FINANCIAL_BENCHMARK,
  FINANCIAL_GROWTH,
  FINANCIAL_HEALTH,
  FINANCIAL_RISK,
  FRAMEWORKS,
  FUTURE_OPPORTUNITIES,
  GE_PORTFOLIO_ANALYSIS,
  GRI,
  MCKINSEY_7S,
  M_AND_A,
  OBJECTIVES,
  OBJECTIVE_RISKS,
  PESTEL,
  PLAYING_TO_WIN,
  PORTERS_FIVE_FORCES,
  REVENUE_ANALYSIS,
  RISK_FRAMEWORKS,
  RISK_MAP,
  SB_TI,
  STAKEHOLDER_ANALYSIS,
  STRATEGIC_RISKS,
  STRATEGY_DIAMOND,
  STRATEX,
  SWOT,
  TAXONOMY,
  TCFD,
  THREE_HORIZONS,
  TWO_BY_TWO,
  UNSDG,
  VALUE_CHAIN,
  VRIO,
};

export default imageByAssessmentType;
