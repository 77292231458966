import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
export type ContributionLineOfSightSectionUserFragment = { __typename: 'User', id: string, displayName?: string | undefined, org?: { __typename: 'Org', id: string, orgKey: string, displayName?: string | undefined, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined } | undefined } | undefined, primaryOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined, orgUnitMemberships: { __typename: 'OrgUnitMembershipList', memberships: Array<{ __typename: 'OrgUnitMembership', roles: Array<Types.OrgUnitRole>, orgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } }> }, contributingTo: { __typename: 'ContributingTo', linkedTopLevelObjectives: Array<{ __typename: 'Objective', currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, id: string, name?: string | undefined }>, asOwner: Array<{ __typename: 'Action', id: string, name?: string | undefined } | { __typename: 'Initiative', id: string, name?: string | undefined } | { __typename: 'Metric', id: string, name?: string | undefined } | { __typename: 'Objective', id: string, name?: string | undefined }>, asTeamMember: Array<{ __typename: 'Action', id: string, name?: string | undefined } | { __typename: 'Initiative', id: string, name?: string | undefined } | { __typename: 'Metric', id: string, name?: string | undefined } | { __typename: 'Objective', id: string, name?: string | undefined }> } };

export type LineOfSightObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined };

export type LineOfSightMetricFragment = { __typename: 'Metric', id: string, name?: string | undefined };

export type LineOfSightInitiativeFragment = { __typename: 'Initiative', id: string, name?: string | undefined };

export type LineOfSightActionFragment = { __typename: 'Action', id: string, name?: string | undefined };

export const LineOfSightObjectiveFragmentDoc = gql`
    fragment lineOfSightObjective on Objective {
  id
  name
}
    `;
export const LineOfSightMetricFragmentDoc = gql`
    fragment lineOfSightMetric on Metric {
  id
  name
}
    `;
export const LineOfSightInitiativeFragmentDoc = gql`
    fragment lineOfSightInitiative on Initiative {
  id
  name
}
    `;
export const LineOfSightActionFragmentDoc = gql`
    fragment lineOfSightAction on Action {
  id
  name
}
    `;
export const ContributionLineOfSightSectionUserFragmentDoc = gql`
    fragment contributionLineOfSightSectionUser on User {
  id
  displayName
  org {
    id
    orgKey
    displayName
    currentStrategy {
      id
      name
    }
  }
  primaryOrgUnit {
    id
    name
    currentUserAuthorizedActions
  }
  orgUnitMemberships {
    memberships {
      roles
      orgUnit {
        id
        name
        currentUserAuthorizedActions
      }
    }
  }
  contributingTo {
    linkedTopLevelObjectives {
      ...lineOfSightObjective
      currentUserAuthorizedActions
    }
    asOwner {
      ... on Node {
        id
      }
      ... on Objective {
        ...lineOfSightObjective
      }
      ... on Metric {
        ...lineOfSightMetric
      }
      ... on Initiative {
        ...lineOfSightInitiative
      }
      ... on Action {
        ...lineOfSightAction
      }
    }
    asTeamMember {
      ... on Node {
        id
      }
      ... on Objective {
        ...lineOfSightObjective
      }
      ... on Metric {
        ...lineOfSightMetric
      }
      ... on Initiative {
        ...lineOfSightInitiative
      }
      ... on Action {
        ...lineOfSightAction
      }
    }
  }
}
    ${LineOfSightObjectiveFragmentDoc}
${LineOfSightMetricFragmentDoc}
${LineOfSightInitiativeFragmentDoc}
${LineOfSightActionFragmentDoc}`;