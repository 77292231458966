import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { AddMemberToOrgUnitFormProps } from 'orgUnit/AddMemberToOrgUnitForm';
import AddMemberToOrgUnitForm from 'orgUnit/AddMemberToOrgUnitForm';
import { useToasts } from 'shared/toast/useToasts';
import { useTeamMembers } from 'team/TeamMembers/TeamMembersProvider';
import type { TeamAdapter } from 'team/TeamAdapter';
import Dialog from 'shared/components/Dialog';

const addMemberToOrgUnitFormId = 'addMemberToOrgUnitForm';

type AddMemberToOrgUnitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  teamAdapter: TeamAdapter;
};

const AddMemberToOrgUnitModal = ({
  isOpen,
  onClose,
  teamAdapter,
}: AddMemberToOrgUnitModalProps) => {
  const { t } = useTranslation();

  const { users: usersToExclude } = useTeamMembers();

  const { addToast } = useToasts();

  const handleSuccess = useCallback<
    NonNullable<AddMemberToOrgUnitFormProps['onSuccess']>
  >(() => {
    addToast({
      children: t('updateSuccessToast'),
      variant: 'success',
    });
    onClose();
  }, [addToast, onClose, t]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('orgUnit.addMemberToOrgUnitForm.title')}
      confirmLabel={t('team.teamMembers.addMemberModal.submitButton')}
      cancelLabel={t('cancel')}
      formId={addMemberToOrgUnitFormId}
      size={'small'}
    >
      <AddMemberToOrgUnitForm
        id={addMemberToOrgUnitFormId}
        onSuccess={handleSuccess}
        orgUnitId={teamAdapter.keyArg}
        usersToExclude={usersToExclude}
        orgUnitFieldLabel={t('team.team')}
      />
    </Dialog>
  );
};

export default AddMemberToOrgUnitModal;
