import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { noop } from 'lodash';

import { StrategyBoardViewSettingsContext } from './StrategyBoardViewSettings.context';

type Props = PropsWithChildren<{
  isOrgUnitVisible?: boolean;
  isUserVisible?: boolean;
}>;

const StrategyBoardViewSettingsStaticProvider = ({ children }: Props) => {
  const value = useMemo(
    () => ({
      isUserVisible: true,
      isOrgUnitVisible: true,
      isMetricsVisible: true,
      isMetricScoreVisible: true,
      isInitiativesVisible: true,
      isInitiativeMilestonesVisible: false,
      isActionsVisible: false,
      isActionProgressVisible: false,
      toggleUserVisible: noop,
      toggleOrgUnitVisible: noop,
      toggleMetricsVisible: noop,
      toggleMetricScoreVisible: noop,
      toggleInitiativesVisible: noop,
      toggleInitiativeMilestonesVisible: noop,
      toggleActionsVisible: noop,
      toggleActionProgressVisible: noop,
    }),
    [],
  );

  return (
    <StrategyBoardViewSettingsContext.Provider value={value}>
      {children}
    </StrategyBoardViewSettingsContext.Provider>
  );
};

export default StrategyBoardViewSettingsStaticProvider;
