import useHandleError from 'shared/errors/useHandleError';
import EditAction from 'actions/EditAction/EditAction';
import useDialogState from 'shared/hooks/useDialogState';

import { useActionStatusModalQuery } from './ActionStatusModal.graphql.generated';
import ActionStatusForm from './ActionStatusForm';
import ActionStatusDialog from './ActionStatusDialog';

type Props = {
  actionId: string;
  isOpen: boolean;
  onClose: () => void;
  onEdit?: () => void;
};

const ActionStatusModal = ({ actionId, isOpen, onClose, onEdit }: Props) => {
  const onError = useHandleError();

  const { data } = useActionStatusModalQuery({
    variables: { actionId },
    onError,
    skip: !isOpen,
  });

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  if (!data) return null;

  const formId = `action-status-form-${data.action.id}`;

  return (
    <>
      <ActionStatusForm formId={formId} action={data.action} onClose={onClose}>
        <ActionStatusDialog
          formId={formId}
          action={data.action}
          isOpen={isOpen && !isEditOpen}
          onClose={onClose}
          onEdit={onEdit || onEditOpen}
        />
      </ActionStatusForm>

      <EditAction
        actionId={actionId}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
    </>
  );
};

export default ActionStatusModal;
