export const assessmentCategories: AssessmentCategory[] = [
  'STRATEGY_ANALYSIS',
  'STRESS_TEST',
  'RISK',
  'FUTURE',
  'FINANCIAL',
  'SUSTAINABILITY',
];

export type AssessmentCategory =
  | 'STRATEGY_ANALYSIS'
  | 'STRESS_TEST'
  | 'RISK'
  | 'FUTURE'
  | 'FINANCIAL'
  | 'SUSTAINABILITY';
