import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Text from 'shared/components/Text';
import SophieAvatar from 'ai/SophieAvatar/SophieAvatar';
import Flex from 'shared/components/Flex';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 1rem;
  margin-top: 5rem;
  gap: 36px;
`;

type Props = {
  infoText: string;
};

const Start = ({ infoText }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SophieAvatar size={'huge'} />
      <Flex direction={'column'} gap={12}>
        <Text size={'large'} isCentered={true} color={'black'}>
          {t('ai.strategySummary.start.header')}
        </Text>
        <Text size={'medium'} isCentered={true}>
          {infoText}
        </Text>
      </Flex>
    </Container>
  );
};

export default Start;
