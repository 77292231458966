import { useMemo } from 'react';

import Space from 'shared/components/Space';
import ToolCard from 'toolkit/ToolCard';
import NoMatchingResultsResult from 'shared/components/NoMatchingResultsResult';
import type { ToolCategoryId, ToolGroup } from 'toolkit/toolkit.static';
import { tools } from 'toolkit/toolkit.static';

import styles from './ToolkitOverviewToolsGrid.module.scss';

type ToolkitOverviewToolsGridProps = {
  categoryId: ToolCategoryId;
  selectedGroupsIds: Set<ToolGroup>;
};

const ToolkitOverviewToolsGrid = ({
  categoryId,
  selectedGroupsIds,
}: ToolkitOverviewToolsGridProps) => {
  const filteredTools = useMemo(
    () =>
      tools.filter(
        (tool) =>
          tool.category === categoryId &&
          (selectedGroupsIds.size === 0 ||
            tool.groups.some((groupId) => selectedGroupsIds.has(groupId))),
      ),
    [categoryId, selectedGroupsIds],
  );

  if (filteredTools.length === 0) {
    return (
      <Space isCentered={true}>
        <NoMatchingResultsResult />
      </Space>
    );
  }

  const availableTools = filteredTools.filter(
    (tool) => tool.availability === 'AVAILABLE',
  );

  const comingSoonTools = filteredTools.filter(
    (tool) => tool.availability === 'COMING_SOON',
  );

  return (
    <div className={styles.container}>
      {availableTools.map((tool) => (
        <ToolCard tool={tool} key={tool.id} />
      ))}
      {comingSoonTools.map((tool) => (
        <ToolCard tool={tool} key={tool.id} />
      ))}
    </div>
  );
};

export default ToolkitOverviewToolsGrid;
