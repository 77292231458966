import { useRouteError } from 'react-router-dom';
import { useEffect } from 'react';

import useHandleError from 'shared/errors/useHandleError';

const AppErrorBoundary = () => {
  const onError = useHandleError();
  const error = useRouteError() as Maybe<Error>;

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  return null;
};

export default AppErrorBoundary;
