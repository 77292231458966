import { css } from '@emotion/react';
import type { PropsWithChildren } from 'react';
import { useCallback } from 'react';
import * as yup from 'yup';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import Form from 'shared/form/Form';
import type {
  StrategyAssessmentInput,
  StrategyAssessmentType,
} from 'types.graphql.generated';
import { useUser } from 'user/UserProvider';

export type AssessmentFormValues = {
  content: string;
  description: string;
  id?: string;
  title: string;
  type?: StrategyAssessmentType;
};

type Props = PropsWithChildren<{
  formId: string;
  initialValues: AssessmentFormValues;
  onSubmit: (input: StrategyAssessmentInput) => void;
}>;

const AssessmentForm = ({
  initialValues,
  onSubmit,
  formId,
  children,
}: Props) => {
  const { user } = useUser();
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const handleSubmit = useCallback(
    (values: AssessmentFormValues) => {
      onSubmit({
        orgKey: activeOrg.orgKey,
        orgUnit: { idToSet: orgUnit?.id },
        owner: { idToSet: user.id },
        idToUpdate: values.id,
        type: values.type,
        title: values.title,
        description: values.description,
        content: values.content,
      });
    },
    [activeOrg.orgKey, onSubmit, orgUnit?.id, user.id],
  );

  const validationSchema = yup.object({
    title: yup.string().required(),
    content: yup.string().required(),
  });

  return (
    <Form<AssessmentFormValues>
      id={formId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      css={css({ display: 'none' })}
    >
      {children}
    </Form>
  );
};

export default AssessmentForm;
