import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import CollapsibleList from 'shared/components/CollapsibleList';
import { ReactComponent as LayoutLayersIcon } from 'shared/static/icons/icon-layout-layers.svg';

import type { StrategyBoardTheme } from '../StrategyBoard.type';
import StrategyBoardObjectiveList from '../StrategyBoardObjectiveList';

type Props = {
  isExpanded?: boolean;
  objectivesByTheme: StrategyBoardTheme;
};

const StrategyBoardThemesSection = ({
  objectivesByTheme,
  isExpanded,
}: Props) => {
  const { t } = useTranslation();

  const { theme, objectives } = objectivesByTheme;

  const isEmptySection = objectives.length === 0;

  if (isEmptySection) return null;

  return (
    <CollapsibleList
      title={theme?.name ?? t('theme.noTheme')}
      type={'objective'}
      listStyle={css`
        display: flex;
        gap: 12px;
        padding-top: 12px;
      `}
      titleIcon={LayoutLayersIcon}
      childrenCount={objectives.length}
      expanded={isExpanded}
    >
      <div css={css({ margin: '0 24px 12px 24px' })}>
        <StrategyBoardObjectiveList objectives={objectives} />
      </div>
    </CollapsibleList>
  );
};

export default StrategyBoardThemesSection;
