import { unflatten } from 'flat';
import type { i18n as I18nType, Interpolator, Formatter } from 'i18next';
import { merge } from 'lodash';

/**
 * This is a workaround to force the download of all translation files to
 * avoid an issue where the client tries to download a translation file from a previous deploy.
 *
 * https://linear.app/bluejam/issue/BLU-2014/fix-login-page-with-broken-translations
 */
import '../locales/en.shared.locale.json';
import '../locales/en.orgTerms.locale.json';
import '../locales/en.mdxEditor.locale.json';
import '../locales/no.shared.locale.json';
import '../locales/no.orgTerms.locale.json';
import '../locales/no.mdxEditor.locale.json';

// Modifies i18n.interpolator.interpolate to enable post processing of nested keys.
// i18next assumes that nested keys are not post processed and adds applyPostProcessor: false to the options.
// This function modifies the interpolate function to always set applyPostProcessor: true, so we don't have to write:
// "This objective has {{metricsCount}} $t(strategy.mission, {'applyPostProcessor': true})."
// every time in the JSON translation files, but only:
// "This objective has {{metricsCount}} $t(strategy.mission)."
// Found on: https://stackoverflow.com/questions/63176820/is-there-an-option-to-use-postprocessor-in-nesting-translations-in-i18next
// Source code of Interpolator: https://github.com/i18next/i18next/blob/b8b014f99a16af73f458c32f167e485447d76f92/src/Interpolator.js#L153
// Read more about nesting: https://www.i18next.com/translation-function/nesting
export const enablePostProcessingNestedKeysByDefault = (i18n: I18nType) => {
  const originalFn = i18n.services.interpolator.interpolate;
  const proxyFn: Interpolator['interpolate'] = function (
    string,
    fn,
    language,
    options = {},
  ) {
    options.applyPostProcessor = true;
    return originalFn.bind(i18n.services.interpolator)(
      string,
      fn,
      language,
      options,
    );
  };
  i18n.services.interpolator.interpolate = proxyFn;
};

export const lowercaseFormatter: Parameters<Formatter['add']>[1] = (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase();
  }
  return value;
};

export const capitalizeFormatter: Parameters<Formatter['add']>[1] = (value) => {
  if (typeof value === 'string') {
    return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
  }
  return value;
};

export const resourcesToBackendImportFn = async (
  language: string,
  namespace: string,
) => {
  const [namespaceFile, orgTermsFile] = await Promise.all([
    import(`../../shared/locales/${language}.${namespace}.locale.json`),
    import(`../../shared/locales/${language}.orgTerms.locale.json`),
  ]);

  return merge(unflatten(namespaceFile), unflatten(orgTermsFile));
};
