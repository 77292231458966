import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

const Content = styled.div`
  *:first-child {
    margin-top: 0;
  }

  hr {
    background-color: ${(props) => props.theme.color.strokeMedium};
    height: 1px;
    border: 0;
  }

  table {
    th {
      padding: 6px;
    }

    td {
      border: 1px solid ${(props) => props.theme.color.strokeMedium};
      padding: 6px;
    }
  }
`;

type Props = {
  content: string;
};

const MarkdownContent = ({ content }: Props) => {
  return (
    <Content>
      <Markdown>{content}</Markdown>
    </Content>
  );
};

export default MarkdownContent;
