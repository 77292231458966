import type { TFunction } from 'react-i18next';

import type { LinkTabsProps } from 'shared/components/LinkTabs';
import type { TeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import { categories } from 'toolkit/toolkit.static';

export const getLinkTabsItems = (
  teamAdapter: TeamAdapter,
  t: TFunction,
): LinkTabsProps['items'] =>
  categories.map((category) => ({
    name: t(`toolkit.categories.${category.id}.shortName`),
    to: toolkitRoutesPaths.category({
      params: {
        teamSlug: teamAdapter.toParam(),
        category: category.id,
      },
    }),
  }));
