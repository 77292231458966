import { useMemo } from 'react';
import type { SetOptional } from 'type-fest';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { useTheme } from '@emotion/react';

import Space from 'shared/components/Space';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import {
  SelectOrgUnitInputProvider,
  SelectOrgUnitInputField,
} from 'orgUnit/SelectOrgUnitInput';
import type { FormProps } from 'shared/form/Form';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import { ColorPickerField } from 'shared/components/ColorPicker';
import MultiUserInputField from 'shared/components/MultiUserInput/MultiUserInput.field';

import { createValidationSchema } from './OrgUnitForm.schema';
import type { OrgUnitFormValues } from './OrgUnitForm.type';
import { createInitialValues } from './OrgUnitForm.utils';
import styles from './OrgUnitForm.module.scss';

export type OrgUnitFormProps = SetOptional<
  Pick<
    FormProps<OrgUnitFormValues>,
    'initialValues' | 'initialErrors' | 'onSubmit' | 'id' | 'className'
  >,
  'initialValues'
> & {
  orgUnitId?: string;
};

const OrgUnitForm = ({
  onSubmit,
  initialValues: initialValuesProp,
  initialErrors,
  orgUnitId,
  ...restProps
}: OrgUnitFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const initialValues = useMemo(() => {
    if (initialValuesProp) {
      return initialValuesProp;
    }
    return createInitialValues(theme);
  }, [initialValuesProp, theme]);

  const validationSchema = useMemo(() => createValidationSchema(t), [t]);

  return (
    <Form
      validateOnChange={true}
      validateOnBlur={true}
      {...restProps}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Space direction={'vertical'}>
        <Space size={'custom'} value={'3rem'}>
          <FieldBox
            name={'name'}
            label={t('orgUnit.orgUnitForm.name.label')}
            hasMargin={false}
            className={styles.nameFieldBox}
          >
            <Field name={'name'} component={TextInputField} />
          </FieldBox>
          <FieldBox
            name={'nameAbbreviated'}
            label={t('orgUnit.orgUnitForm.nameAbbreviated.label')}
            help={t('orgUnit.orgUnitForm.nameAbbreviated.help')}
            hasMargin={false}
          >
            <Field
              name={'nameAbbreviated'}
              component={TextInputField}
              className={styles.nameAbbreviatedField}
              maxLength={3}
            />
          </FieldBox>
        </Space>
        <FieldBox
          name={'description'}
          label={t('orgUnit.orgUnitForm.description.label')}
          isOptional={true}
          hasMargin={false}
        >
          <Field
            name={'description'}
            component={TextInputField}
            placeholder={t('orgUnit.orgUnitForm.description.placeholder')}
          />
        </FieldBox>
        <FieldBox
          name={'parent'}
          label={t('orgUnit.orgUnitForm.parent.label')}
          hasMargin={false}
        >
          <SelectOrgUnitInputProvider
            filterOrgByAction={'WRITE_STRATEGY'}
            filterOrgUnitsByAction={'WRITE'}
            excludeOrgUnitIds={[orgUnitId]}
          >
            <Field
              name={'parent'}
              component={SelectOrgUnitInputField}
              size={'full'}
            />
          </SelectOrgUnitInputProvider>
        </FieldBox>

        <FieldBox
          name={'lead'}
          label={t('orgUnit.orgUnitForm.lead.label')}
          hasMargin={false}
        >
          <UserEmailInputProvider>
            <Field component={UserEmailInputField} name={'lead'} />
          </UserEmailInputProvider>
        </FieldBox>

        <FieldBox
          name={'admins'}
          label={t('orgUnit.orgUnitForm.admins.label')}
          hasMargin={false}
        >
          <UserEmailInputProvider>
            <Field component={MultiUserInputField} name={'admins'} />
          </UserEmailInputProvider>
        </FieldBox>

        <FieldBox
          name={'backgroundColor'}
          label={t('orgUnit.orgUnitForm.backgroundColor.label')}
          hasMargin={false}
        >
          <Field component={ColorPickerField} name={'backgroundColor'} />
        </FieldBox>
      </Space>
    </Form>
  );
};

export default OrgUnitForm;
