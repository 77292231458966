import { useCallback, useState } from 'react';

export default function useDialogState(initialValue = false) {
  const [isOpen, setIsOpen] = useState(initialValue);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  return { isOpen, onOpen, onClose, onToggle, setIsOpen };
}
