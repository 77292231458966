import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash';

import Spinner from 'shared/spinner/Spinner';
import {
  getObjectivesThemes,
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { useTeamAdapter } from 'team/TeamAdapter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import { useThemeFilter } from 'shared/filters/ThemeFilter';
import { useStatusFilter } from 'shared/filters/StatusFilter';
import { useUserFilter } from 'shared/filters/UserFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';

import type { StrategyObjectivesProviderContextValue } from './StrategyObjectivesProvider.context';
import { StrategyObjectivesProviderContext } from './StrategyObjectivesProvider.context';
import type { StrategyObjectivesThemeFragment } from './StrategyObjectivesProvider.graphql.generated';
import {
  useStrategyObjectivesOrgQuery,
  useStrategyObjectivesOrgUnitQuery,
} from './StrategyObjectivesProvider.graphql.generated';
import {
  filterObjectivesByStatusIndicator,
  filterObjectivesByUser,
  filterObjectivesByDates,
  getObjectivesOwners,
  filterObjectivesByPriority,
  filterObjectivesByTheme,
  filterObjectivesByCollaboratingTeams,
  filterObjectivesByCompleted,
} from './StrategyObjectivesProvider.utils';
import type {
  StrategyObjectivesByTheme,
  StrategyObjectivesObjective,
} from '../StrategyObjectives.type';

type StrategyObjectivesProviderProps = PropsWithChildren<object>;

const StrategyObjectivesProvider = ({
  children,
}: StrategyObjectivesProviderProps) => {
  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const { orgUnit } = useOrgUnit();

  const { teamAdapter } = useTeamAdapter();

  const onError = useHandleError();

  const { data: orgData } = useStrategyObjectivesOrgQuery({
    skip: teamAdapter.isOrgUnit,
    onError,
  });

  const { data: orgUnitData } = useStrategyObjectivesOrgUnitQuery({
    variables: { orgUnitId: teamAdapter.keyArg },
    skip: teamAdapter.isOrg,
    onError,
  });

  const topLevelObjectives = useMemo(
    () =>
      (teamAdapter.isOrg
        ? orgData?.activeOrg.objectives
        : orgUnitData?.orgUnit.objectives
      )?.edges.map((n) => n.node) || [],
    [teamAdapter.isOrg, orgData, orgUnitData],
  );

  const allObjectives = useMemo(
    () =>
      uniqBy(
        [
          ...topLevelObjectives,
          ...topLevelObjectives.flatMap(
            (objective) => objective.childObjectiveTree,
          ),
        ],
        (objective) => objective.id,
      ).map((objective) => ({ ...objective, isFiltersMatch: true })),
    [topLevelObjectives],
  );

  const { selectedThemeIds, setAvailableThemes } = useThemeFilter();

  const { statuses } = useStatusFilter();

  const { selectedUserIds, setAvailableUsers } = useUserFilter();

  const { startDate, endDate } = useTimelineFilter();

  const { priorities } = usePriorityFilter();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  useEffect(() => {
    setAvailableThemes(getObjectivesThemes(allObjectives));
    setAvailableUsers(getObjectivesOwners(allObjectives));
  }, [allObjectives, setAvailableThemes, setAvailableUsers]);

  const contextValue = useMemo<
    StrategyObjectivesProviderContextValue | undefined
  >(() => {
    if (isPrioritiesLoading) return;

    const topLevelObjectivesIds = topLevelObjectives.map(
      (objective) => objective.id,
    );

    const objectivesWithFilterFlag = filterObjectivesByCollaboratingTeams(
      filterObjectivesByDates(
        filterObjectivesByDates(
          filterObjectivesByUser(
            filterObjectivesByCompleted(
              filterObjectivesByStatusIndicator(
                filterObjectivesByPriority(
                  filterObjectivesByTheme(allObjectives, selectedThemeIds),
                  priorities,
                ),
                statuses,
              ),
              showCompleted,
            ),
            selectedUserIds,
          ),
          startDate,
          endDate,
        ),
        calendarIntervalStartDate,
        calendarIntervalEndDate,
        calendarCheck,
      ),
      showCollaboratingTeams,
      topLevelObjectivesIds,
      orgUnit?.id,
    );

    const matchedObjectivesIds = objectivesWithFilterFlag
      .filter((objective) => objective.isFiltersMatch)
      .map((objective) => objective.id);

    const filteredObjectives = objectivesWithFilterFlag.filter(
      (objective) =>
        objective.isFiltersMatch ||
        objective.childObjectiveTree.some((childObjective) =>
          matchedObjectivesIds.includes(childObjective.id),
        ),
    );

    const allObjectiveIds = allObjectives.map((objective) => objective.id);
    const filteredTopLevelObjectives = filteredObjectives.filter(
      (objective) =>
        !objective.parentObjective ||
        !allObjectiveIds.includes(objective.parentObjective.id),
    );

    const objectivesByThemes = groupObjectivesByTheme<
      StrategyObjectivesObjective,
      StrategyObjectivesThemeFragment
    >(filteredTopLevelObjectives).reduce<StrategyObjectivesByTheme[]>(
      (result, objectivesByTheme) => [
        ...result,
        {
          ...objectivesByTheme,
          childObjectives: objectivesByTheme.objectives.flatMap((objective) =>
            objective.childObjectiveTree
              .map((childObjective) =>
                filteredObjectives.find(
                  (objective) => objective.id === childObjective.id,
                ),
              )
              .filter(Boolean),
          ),
        },
      ],
      [],
    );

    const sortedObjectivesByThemes = sortObjectivesByTheme(
      objectivesByThemes,
      orgUnitData
        ? [
            orgUnitData.orgUnit,
            ...[...orgUnitData.orgUnit.parentOrgUnitTree].reverse(),
          ]
        : [],
    );

    const activeObjectives = allObjectives.filter(
      (objective) => objective.timeLine.completeDateTime == null,
    );
    const activeThemes = getObjectivesThemes(activeObjectives) || [];
    const completedObjectives = allObjectives.filter(
      (objective) => objective.timeLine.completeDateTime !== null,
    );
    const filteredCompletedObjectives = filterObjectivesByStatusIndicator(
      completedObjectives,
      statuses,
    );

    return {
      activeThemesCount: activeThemes.length,
      objectives: filteredObjectives,
      objectivesByThemes: sortedObjectivesByThemes,
      activeObjectives,
      completedObjectives: filteredCompletedObjectives,
    };
  }, [
    allObjectives,
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    endDate,
    isPrioritiesLoading,
    orgUnit?.id,
    orgUnitData,
    priorities,
    selectedThemeIds,
    selectedUserIds,
    showCollaboratingTeams,
    showCompleted,
    startDate,
    statuses,
    topLevelObjectives,
  ]);

  if (!contextValue) return <Spinner.Circle />;

  return (
    <StrategyObjectivesProviderContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesProviderContext.Provider>
  );
};

export default StrategyObjectivesProvider;
