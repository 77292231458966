import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import FieldBox from 'shared/form/FieldBox';
import Flex from 'shared/components/Flex';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import type { OrgFormValues } from 'org/EditOrg/OrgForm';
import { TextInputField } from 'shared/components/TextInput';
import { ColorPickerField } from 'shared/components/ColorPicker';
import MultiFieldRow from 'shared/form/MultiFieldRow';
import { enumerate } from 'shared/utils/string.utils';
import { CheckboxField } from 'shared/components/CheckboxV2';

import type { EditOrgOrgFragment } from './GetOrgForEdit.graphql.generated';

const FieldColumn = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`;

type Props = {
  org: EditOrgOrgFragment;
};

const OrgQuickFormFields = ({ org }: Props) => {
  const { t } = useTranslation();

  const { getFieldMeta } = useFormikContext<OrgFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  return (
    <Flex direction={'column'} gap={20}>
      <MultiFieldRow>
        <FieldBox
          name={'name'}
          label={t('org.form.field.name.label')}
          hasError={hasError('name')}
          hasMargin={false}
        >
          <Field
            component={TextInputField}
            name={'name'}
            hasError={hasError('name')}
            fontSize={'big'}
            maxLength={100}
            autoFocus={true}
          />
        </FieldBox>

        <FieldBox
          name={'acronym'}
          label={t('org.form.field.acronym.label')}
          hasError={hasError('acronym')}
          hasMargin={false}
          css={css({ maxWidth: 180 })}
        >
          <Field
            component={TextInputField}
            name={'acronym'}
            hasError={hasError('acronym')}
            fontSize={'big'}
            maxLength={3}
            autoFocus={true}
          />
        </FieldBox>
      </MultiFieldRow>

      <FieldBox
        name={'lead'}
        label={t('org.form.field.lead.label')}
        hasError={hasError('lead')}
        hasMargin={false}
      >
        <UserEmailInputProvider>
          <Field
            component={UserEmailInputField}
            name={'lead'}
            hasError={hasError('lead')}
            autoComplete={'false'}
          />
        </UserEmailInputProvider>
      </FieldBox>

      <FieldBox
        name={'color'}
        label={t('org.form.field.color.label')}
        hasError={hasError('color')}
        hasMargin={false}
      >
        <Field
          component={ColorPickerField}
          name={'color'}
          hasError={hasError('color')}
        />
      </FieldBox>

      <FieldBox
        name={'industryName'}
        label={t('org.form.field.industryName.label')}
        hasError={hasError('industryName')}
        hasMargin={false}
      >
        <Field
          component={TextInputField}
          name={'industryName'}
          hasError={hasError('industryName')}
        />
      </FieldBox>

      <FieldBox
        name={'industryDescription'}
        label={t('org.form.field.industryDescription.label')}
        hasError={hasError('industryDescription')}
        hasMargin={false}
      >
        <Field
          component={TextInputField}
          name={'industryDescription'}
          hasError={hasError('industryDescription')}
        />
      </FieldBox>

      <Flex gap={8}>
        <FieldColumn>
          <FieldBox
            name={'authMethods'}
            label={t('org.form.field.authMethods.label')}
            hasError={hasError('authMethods')}
            hasMargin={false}
          >
            {enumerate(
              t,
              org.systemPreferences.authenticationMethods.map((authMethod) =>
                t(`AuthenticationMethod.${authMethod}`),
              ),
            )}
          </FieldBox>
        </FieldColumn>

        <FieldColumn>
          <FieldBox
            name={'doAutoCreateUserAccounts'}
            label={t('org.form.field.doAutoCreateUserAccounts.label')}
            hasError={hasError('doAutoCreateUserAccounts')}
            hasMargin={false}
          >
            <Field
              component={CheckboxField}
              name={'doAutoCreateUserAccounts'}
              hasError={hasError('doAutoCreateUserAccounts')}
              disabled={true}
            />
          </FieldBox>
        </FieldColumn>

        <FieldColumn>
          <FieldBox
            name={'scimUserProvisioningEnabled'}
            label={t('org.form.field.scimUserProvisioningEnabled.label')}
            hasError={hasError('scimUserProvisioningEnabled')}
            hasMargin={false}
          >
            <Field
              component={CheckboxField}
              name={'scimUserProvisioningEnabled'}
              hasError={hasError('scimUserProvisioningEnabled')}
              disabled={true}
            />
          </FieldBox>
        </FieldColumn>
      </Flex>

      <Flex gap={8}>
        <FieldColumn>
          <FieldBox
            name={'doSendEmails'}
            label={t('org.form.field.doSendEmails.label')}
            hasError={hasError('doSendEmails')}
            hasMargin={false}
          >
            <Field
              component={CheckboxField}
              name={'doSendEmails'}
              hasError={hasError('doSendEmails')}
            />
          </FieldBox>
        </FieldColumn>

        <FieldColumn>
          <FieldBox
            name={'showLinksInEmailsEnabled'}
            label={t('org.form.field.showLinksInEmailsEnabled.label')}
            hasError={hasError('showLinksInEmailsEnabled')}
            hasMargin={false}
          >
            <Field
              component={CheckboxField}
              name={'showLinksInEmailsEnabled'}
              hasError={hasError('showLinksInEmailsEnabled')}
              disabled={true}
            />
          </FieldBox>
        </FieldColumn>

        <FieldColumn>
          <FieldBox
            name={'userHighlightsEmailEnabled'}
            label={t('org.form.field.userHighlightsEmailEnabled.label')}
            hasError={hasError('userHighlightsEmailEnabled')}
            hasMargin={false}
          >
            <Field
              component={CheckboxField}
              name={'userHighlightsEmailEnabled'}
              hasError={hasError('userHighlightsEmailEnabled')}
            />
          </FieldBox>
        </FieldColumn>
      </Flex>
    </Flex>
  );
};

export default OrgQuickFormFields;
