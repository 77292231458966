import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { ChartProps } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { Tooltip, Chart as ChartJSChart } from 'chart.js';
import { useTranslation } from 'react-i18next';
import Annotation from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useCallback, useRef } from 'react';
import { merge } from 'lodash';
import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useSessionStorage } from 'react-use';

import { transientOptions } from 'shared/utils/emotion.utils';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ChartContainer = styled.div`
  max-height: 320px;
  min-height: 240px;
  width: 100%;
`;

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Entry = styled.li(
  ({ theme }: { theme: Theme }) => css`
    align-items: center;
    background-color: ${theme.color.backgroundLight};
    border-radius: 8px;
    color: ${theme.color.typoSecondary};
    display: flex;
    gap: 8px;
    padding: 8px 20px;
  `,
);

const Strong = styled.span(
  ({ theme }: { theme: Theme }) => css`
    color: ${theme.color.typoPrimary};
    font-weight: 700;
  `,
);

const Indicator = styled('div', transientOptions)<{
  $backgroundColor?: string;
  $borderColor?: string;
}>`
  border: ${(props) =>
    props.$borderColor
      ? `2px solid ${props.$borderColor}`
      : `1px solid ${props.theme.color.white}`};
  border-radius: 1px;
  height: 9px;
  width: 9px;
  background-color: ${(props) => props.$backgroundColor};
`;

ChartJSChart.register(Tooltip, Annotation, ChartDataLabels);

const showValuesStorageKey = 'show-chart-values';

export type Legend = {
  backgroundColor?: string;
  borderColor?: string;
  label: string;
  value?: number;
};

type Props = ChartProps & {
  legends: Legend[];
};

const ChartWithCustomLegend = (props: Props) => {
  const chartRef = useRef<ChartJSOrUndefined>(null);

  const { t } = useTranslation();
  const { options, legends, ...restProps } = props;

  const [showValues, setShowValues] = useSessionStorage(
    showValuesStorageKey,
    false,
  );

  const finalOptions = merge(options, {
    plugins: { datalabels: { display: showValues } },
  });

  const onShowValues = useCallback(() => {
    setShowValues(true);
    setTimeout(() => chartRef.current?.update(), 0);
  }, [setShowValues]);

  const onHideValues = useCallback(() => {
    setShowValues(false);
    setTimeout(() => chartRef.current?.update(), 0);
  }, [setShowValues]);

  return (
    <Container>
      <ChartContainer>
        <Chart
          ref={chartRef}
          {...restProps}
          options={finalOptions}
          updateMode={'default'}
        />
      </ChartContainer>

      <Flex justifyContent={'space-between'} gap={8}>
        <Legend>
          {legends.map((legend) => (
            <Entry key={legend.label}>
              <Indicator
                $backgroundColor={legend.backgroundColor}
                $borderColor={legend.borderColor}
              />
              <div>
                {legend.label}
                {`: `}
                <Strong>
                  {legend.value === undefined ? t('none') : legend.value}
                </Strong>
              </div>
            </Entry>
          ))}
        </Legend>

        {showValues ? (
          <Button variant={'simple'} onPress={onHideValues}>
            {t('chart.hideValues')}
          </Button>
        ) : (
          <Button variant={'simple'} onPress={onShowValues}>
            {t('chart.showValues')}
          </Button>
        )}
      </Flex>
    </Container>
  );
};

export default ChartWithCustomLegend;
