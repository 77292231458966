import { css } from '@emotion/react';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as FilterIcon } from 'shared/static/icons/icon-filter.svg';
import useDialogState from 'shared/hooks/useDialogState';

import Button from '../../components/Button';
import SideModal from '../../components/SideModal';
import ClearAllButton from '../../components/ClearAllButton';
import { useChipGroup } from '../ChipGroup';

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
`;

const SelectionCount = styled.div`
  top: 5.5px;
  right: 5.5px;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

const FiltersButton = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialogState();

  const { chipItems, clearAllChipItems } = useChipGroup();

  const selectionCount = chipItems.length;

  return (
    <>
      <div css={css({ position: 'relative' })}>
        <Button
          icon={FilterIcon}
          variant={'icon'}
          css={css({ width: 42, height: 42, boxSizing: 'border-box' })}
          onPress={onOpen}
        />

        {selectionCount > 0 && (
          <SelectionCount>{selectionCount}</SelectionCount>
        )}
      </div>

      <SideModal
        headerTitle={t('filters.title')}
        headerControls={
          selectionCount > 0 && <ClearAllButton onClear={clearAllChipItems} />
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <FiltersContainer>{children}</FiltersContainer>
      </SideModal>
    </>
  );
};

export default FiltersButton;
