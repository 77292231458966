import type { StrategyAssessmentType } from 'types.graphql.generated';

import imageByAssessmentType from './imageByAssessmentType';

export type AssessmentImageProps = {
  type: StrategyAssessmentType;
};

const AssessmentImage = ({ type }: AssessmentImageProps) => {
  const Image = imageByAssessmentType[type];

  return <Image />;
};

export default AssessmentImage;
