import type { Metric, Objective } from 'types.graphql.generated';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';
import {
  testStatusIndicatorAgainstIds,
  type StatusFilterValue,
} from 'shared/filters/StatusFilter';
import {
  testPriorityFilters,
  type PriorityFilterValue,
} from 'shared/filters/PriorityFilter';

import type { StrategyMetricsObjective } from './StrategyMetricsProvider.type';

export const getObjectivesWithMetrics = <
  TObjective extends Maybe<
    Pick<Objective, 'id'> & { metrics: Pick<Metric, 'id'>[] }
  >,
>(
  objectives: Maybe<TObjective[]>,
) => objectives?.filter((objective) => !!objective?.metrics?.length);

export const filterObjectivesByStatusIndicator = (
  objectives: StrategyMetricsObjective[],
  statuses: StatusFilterValue[],
) =>
  objectives.map((objective) => ({
    ...objective,
    metrics: objective.metrics.filter((metric) =>
      testStatusIndicatorAgainstIds(
        statuses,
        metric.currentMetricStatus?.statusIndicator,
      ),
    ),
  }));

export const filterObjectivesByCompleted = (
  objectives: StrategyMetricsObjective[],
  showCompleted: boolean,
) =>
  objectives.map((objective) => ({
    ...objective,
    metrics: objective.metrics.filter(
      (metric) => showCompleted || !metric.currentMetricStatus?.complete,
    ),
  }));

export const filterObjectiveMetricsByPriority = (
  objectives: StrategyMetricsObjective[],
  priorities: PriorityFilterValue[],
) =>
  objectives.map((objective) => ({
    ...objective,
    metrics: objective.metrics.filter((metric) =>
      testPriorityFilters(priorities, metric.priority),
    ),
  }));

export const filterObjectivesByUser = (
  objectives: StrategyMetricsObjective[],
  userIds: string[],
) => {
  if (userIds.length === 0) {
    return objectives;
  }

  return objectives
    .map((objective) => ({
      ...objective,
      metrics: objective.metrics.filter((metric) =>
        userIds.some((userId) => userId === metric.owner?.id),
      ),
    }))
    .filter((objective) => objective.metrics.length);
};

export const filterObjectivesByTheme = (
  objectives: StrategyMetricsObjective[],
  themeIds: string[],
) =>
  objectives.filter(
    (objective) =>
      themeIds.length === 0 ||
      themeIds.some((themeId) => themeId === objective.theme?.id),
  );

export const filterObjectivesByDates = (
  objectives: StrategyMetricsObjective[],
  start: Maybe<Date>,
  end: Maybe<Date>,
  check?: CalendarCheck,
) => {
  if (!start && !end) {
    return objectives;
  }

  return objectives
    .map((objective) => ({
      ...objective,
      metrics: objective.metrics.filter((metric) =>
        testStrategyItemAgainstInterval(metric, { start, end }, check),
      ),
    }))
    .filter((objective) => objective.metrics.length);
};

export const filterObjectivesByCollaboratingTeams = (
  objectives: StrategyMetricsObjective[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const getObjectivesOwners = (objectives: StrategyMetricsObjective[]) =>
  Object.values(
    objectives.reduce<Record<string, StrategyMetricsObjective['owner']>>(
      (accumulator, objective) => {
        objective.metrics.forEach((metric) => {
          const user = metric.owner;
          if (user && !accumulator[user.id]) {
            accumulator[user.id] = user;
          }
        });
        return accumulator;
      },
      {},
    ),
  ).filter(Boolean);
