import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

export function useViewSetting(key: string, defaultValue: boolean) {
  const [value = defaultValue, setValue] = useLocalStorage(key, defaultValue);

  const toggleValue = useCallback(() => setValue(!value), [value, setValue]);

  return [value, toggleValue] as const;
}
