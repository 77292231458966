import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import AiLoadingIcon from 'ai/AiLoadingIcon';
import useHandleError from 'shared/errors/useHandleError';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import MarkdownContent from 'shared/components/MarkdownContent';
import SophieAvatar from 'ai/SophieAvatar/SophieAvatar';

import { useAskSophieSidePanelSubscription } from '../AskSohpieButton/AskSophieSidePanel/AskSophieSidePanel.graphql.generated';
import type { SummaryMessage } from './AskSophieChat.type';
import { useAskSophieChat } from './AskSophieChatProvider';
import ChatBubble from './ChatBubble';

const IconContainer = styled.div`
  align-self: flex-start;
`;

export const Spacer = styled.div`
  flex-shrink: 0;
  width: 28px;
`;

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  summary: SummaryMessage;
};

const SummaryMessageItem = ({ summary, setIsLoading }: Props) => {
  const { activeOrg } = useActiveOrg();
  const [content, setContent] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [tempIncrement, setTempIncrement] = useState(0);
  const [shouldSkip, setShouldSkip] = useState(summary.skipQueryOnLoad);

  const { onSummaryGenerated, regenerateButtonLabel } = useAskSophieChat();

  const handleError = useHandleError();

  useEffect(() => {
    if (!shouldSkip) {
      setIsLoading(true);
    }
  }, [setIsLoading, shouldSkip]);

  const temperature = (summary.promptMessage.temperature || 1) + tempIncrement;

  const { error } = useAskSophieSidePanelSubscription({
    skip: shouldSkip,
    variables: {
      input: {
        languageLocaleCode: activeOrg.languageLocaleCode,
        maxNumberOfCharacters: summary.promptMessage.numberOfCharacters,
        mainPrompt: summary.promptMessage.mainPrompt,
        mainPromptKey: summary.promptMessage.mainPromptKey,
        elements: {
          objectiveIds: summary.context.objectiveIds,
          metricIds: summary.context.metricIds,
          initiativeIds: summary.context.initiativeIds,
          actionIds: summary.context.actionIds,
          strategyIds: summary.context.strategyId
            ? [summary.context.strategyId]
            : undefined,
        },
        hints: [
          ...(summary.promptMessage.hints || []),
          'Use markdown to format your response',
        ],
        model: { temperature },
      },
    },
    onSubscriptionData: (result) => {
      setContent(
        (prevData) => prevData + result.subscriptionData.data?.aiStream,
      );
    },
    onSubscriptionComplete: () => {
      setIsComplete(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (isComplete) {
      onSummaryGenerated?.(content);
    }
  }, [content, isComplete, onSummaryGenerated]);

  useEffect(() => {
    if (error) {
      handleError(error);
      setIsLoading(false);
    }
  }, [error, handleError, setIsLoading]);

  const hasContent = content !== '';
  const shouldShow = !error && (!isComplete || hasContent);

  const regenerate = useCallback(() => {
    setShouldSkip(false);
    setContent('');
    setIsComplete(false);
    setTempIncrement((currentTemp) => currentTemp + 0.01);
    onSummaryGenerated?.(undefined);
  }, [onSummaryGenerated]);

  if (!shouldShow) return null;

  return (
    <>
      <IconContainer>
        <SophieAvatar size={'small'} width={35} />
      </IconContainer>
      <ChatBubble $messageType={'SummaryMessage'}>
        <Flex direction={'column'} gap={8}>
          {hasContent && <MarkdownContent content={content} />}
          {!shouldSkip && !isComplete && (
            <div css={css({ marginTop: hasContent ? '2rem' : 0 })}>
              <AiLoadingIcon />
            </div>
          )}
          {(shouldSkip || isComplete) && (
            <Flex justifyContent={'center'}>
              {regenerateButtonLabel && (
                <Button
                  icon={RefreshIcon}
                  iconPosition={'start'}
                  variant={'outlined'}
                  onPress={regenerate}
                >
                  {regenerateButtonLabel}
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </ChatBubble>
      <Spacer />
    </>
  );
};

export default SummaryMessageItem;
