import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import { SelectField } from 'shared/components/Select';

import { useThemeForm } from '../ThemeFormProvider';

const ThemeFormFields = () => {
  const { t } = useTranslation();

  const themeCategoryFieldOptions = useThemeForm();

  return (
    <>
      <FieldBox name={'name'} label={t('theme.themeForm.name.label')}>
        <Field name={'name'} component={TextInputField} />
      </FieldBox>
      <FieldBox
        name={'description'}
        label={t('theme.themeForm.description.label')}
      >
        <Field name={'description'} component={TextInputField} />
      </FieldBox>
      <FieldBox
        name={'themeCategory'}
        label={t('theme.themeForm.themeCategory.label')}
      >
        <Field
          name={'themeCategory'}
          component={SelectField}
          fieldLabel={t('theme.themeForm.themeCategory.label')}
          options={themeCategoryFieldOptions}
          emptyOptionLabel={t('theme.themeForm.themeCategory.empty')}
          fullWidth={true}
        />
      </FieldBox>
    </>
  );
};

export default ThemeFormFields;
