import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyBoardViewSettingsContext } from './StrategyBoardViewSettings.context';
import StrategyBoardViewSettingsProvider from './StrategyBoardViewSettingsProvider';

export const useStrategyBoardViewSettings = () =>
  useDefinedContext(StrategyBoardViewSettingsContext, {
    hookName: useStrategyBoardViewSettings.name,
    providerName: StrategyBoardViewSettingsProvider.name,
  });
