import type { PropsWithChildren } from 'react';
import { useState, useMemo } from 'react';

import type { ObjectivesSort } from 'actions/actions.type';
import useHandleError from 'shared/errors/useHandleError';

import type { StrategyObjectivesActionsContextValue } from './StrategyActionsProvider.context';
import { StrategyObjectivesActionsContext } from './StrategyActionsProvider.context';
import { useStrategyActionsQuery } from './StrategyActionsProvider.graphql.generated';
import { getObjectivesWithActions } from './StrategyActions.utils';
import { useTeamAdapter } from '../../team/TeamAdapter';

type StrategyActionsProviderProps = PropsWithChildren<object>;

const StrategyActionsProvider = ({
  children,
}: StrategyActionsProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const onError = useHandleError();

  const { data, loading } = useStrategyActionsQuery({
    variables: {
      filter: {
        orgUnitIds: teamAdapter.isOrg
          ? { operator: 'IS_NULL' }
          : { operator: 'IN', values: [teamAdapter.keyArg] },
      },
    },
    onError,
  });

  const [objectivesSort, setObjectivesSort] =
    useState<ObjectivesSort>('deadlineAsc');

  const objectives = useMemo(
    () => data?.activeOrg.objectives?.edges.map((n) => n.node) || [],
    [data?.activeOrg.objectives?.edges],
  );

  const objectivesWithActions = useMemo(
    () => getObjectivesWithActions(objectives),
    [objectives],
  );

  const contextValue = useMemo<StrategyObjectivesActionsContextValue>(
    () => ({
      objectives,
      objectivesWithActions,
      objectivesSort,
      setObjectivesSort,
      isStrategyActionsLoading: loading && !objectives,
    }),
    [loading, objectives, objectivesSort, objectivesWithActions],
  );

  return (
    <StrategyObjectivesActionsContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesActionsContext.Provider>
  );
};

export default StrategyActionsProvider;
