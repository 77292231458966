import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { last, without } from 'lodash';
import type { Key } from 'react-aria-components';

import { getActionStatusLabel } from './ActionStatusFilter.utils';
import type { ActionStatusFilterValue } from './ActionStatusFilter.context';
import { useActionStatusFilter } from './useActionStatusFilter';
import FilterMultiSelect from '../FilterMultiSelect';

export type ActionStatusFilterSelectItemId = ActionStatusFilterValue | 'ALL';

export type ActionStatusFilterSelectItem = {
  id: ActionStatusFilterSelectItemId;
};

const itemIds = [
  'ALL',
  'INACTIVE',
  'ACTIVE',
] satisfies ActionStatusFilterSelectItemId[];

const StatusFilter = () => {
  const { t } = useTranslation();

  const { actionStatuses, setActionStatuses, clearActionStatuses } =
    useActionStatusFilter();

  const items = useMemo(
    () => itemIds.map((id) => ({ id, title: getActionStatusLabel(t, id) })),
    [t],
  );

  const selectedKeys = useMemo(
    () => (actionStatuses.length === 0 ? ['ALL'] : actionStatuses),
    [actionStatuses],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Key[]) =>
      last(selectedKeys) === 'ALL'
        ? clearActionStatuses()
        : setActionStatuses(
            without([...selectedKeys], 'ALL') as ActionStatusFilterValue[],
          ),
    [clearActionStatuses, setActionStatuses],
  );

  const selectionLabel = selectedKeys.includes('ALL')
    ? t('status.allStatus')
    : selectedKeys.length === 0
    ? t('none')
    : t('actions.actionStatusMultiSelect.statusCount', {
        count: selectedKeys.length,
      });

  return (
    <FilterMultiSelect
      items={items}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      fieldLabel={t('actions.actionStatusMultiSelect.label')}
      selectionLabel={selectionLabel}
    />
  );
};

export default StatusFilter;
