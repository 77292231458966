import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Caption from 'shared/components/Caption';
import Flex from 'shared/components/Flex';

import type { StrategyBoardObjective } from '../StrategyBoard.type';
import { useStrategyBoardViewSettings } from '../StrategyBoardViewSettings/useStrategyBoardViewSettings';
import StrategyBoardItemCard from './StrategyBoardItemCard';

const ObjectiveListItem = styled.li`
  display: flex;
  gap: 8px;
  padding: 8px;
  justify-content: space-between;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.backgroundDark};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`;

type Props = {
  objectives: StrategyBoardObjective[];
};

const StrategyBoardObjectiveList = ({ objectives }: Props) => {
  const { t } = useTranslation();

  const { isMetricsVisible, isInitiativesVisible, isActionsVisible } =
    useStrategyBoardViewSettings();

  return (
    <Flex direction={'column'} gap={8}>
      <Flex gap={8} css={css({ padding: '0 8px' })}>
        <Column>
          <Caption size={'C2'} color={'secondary'}>
            {capitalize(t('objective.objective_other'))}
          </Caption>
        </Column>
        {isMetricsVisible && (
          <Column>
            <Caption size={'C2'} color={'secondary'}>
              {capitalize(t('metric.metric_other'))}
            </Caption>
          </Column>
        )}
        {isInitiativesVisible && (
          <Column>
            <Caption size={'C2'} color={'secondary'}>
              {capitalize(t('initiative.initiative_other'))}
            </Caption>
          </Column>
        )}
        {isActionsVisible && (
          <Column>
            <Caption size={'C2'} color={'secondary'}>
              {capitalize(t('actions.action_other'))}
            </Caption>
          </Column>
        )}
      </Flex>

      {objectives.map((objective) => (
        <ObjectiveListItem key={objective.id}>
          <Column>
            <StrategyBoardItemCard
              item={objective}
              rowObjectiveId={objective.id}
              isExpanded={true}
            />
          </Column>
          {isMetricsVisible && (
            <Column>
              {objective.metrics.map((metric) => (
                <StrategyBoardItemCard
                  key={metric.id}
                  item={metric}
                  rowObjectiveId={objective.id}
                />
              ))}
            </Column>
          )}
          {isInitiativesVisible && (
            <Column>
              {objective.initiatives.map((initiative) => (
                <StrategyBoardItemCard
                  key={initiative.id}
                  item={initiative}
                  rowObjectiveId={objective.id}
                />
              ))}
            </Column>
          )}
          {isActionsVisible && (
            <Column>
              {objective.actions.map((action) => (
                <StrategyBoardItemCard
                  key={action.id}
                  item={action}
                  rowObjectiveId={objective.id}
                />
              ))}
            </Column>
          )}
        </ObjectiveListItem>
      ))}
    </Flex>
  );
};

export default StrategyBoardObjectiveList;
