import styled from '@emotion/styled';

import SophieAvatar from 'ai/SophieAvatar/SophieAvatar';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.color.backgroundLight};
  border-radius: 20px;
  padding: 20px;
`;

type Props = {
  content: string;
  header: string;
};

const SophieGuideBanner = ({ header, content }: Props) => {
  return (
    <Container gap={20} alignItems={'center'}>
      <SophieAvatar size={'big'} />
      <Flex direction={'column'} gap={8}>
        <Text size={'large'} isBold={true} color={'black'}>
          {header}
        </Text>
        <Text color={'black'}>{content}</Text>
      </Flex>
    </Container>
  );
};

export default SophieGuideBanner;
