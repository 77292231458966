import type { StrategyAssessmentType } from 'types.graphql.generated';

import type { AssessmentCategory } from './assessment.type';

const assessmentTypesByCategory: Record<
  AssessmentCategory,
  StrategyAssessmentType[]
> = {
  STRATEGY_ANALYSIS: [
    'PESTEL',
    'PORTERS_FIVE_FORCES',
    'CUSTOMER_SEGMENTATION',
    'STAKEHOLDER_ANALYSIS',
    'ANSOFF',
    'BLUE_OCEAN',
    'VRIO',
    'COMPETITIVE_STRATEGIES',
    'M_AND_A',
    'VALUE_CHAIN',
    'PLAYING_TO_WIN',
    'CUSTOMER_JOURNEY',
    'THREE_HORIZONS',
    'SWOT',
    'BSC_SWOT',
  ],
  STRESS_TEST: [
    'GE_PORTFOLIO_ANALYSIS',
    'BSC_STRATEGY_MAP',
    'BSC_STRATEGY_BOARD',
    'STRATEGY_DIAMOND',
    'BUSINESS_CANVAS',
    'MCKINSEY_7S',
  ],
  RISK: ['RISK_FRAMEWORKS', 'STRATEGIC_RISKS', 'OBJECTIVE_RISKS', 'RISK_MAP'],
  SUSTAINABILITY: [
    'DOUBLE_MATERIALITY',
    'UNSDG',
    'ESG',
    'OBJECTIVES',
    'FRAMEWORKS',
    'TAXONOMY',
    'CSRD',
    'TCFD',
    'SB_TI',
    'GRI',
  ],
  FUTURE: ['TWO_BY_TWO', 'FUTURE_OPPORTUNITIES'],
  FINANCIAL: [
    'FINANCIAL_HEALTH',
    'FINANCIAL_BENCHMARK',
    'REVENUE_ANALYSIS',
    'FINANCIAL_GROWTH',
    'FINANCIAL_RISK',
    'STRATEX',
  ],
};

export default assessmentTypesByCategory;
