import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Checkbox as AriaCheckbox } from 'react-aria-components';

import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';

const Content = styled.div`
  cursor: pointer;
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 0.188rem;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const StyledCheckbox = styled(AriaCheckbox)`
  &[data-focus-visible='true'] ${Content} {
    outline: none;
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary};
  }
`;

type Props = {
  disabled?: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
};

const Checkbox = ({ value, onChange, disabled }: Props) => {
  const theme = useTheme();

  const style = [
    value
      ? css`
          background-color: ${theme.color.black};

          svg {
            color: ${theme.color.white};
          }
        `
      : css`
          background-color: ${theme.color.white};

          border: 2px solid ${theme.legacyColor.colorSilver};

          svg {
            display: none;
          }
        `,
    disabled && css({ cursor: 'not-allowed', opacity: 0.5 }),
  ];

  return (
    <StyledCheckbox
      isSelected={value}
      onChange={onChange}
      isDisabled={disabled}
    >
      <Content css={style}>
        <CheckIcon />
      </Content>
    </StyledCheckbox>
  );
};

export default Checkbox;
