import { Trans, useTranslation } from 'react-i18next';
import { Field, FieldArray } from 'formik';
import type { ReactNode } from 'react';
import type { SetOptional } from 'type-fest';
import * as yup from 'yup';

import type { FormChildrenRenderProps, FormProps } from 'shared/form/Form';
import Form from 'shared/form/Form/Form';
import FieldBox from 'shared/form/FieldBox';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { TextInputField } from 'shared/components/TextInput';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import Anchor from 'shared/components/Anchor';
import Text from 'shared/components/Text';
import { TextAreaField } from 'shared/components/TextArea';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import styles from './StrategyItemListForm.module.scss';

export type StrategyItemListFormValues = {
  items: Array<{
    description: string;
    id?: string;
    name: string;
    owner: string;
    symbol: string;
  }>;
};

const createItemInitialValues = (
  owner: string,
): StrategyItemListFormValues['items'][0] => ({
  id: undefined,
  name: '',
  symbol: '',
  description: '',
  owner,
});

export type StrategyItemListFormProps = SetOptional<
  Pick<
    FormProps<StrategyItemListFormValues>,
    'className' | 'id' | 'initialValues' | 'onChange'
  >,
  'initialValues'
> & {
  isSymbolDisplayed?: boolean;
  nameLabel?: string;
  renderItemsHeading: () => ReactNode;
};

const StrategyItemListForm = ({
  id,
  className,
  renderItemsHeading,
  nameLabel,
  isSymbolDisplayed = false,
  initialValues: initialValuesProp, // = createInitialValues(),
  onChange,
}: StrategyItemListFormProps) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const validationSchema = yup.object({
    owner: yup.string().email().required(),
  });

  const defaultOwnerEmail =
    orgUnit?.orgUnitLead?.email || activeOrg.orgLead?.email || '';

  const initialValues = initialValuesProp || {
    items: [createItemInitialValues(defaultOwnerEmail)],
  };

  const renderItemFields = (params: {
    formikProps: FormChildrenRenderProps<StrategyItemListFormValues>;
    itemIndex: number;
  }) => {
    const {
      formikProps: { hasError },
      itemIndex,
    } = params;
    const getFieldName = (name: string) => `items.${itemIndex}.${name}`;
    return (
      <>
        <FieldBox
          name={getFieldName('name')}
          label={
            nameLabel || t('strategy.strategyItemListForm.item.name.label')
          }
        >
          <Field name={getFieldName('name')} component={TextInputField} />
        </FieldBox>
        <FieldBox
          name={getFieldName('description')}
          label={t('strategy.strategyItemListForm.item.description.label')}
        >
          <Field name={getFieldName('description')} component={TextAreaField} />
        </FieldBox>
        <FieldBox
          name={getFieldName('owner')}
          label={t('strategy.strategyItemListForm.item.owner.label')}
          hasError={hasError('owner')}
        >
          <Field
            name={getFieldName('owner')}
            hasError={hasError('owner')}
            component={UserEmailInputField}
          />
        </FieldBox>
        {isSymbolDisplayed && (
          <FieldBox
            name={getFieldName('symbol')}
            help={
              <Trans i18nKey={'strategy.strategyItemListForm.item.symbol.help'}>
                <Anchor
                  href={'https://emojipedia.org/'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                />
              </Trans>
            }
            label={t('strategy.strategyItemListForm.item.symbol.label')}
          >
            <Field
              name={getFieldName('symbol')}
              component={TextInputField}
              variant={'outlined'}
              size={'tiny'}
              maxLength={2}
            />
          </FieldBox>
        )}
      </>
    );
  };

  return (
    <UserEmailInputProvider>
      <Form
        initialValues={initialValues}
        id={id}
        className={className}
        onChange={onChange}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const { values } = formikProps;
          return (
            <>
              <FieldArray name={'items'}>
                {(arrayProps) => (
                  <>
                    {values.items.map((_, itemIndex) => (
                      <section className={styles.item} key={itemIndex}>
                        <header className={styles.itemHeader}>
                          {renderItemsHeading()}
                          <IconButton
                            icon={CloseIcon}
                            onClick={() => {
                              arrayProps.remove(itemIndex);
                            }}
                          >
                            {t('remove')}
                          </IconButton>
                        </header>
                        {renderItemFields({
                          formikProps,
                          itemIndex,
                        })}
                      </section>
                    ))}
                    {values.items.length === 0 ? (
                      <Text variant={'emphasis'}>
                        {t('strategy.strategyItemListForm.noItems')}
                      </Text>
                    ) : null}
                    <IconButton
                      variant={'outlined'}
                      icon={PlusIcon}
                      onClick={() => {
                        arrayProps.push(
                          createItemInitialValues(defaultOwnerEmail),
                        );
                      }}
                      className={styles.itemCreateButton}
                    >
                      {t('create')}
                    </IconButton>
                  </>
                )}
              </FieldArray>
            </>
          );
        }}
      </Form>
    </UserEmailInputProvider>
  );
};

export default StrategyItemListForm;
