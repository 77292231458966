import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import type { StrategyAssessmentInput } from 'types.graphql.generated';

import AssesmentWizardModal, { AssessmentForm } from '../AssessmentWizardModal';
import {
  useEditAssessmentQuery,
  useUpdateAssessmentMutation,
} from './EditAssessment.graphql.generated';

type Props = {
  assessmentId: string;
  isOpen: boolean;
  onClose: () => void;
};

const EditAssessment = ({ assessmentId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const { data } = useEditAssessmentQuery({
    variables: { assessmentId },
    onError,
    skip: !isOpen,
  });

  const [updateAssessment, { loading }] = useUpdateAssessmentMutation({
    onError,
    onCompleted: () => {
      addToast({
        variant: 'success',
        children: t('assessment.updateAssessment.success'),
      });
      onClose();
    },
  });

  const handleSubmit = useCallback(
    (input: StrategyAssessmentInput) =>
      updateAssessment({ variables: { input } }),
    [updateAssessment],
  );

  const initialValues = useMemo(
    () =>
      data
        ? {
            id: data.strategyAssessment.id,
            title: data.strategyAssessment.title || '',
            description: data.strategyAssessment.description || '',
            content: data.strategyAssessment.content,
            type: data.strategyAssessment.type,
          }
        : undefined,
    [data],
  );

  if (!initialValues) return null;

  const formId = `assessment-${initialValues.id}`;

  return (
    <AssessmentForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formId={formId}
    >
      <AssesmentWizardModal
        isOpen={isOpen}
        onClose={onClose}
        isSubmitting={loading}
        formId={formId}
      />
    </AssessmentForm>
  );
};

export default EditAssessment;
