import type {
  ComponentType,
  PropsWithChildren,
  ReactNode,
  SVGProps,
} from 'react';
import { Children, useEffect, useMemo } from 'react';
import { useToggle } from 'react-use';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import type { SerializedStyles } from '@emotion/react';

import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';

import styles from './CollapsibleList.module.scss';
import Caption from '../Caption';
import CollapsibleListTitle from './CollapsibleListTitle/CollapsibleListTitle';

export type CollapsibleListProps = PropsWithChildren<{
  childrenCount?: number;
  containerClassName?: string;
  direction?: 'column' | 'row';
  expanded?: boolean;
  hasHeader?: boolean;
  hasHeaderPadding?: boolean;
  headerClassName?: string;
  listClassName?: string;
  listStyle?: SerializedStyles;
  title: Maybe<string> | ReactNode;
  titleIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  type?: string;
}>;

const CollapsibleList = ({
  containerClassName,
  headerClassName,
  listClassName,
  listStyle,
  direction = 'column',
  hasHeader = true,
  hasHeaderPadding = true,
  titleIcon,
  title,
  type = 'item',
  expanded = true,
  childrenCount: childrenCountProp,
  children,
}: CollapsibleListProps) => {
  const { t } = useTranslation();

  const [isSectionExpanded, toggleIsSectionExpanded] = useToggle(expanded);

  const isTitleComponent = typeof title === 'object';

  const childrenCount = useMemo(() => {
    if (Number.isInteger(childrenCountProp)) {
      return childrenCountProp;
    }
    return Children.toArray(children).length;
  }, [childrenCountProp, children]);

  useEffect(() => {
    toggleIsSectionExpanded(expanded);
  }, [expanded, toggleIsSectionExpanded]);

  const itemsCount = t(`collapsibleList.itemsCount.${type}`, {
    itemsCount: childrenCount,
  });

  return (
    <section className={cn(styles.section, containerClassName)}>
      {hasHeader && (
        <header
          onClick={toggleIsSectionExpanded}
          className={cn(
            styles.header,
            {
              [styles.headerPadding]: hasHeaderPadding,
            },
            headerClassName,
          )}
        >
          {isTitleComponent ? (
            <>{title}</>
          ) : (
            <CollapsibleListTitle icon={titleIcon}>
              {title}
            </CollapsibleListTitle>
          )}
          <div className={styles.actionsButton}>
            <Caption size={'C2'} color={'secondary'}>
              {itemsCount}
            </Caption>
            <ChevronDownIcon
              className={cn(styles.actionsButtonIcon, {
                [styles.actionsButtonIconExpanded]: isSectionExpanded,
              })}
            />
          </div>
        </header>
      )}
      {isSectionExpanded && (
        <ul
          className={cn(
            styles.list,
            {
              [styles.listDirectionColumn]: direction === 'column',
              [styles.listDirectionRow]: direction === 'row',
            },
            listClassName,
          )}
          css={listStyle}
        >
          {children}
        </ul>
      )}
    </section>
  );
};

export default CollapsibleList;
