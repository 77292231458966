import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { fromPairs, groupBy, toPairs } from 'lodash';
import { addDays, endOfDay } from 'date-fns';

import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import { date } from 'shared/services/date.service';

import { useSendStatusRequestMutation } from './SendStatusRequest.graphql.generated';

export type StrategyElement = {
  __typename:
    | 'Objective'
    | 'ChildObjective'
    | 'Metric'
    | 'Initiative'
    | 'Action';
  id: string;
  owner?: { displayName?: string; email?: string; id: string };
};

const elementKeyByType = {
  Objective: 'objectiveIds',
  ChildObjective: 'objectiveIds',
  Metric: 'metricIds',
  Initiative: 'initiativeIds',
  Action: 'actionIds',
};

export default function useSendStatusRequest() {
  const { t } = useTranslation();
  const onError = useHandleError();
  const { addToast } = useToasts();

  const [
    triggerSendStatusRequestMutation,
    { loading: isSendingStatusRequest },
  ] = useSendStatusRequestMutation({
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('statusRequest.toast.success'),
      }),
    onError,
  });

  const sendStatusRequest = useCallback(
    (
      strategyElements: StrategyElement[],
      deadline: Maybe<Date>,
      requester: string,
    ) => {
      const serializedDeadline = date.formatRfc3339(
        endOfDay(deadline ?? addDays(new Date(), 7)),
      );

      const elements = fromPairs(
        toPairs(
          groupBy(
            strategyElements,
            (strategyElement) => elementKeyByType[strategyElement.__typename],
          ),
        ).map(([typeKey, elements]) => [
          typeKey,
          elements.map((element) => element.id),
        ]),
      );

      return triggerSendStatusRequestMutation({
        variables: {
          input: {
            deadline: serializedDeadline,
            elements,
            requestSubmitter: { emailToSet: requester },
          },
        },
      });
    },
    [triggerSendStatusRequestMutation],
  );

  return useMemo(
    () => ({
      isSendingStatusRequest,
      sendStatusRequest,
    }),
    [isSendingStatusRequest, sendStatusRequest],
  );
}
