import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from '@emotion/styled';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import useHandleError from 'shared/errors/useHandleError';
import type { Message, SummaryForEdit } from 'ai/AskSophieChat';
import AskSophieChat, { AskSophieProvider } from 'ai/AskSophieChat';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as EditOffIcon } from 'shared/static/icons/icon-edit-off.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import Spinner from 'shared/spinner/Spinner';
import type { StrategyAssessmentType } from 'types.graphql.generated';
import Heading from 'shared/components/Heading';
import Toggle from 'shared/components/Toggle';
import useDialogState from 'shared/hooks/useDialogState';
import Dialog from 'shared/components/Dialog';
import type { AssessmentCategory } from 'assessment/assessment.type';

import { useAssesmentWizardModalQuery } from './AssesmentWizardModal.graphql.generated';
import AssessmentFormFields from './AssessmentFormFields';
import type { AssessmentFormValues } from './AssessmentForm';
import mainPromptKeyByAssessmentType from './mainPromptKeyByAssessmentType';
import AssessmentInputMessageContent from './AssessmentInputMessageContent';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.color.strokeLight};
  padding: 20px;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-width: 1200px;
`;

const LeftContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: hidden;
  border-left: 1px solid ${(props) => props.theme.color.strokeLight};
`;

type Props = {
  formId: string;
  isSubmitting: boolean;
  modalOverlayElement: HTMLDivElement | null;
  setSelectedCategory: (category?: AssessmentCategory) => void;
  setSelectedType: (type?: StrategyAssessmentType) => void;
  type: StrategyAssessmentType;
  wasSelectionChanged: boolean;
};

const AssessmentChat = ({
  formId,
  type,
  isSubmitting,
  modalOverlayElement,
  setSelectedCategory,
  setSelectedType,
  wasSelectionChanged,
}: Props) => {
  const { t } = useTranslation();

  const { values, setValues, isValid } =
    useFormikContext<AssessmentFormValues>();

  const theme = useTheme();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const isPrestineEdit = !!values.id && !wasSelectionChanged;

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState(isPrestineEdit);

  const {
    isOpen: isApplySuggestionConfirmationOpen,
    onOpen: onApplySuggestionConfirmationOpen,
    onClose: onApplySuggestionConfirmationClose,
  } = useDialogState();

  const {
    isOpen: isCloseEditConfirmationOpen,
    onOpen: onCloseEditConfirmationOpen,
    onClose: onCloseEditConfirmationClose,
  } = useDialogState();

  const [generatedSummary, setGeneratedSummary] = useState<string>();

  const onError = useHandleError();

  const { data } = useAssesmentWizardModalQuery({
    variables: {
      filter: {
        orgUnitIds: orgUnit
          ? { operator: 'IN', value: [orgUnit.id] }
          : { operator: 'IS_NULL' },
      },
    },
    onError,
  });

  const strategyId = orgUnit
    ? orgUnit.currentStrategy?.id
    : activeOrg.currentStrategy?.id;

  const objectiveIds = data?.allObjectives.edges.map((edge) => edge.node.id);
  const metricIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.metrics.map((metric) => metric.id),
  );
  const initiativeIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.initiatives.map((initiative) => initiative.id),
  );
  const actionIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.actions.map((action) => action.id),
  );

  const title = t(`assessment.type.${type}.title`);
  const description = t(`assessment.type.${type}.description`);
  const header = `${title.toUpperCase()}: ${description}`;

  const generatedSummaryForEdit = useMemo<SummaryForEdit>(
    () => ({
      type,
      title,
      description,
      content: generatedSummary || '',
    }),
    [description, generatedSummary, title, type],
  );

  const setValuesFromGeneratedSummary = useCallback(
    () => setValues((values) => ({ ...values, ...generatedSummaryForEdit })),
    [generatedSummaryForEdit, setValues],
  );

  const initialMessages = useMemo<Message[]>(() => {
    return [
      {
        type: 'InputMessage',
        content: <AssessmentInputMessageContent type={type} />,
      },
      {
        type: 'SummaryMessage',
        skipQueryOnLoad: isPrestineEdit,
        promptMessage: {
          mainPromptKey: mainPromptKeyByAssessmentType[type],
          numberOfCharacters: 5000,
        },
        context: {
          objectiveIds,
          metricIds,
          initiativeIds,
          actionIds,
          strategyId,
        },
      },
    ];
  }, [
    actionIds,
    initiativeIds,
    isPrestineEdit,
    metricIds,
    objectiveIds,
    strategyId,
    type,
  ]);

  useEffect(() => {
    if (generatedSummary && !isEditOpen) {
      setValuesFromGeneratedSummary();
    }
  }, [generatedSummary, isEditOpen, setValuesFromGeneratedSummary]);

  const onClearMessages = useCallback(() => {
    setGeneratedSummary(undefined);
    setSelectedCategory(undefined);
    setSelectedType(undefined);
  }, [setSelectedCategory, setSelectedType]);

  if (!data) return <Spinner.Circle />;

  const isPublishDisabled =
    isSubmitting || (isEditOpen ? !isValid : !generatedSummary);

  const isEditToggleDisabled =
    isSubmitting || (!isEditOpen && !generatedSummary);

  const isContentChanged =
    values.title !== title || values.content !== generatedSummary;

  const isApplySuggestionDisabled =
    isSubmitting || !generatedSummary || !isContentChanged;

  return (
    <>
      <Flex direction={'column'} css={css({ height: '100%' })}>
        <HeaderContainer>
          <HeaderContent>
            <Heading level={4} hasMargin={false}>
              {header}
            </Heading>

            <Flex gap={8}>
              <Toggle
                firstChecked={!isEditOpen}
                firstIcon={EditOffIcon}
                handleToggle={
                  isEditOpen
                    ? isContentChanged
                      ? onCloseEditConfirmationOpen
                      : onEditClose
                    : onEditOpen
                }
                secondIcon={EditIcon}
                isDisabled={isEditToggleDisabled}
              />

              <Button
                variant={'contained'}
                type={'submit'}
                isDisabled={isPublishDisabled}
                form={formId}
              >
                {t('publish')}
              </Button>
            </Flex>
          </HeaderContent>
        </HeaderContainer>

        <Flex justifyContent={'center'} grow={1}>
          <LeftContainer>
            <AskSophieProvider
              strategyId={strategyId}
              objectiveIds={objectiveIds}
              metricIds={metricIds}
              initiativeIds={initiativeIds}
              actionIds={actionIds}
              initialMessages={initialMessages}
              onSummaryGenerated={setGeneratedSummary}
              regenerateButtonLabel={t('assessment.summary.regenerateButton')}
              onClearMessages={onClearMessages}
            >
              <AskSophieChat
                infoText={''}
                isOpen={true}
                promptMessages={[]}
                maxWidth={1200}
              />
            </AskSophieProvider>
          </LeftContainer>

          <RightContainer css={!isEditOpen && css({ display: 'none' })}>
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              css={css({
                padding: 20,
                borderBottom: `1px solid ${theme.color.strokeLight}`,
              })}
            >
              <Heading level={3} hasMargin={false}>
                {t('assessment.editMode.header')}
              </Heading>

              <Button
                variant={'outlined'}
                icon={RefreshIcon}
                iconPosition={'start'}
                height={'32px'}
                onPress={onApplySuggestionConfirmationOpen}
                isDisabled={isApplySuggestionDisabled}
              >
                {t('assessment.editMode.applyButton')}
              </Button>
            </Flex>

            <AssessmentFormFields overlayElement={modalOverlayElement} />
          </RightContainer>
        </Flex>
      </Flex>

      <Dialog
        isOpen={isApplySuggestionConfirmationOpen}
        onClose={onApplySuggestionConfirmationClose}
        onConfirm={setValuesFromGeneratedSummary}
        headerTitle={t('assessment.applySuggestionConfirmationDialog.header')}
        confirmLabel={t('assessment.applySuggestionConfirmationDialog.confirm')}
        cancelLabel={t('cancel')}
        size={'small'}
      >
        {t('assessment.applySuggestionConfirmationDialog.content')}
      </Dialog>

      <Dialog
        isOpen={isCloseEditConfirmationOpen}
        onClose={onCloseEditConfirmationClose}
        onConfirm={onEditClose}
        headerTitle={t('assessment.closeEditConfirmationDialog.header')}
        confirmLabel={t('assessment.closeEditConfirmationDialog.confirm')}
        cancelLabel={t('cancel')}
        size={'small'}
      >
        {t('assessment.closeEditConfirmationDialog.content')}
      </Dialog>
    </>
  );
};

export default AssessmentChat;
