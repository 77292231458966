import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import MetricsListProvider from 'metric/MetricsList/MetricsListProvider';
import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/filters/CalendarControls';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import ShowChildrenFilterButton from 'shared/filters/ShowChildrenFilterButton';
import ShowChildrenFilterProvider from 'shared/filters/ShowChildrenFilterButton/ShowChildrenFilterProvider';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import StatusFilter, {
  StatusFilterProvider,
} from 'shared/filters/StatusFilter';
import PriorityFilter, {
  PriorityFilterProvider,
} from 'shared/filters/PriorityFilter';
import UserFilter, { UserFilterProvider } from 'shared/filters/UserFilter';
import TimelineFilter, {
  TimelineFilterProvider,
} from 'shared/filters/TimelineFilter';
import ChipGroup from 'shared/filters/ChipGroup';
import FiltersButton from 'shared/filters/FiltersButton';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';
import ThemeFilter, { ThemeFilterProvider } from 'shared/filters/ThemeFilter';

import StrategyMetricsProvider from './StrategyMetricsProvider';
import { useStrategyMetrics } from './StrategyMetricsProvider/useStrategyMetrics';
import MetricsListHeader from '../MetricsList/MetricsListHeader';
import styles from './StrategyMetrics.module.scss';
import MetricsListThemes from './MetricsListThemes/MetricsListThemes';

const StrategyMetrics = () => {
  const { t } = useTranslation();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  const { objectives, isStrategyMetricsLoading } = useStrategyMetrics();

  return (
    <MetricsListProvider objectives={objectives}>
      <Layout.Content
        hasPadding={false}
        className={styles.layout}
        variant={'contained'}
      >
        <MetricsListHeader
          afterStats={<ChipGroup />}
          controls={<CalendarControls />}
          filters={
            <>
              <CompletedFilterButton />

              <ShowChildrenFilterButton
                activeTooltip={t(
                  'filters.showChildrenFilter.tooltip.hide.metric',
                )}
                inactiveTooltip={t(
                  'filters.showChildrenFilter.tooltip.show.metric',
                )}
              />

              <FiltersButton>
                <ThemeFilter />
                <StatusFilter />
                <PriorityFilter />
                <UserFilter />
                <TimelineFilter />
                <CollaboratingTeamsFilter />
              </FiltersButton>

              <ToggleExpandIconButton
                onToggle={toggleAreAllSectionsExpanded}
                isExpanded={areAllSectionsExpanded}
              />
            </>
          }
        />

        {isStrategyMetricsLoading ? (
          <Spinner.Circle />
        ) : (
          <MetricsListThemes areAllSectionsExpanded={areAllSectionsExpanded} />
        )}
      </Layout.Content>
    </MetricsListProvider>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ShowChildrenFilterProvider storeToSession={true}>
        <ChipGroupProvider>
          <CollaboratingTeamsFilterProvider storeToSession={true}>
            <ThemeFilterProvider storeToSession={true}>
              <StatusFilterProvider storeToSession={true}>
                <PriorityFilterProvider storeToSession={true}>
                  <UserFilterProvider storeToSession={true}>
                    <TimelineFilterProvider storeToSession={true}>
                      <StrategyMetricsProvider>
                        <StrategyMetrics />
                      </StrategyMetricsProvider>
                    </TimelineFilterProvider>
                  </UserFilterProvider>
                </PriorityFilterProvider>
              </StatusFilterProvider>
            </ThemeFilterProvider>
          </CollaboratingTeamsFilterProvider>
        </ChipGroupProvider>
      </ShowChildrenFilterProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
