import { ReactComponent as ActionIcon } from 'shared/static/icons/icon-check-square.svg';
import { date } from 'shared/services/date.service';
import type {
  Action,
  ActionStatus,
  AuditRecord,
  TimeLine,
} from 'types.graphql.generated';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';
import {
  testPriorityFilters,
  type PriorityFilterValue,
} from 'shared/filters/PriorityFilter';
import {
  testActionStatusFilters,
  type ActionStatusFilterValue,
} from 'shared/filters/ActionStatusFilter';

import type { ActionsSort } from './actions.type';
import type { ActionPartsFragment } from './actions.fragment.graphql.generated';

export const getActionIcon = () => ActionIcon;

export const getActionsContext = (pathname: string) =>
  pathname.includes('objectives') ? 'objective' : 'strategy';

export const determineIfIsOverdue = <
  TAction extends Pick<Action, 'timeLine'> & {
    currentStatus?: Pick<ActionStatus, 'complete'>;
  },
>(
  action: Maybe<TAction>,
): boolean =>
  !!action?.timeLine.endDate &&
  !action.currentStatus?.complete &&
  date.isAfter(new Date(), date.add(action.timeLine.endDate, { days: 1 }));

export const filterActionsByUser = (
  actions: Maybe<ActionPartsFragment[]>,
  userIds: string[],
) => {
  if (!userIds.length) {
    return actions;
  }

  return actions?.filter((action) =>
    userIds.some((userId) => userId === action.owner?.id),
  );
};

export const filterActionsByTheme = (
  actions: Maybe<ActionPartsFragment[]>,
  themeIds: string[],
) => {
  if (!themeIds.length) {
    return actions;
  }

  return actions?.filter((action) =>
    themeIds.some((themeId) => themeId === action.objective?.theme?.id),
  );
};

export const filterActionsByPriority = (
  actions: Maybe<ActionPartsFragment[]>,
  priorities: PriorityFilterValue[],
) =>
  actions?.filter((action) => testPriorityFilters(priorities, action.priority));

export const getActiveActions = <
  TAction extends {
    currentStatus?: Pick<ActionStatus, 'complete' | 'completionRate'>;
  },
>(
  actions: Maybe<TAction[]>,
) =>
  (actions || []).filter(
    (action) =>
      !action.currentStatus?.complete &&
      action.currentStatus?.completionRate &&
      action.currentStatus?.completionRate > 0,
  );

export const getInactiveActions = (actions: Maybe<ActionPartsFragment[]>) =>
  (actions || []).filter(
    (action) =>
      !action.currentStatus?.completionRate ||
      action.currentStatus?.completionRate === 0,
  );

export const getDoneActions = <
  TAction extends { currentStatus?: Pick<ActionStatus, 'complete'> },
>(
  actions: Maybe<TAction[]>,
) => actions?.filter((action) => action.currentStatus?.complete);

export const getActionsDoneCount = <
  TAction extends { currentStatus?: Pick<ActionStatus, 'complete'> },
>(
  actions: Maybe<TAction[]>,
) => {
  if (actions) {
    return actions.reduce<number>(
      (acc, cur) => (cur.currentStatus?.complete ? acc + 1 : acc),
      0,
    );
  } else {
    return 0;
  }
};

export const getOverdueActions = (actions: Maybe<ActionPartsFragment[]>) =>
  actions?.filter(
    (action) =>
      action.timeLine.endDate &&
      date.isAfter(new Date(), date.add(action.timeLine.endDate, { days: 1 })),
  );

export const sortActionsByDeadlineDesc = (actions: ActionPartsFragment[]) =>
  actions.sort((actionA, actionB) =>
    actionA.timeLine.endDate &&
    actionB.timeLine.endDate &&
    date.isAfter(actionA.timeLine.endDate, actionB.timeLine.endDate)
      ? -1
      : 1,
  );

export const sortActionsByDeadlineAsc = (actions: ActionPartsFragment[]) =>
  actions.sort((actionA, actionB) =>
    actionA.timeLine.endDate &&
    actionB.timeLine.endDate &&
    date.isAfter(actionA.timeLine.endDate, actionB.timeLine.endDate)
      ? 1
      : -1,
  );

export const sortActionsByCompletionDesc = (actions: ActionPartsFragment[]) =>
  actions.sort((actionA, actionB) => {
    if (
      actionA.currentStatus?.completionRate ===
      actionB.currentStatus?.completionRate
    ) {
      return 0;
    }

    return (actionA.currentStatus?.completionRate || 0) >
      (actionB.currentStatus?.completionRate || 0)
      ? 1
      : -1;
  });

export const sortActionsByCompletionAsc = (actions: ActionPartsFragment[]) =>
  actions.sort((actionA, actionB) => {
    if (
      actionA.currentStatus?.completionRate ===
      actionB.currentStatus?.completionRate
    ) {
      return 0;
    }

    return (actionA.currentStatus?.completionRate || 0) >
      (actionB.currentStatus?.completionRate || 0)
      ? -1
      : 1;
  });

export const sortActions = (
  actions: Maybe<ActionPartsFragment[]>,
  sort: ActionsSort,
) => {
  if (actions) {
    switch (sort) {
      case 'deadlineDesc':
        return sortActionsByDeadlineDesc(actions);
      case 'deadlineAsc':
        return sortActionsByDeadlineAsc(actions);
      case 'completionDesc':
        return sortActionsByCompletionDesc(actions);
      case 'completionAsc':
        return sortActionsByCompletionAsc(actions);
    }
  }
};

export const filterActionsByDates = <
  Action extends {
    auditRecord: Pick<AuditRecord, 'createDateTime'>;
    timeLine: Pick<TimeLine, 'startDate'>;
  },
>(
  actions: Maybe<Action[]>,
  start: Maybe<Date>,
  end: Maybe<Date>,
  check?: CalendarCheck,
) => {
  if (!start && !end) return actions;

  return actions?.filter((action) =>
    testStrategyItemAgainstInterval(action, { start, end }, check),
  );
};

export const filterActionsByStatus = (
  actions: Maybe<ActionPartsFragment[]>,
  statusFilter: ActionStatusFilterValue[],
) =>
  actions?.filter((action) =>
    testActionStatusFilters(statusFilter, action.currentStatus),
  );

export const filterActionsByCompleted = (
  actions: Maybe<ActionPartsFragment[]>,
  showCompleted: boolean,
) =>
  actions?.filter((action) => showCompleted || !action.currentStatus?.complete);

export const filterActionsByCollaboratingTeams = (
  actions: Maybe<ActionPartsFragment[]>,
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  actions?.filter(
    (action) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      action.orgUnit?.id === currentOrgUnitId,
  );
