import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import type { OrgInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';
import Dialog from 'shared/components/Dialog';

import { useUpdateOrgMutation } from './UpdateOrg.graphql.generated';
import type { OrgFormValues } from './OrgForm';
import OrgForm, { orgFormId } from './OrgForm';
import { useGetOrgForEditQuery } from './GetOrgForEdit.graphql.generated';
import OrgQuickFormFields from './OrgQuickFormFields';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const EditOrg = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const onError = useHandleError();

  const { data } = useGetOrgForEditQuery({
    onError,
    skip: !isOpen,
  });

  const [updateOrg, { loading }] = useUpdateOrgMutation({
    onError,
  });

  const org = data?.activeOrg;

  const initialValues: OrgFormValues | false = useMemo(
    () =>
      !!org && {
        name: org.displayName || '',
        acronym: org.displayNameAbbreviated || '',
        color: org.backgroundColorAsHex || '',
        lead: org.orgLead?.email || '',
        industryName: org.industry?.name || '',
        industryDescription: org.industry?.description || '',
        doAutoCreateUserAccounts:
          org.systemPreferences.doAutoCreateUserAccounts,
        doSendEmails: org.systemPreferences.doSendEmails,
        scimUserProvisioningEnabled:
          org.systemPreferences.scimUserProvisioningEnabled,
        showLinksInEmailsEnabled:
          org.systemPreferences.showLinksInEmailsEnabled,
        userHighlightsEmailEnabled:
          org.systemPreferences.userHighlightsEmailEnabled,
      },
    [org],
  );

  const handleSubmit = useCallback(
    async (input: OrgInput) => {
      const result = await updateOrg({ variables: { input } });

      const isSuccess = result.data?.updateOrg.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('org.edit.toast.success'),
        });

        onClose();
      }
    },
    [addToast, onClose, t, updateOrg],
  );

  if (!initialValues || !org) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('org.edit.title')}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      formId={orgFormId}
      isConfirmDisabled={loading}
    >
      <OrgForm initialValues={initialValues} onSubmit={handleSubmit}>
        <OrgQuickFormFields org={org} />
      </OrgForm>
    </Dialog>
  );
};

export default EditOrg;
