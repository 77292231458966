import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { Strategy } from 'types.graphql.generated';
import { TeamAdapter } from 'team/TeamAdapter';
import Navbar from 'shared/components/Navbar';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import UserAvatarContextMenu from 'user/UserAvatarContextMenu';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import useUserPrimaryTeamAdapter from 'user/useUserPrimaryTeamAdapter';
import Flex from 'shared/components/Flex';
import SelectUserTenantInput from 'user/SelectUserTenantInput';

import { getNavigationLinks } from './UserNavbar.utils';

const UserNavbar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const userPrimaryTeamAdapter = useUserPrimaryTeamAdapter();

  const teamAdapter = useMemo<TeamAdapter>(() => {
    if (orgUnit) {
      return TeamAdapter.fromOrgUnit(orgUnit, activeOrg.orgKey);
    }
    return TeamAdapter.fromOrg(activeOrg);
  }, [activeOrg, orgUnit]);

  const strategyId = useMemo<Maybe<Strategy['id']>>(() => {
    if (teamAdapter.isOrgUnit && orgUnit?.currentStrategy) {
      return orgUnit.currentStrategy.id;
    }
    if (teamAdapter.isOrg && activeOrg.currentStrategy) {
      return activeOrg.currentStrategy.id;
    }
    return undefined;
  }, [teamAdapter, activeOrg, orgUnit]);

  const navigationLinks = useMemo(
    () =>
      getNavigationLinks({
        t,
        pathname,
        teamSlug: teamAdapter.toParam(),
        primaryTeamSlug: userPrimaryTeamAdapter.toParam(),
        orgTeamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
        strategyId,
        org: activeOrg,
      }),
    [activeOrg, pathname, strategyId, t, teamAdapter, userPrimaryTeamAdapter],
  );

  return (
    <Navbar navigationLinks={navigationLinks}>
      <Flex justifyContent={'flex-end'} alignItems={'center'} gap={14} grow={1}>
        <UserAvatarContextMenu />
        <SelectUserTenantInput />
      </Flex>
    </Navbar>
  );
};

export default UserNavbar;
