import { createContext } from 'react';

export type StrategyBoardViewSettingsValue = {
  isActionProgressVisible: boolean;
  isActionsVisible: boolean;
  isInitiativeMilestonesVisible: boolean;
  isInitiativesVisible: boolean;
  isMetricScoreVisible: boolean;
  isMetricsVisible: boolean;
  isOrgUnitVisible: boolean;
  isUserVisible: boolean;
  toggleActionProgressVisible: () => void;
  toggleActionsVisible: () => void;
  toggleInitiativeMilestonesVisible: () => void;
  toggleInitiativesVisible: () => void;
  toggleMetricScoreVisible: () => void;
  toggleMetricsVisible: () => void;
  toggleOrgUnitVisible: () => void;
  toggleUserVisible: () => void;
};

export const StrategyBoardViewSettingsContext = createContext<
  StrategyBoardViewSettingsValue | undefined
>(undefined);
