import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import ObjectiveLink from 'objective/ObjectiveLink';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import useHandleError from 'shared/errors/useHandleError';
import useDialogState from 'shared/hooks/useDialogState';
import SendStatusRequestsDialog from 'status/SendStatusRequestsDialog';
import Flex from 'shared/components/Flex';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import Dialog from 'shared/components/Dialog';
import Button from 'shared/components/Button';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useDeleteActionMutation } from 'actions/ActionContextMenu/DeleteAction.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import ContextMenu from 'shared/components/ContextMenu';

import styles from './ActionStatusModal.module.scss';
import type { ActionStatusModalActionFragment } from './ActionStatusModal.graphql.generated';
import ActionStatusFormFields from './ActionStatusFormFields';
import type { ActionStatusFormValues } from './ActionStatusForm';

type Props = {
  action: ActionStatusModalActionFragment;
  formId: string;
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
};

const ActionStatusDialog = ({
  action,
  formId,
  isOpen,
  onClose,
  onEdit,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const {
    isOpen: isSendStatusRequestOpen,
    onOpen: onSendStatusRequestOpen,
    onClose: onSendStatusRequestClose,
  } = useDialogState();

  const [deleteAction] = useDeleteActionMutation({
    variables: { actionId: action.id },
    onError,
    update(cache) {
      setTimeout(() => cache.evict({ id: `Action:${action.id}` }), 1000);
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('actions.toasts.deleteActionSuccess'),
      }),
  });

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const canAddStatus = canPerformStrategyElementAction(action, 'WRITE');
  const canRequestStatus = !action.isCurrentUserOwner;

  const handleRemoveAction = useCallback(
    () => deleteAction().then(onClose),
    [deleteAction, onClose],
  );

  const menuItems = useMemo(
    () =>
      [
        canRequestStatus && {
          id: 'requestStatus',
          title: t('statusRequest.action'),
          icon: SendIcon,
        },
        {
          id: 'remove',
          title: t('remove'),
          icon: TrashIcon,
        },
      ].filter(Boolean),
    [canRequestStatus, t],
  );

  const onMenuAction = useCallback(
    (id: string) => {
      switch (id) {
        case 'requestStatus':
          onSendStatusRequestOpen();
          break;
        case 'remove':
          handleRemoveAction();
          break;
      }
    },
    [handleRemoveAction, onSendStatusRequestOpen],
  );

  const { values, dirty } = useFormikContext<ActionStatusFormValues>();

  return (
    <>
      <Dialog
        isOpen={isOpen && !isSendStatusRequestOpen}
        onClose={onClose}
        headerTitle={action?.name}
        formId={formId}
        isConfirmDisabled={!dirty}
        confirmIcon={
          canAddStatus && values.completionRate === 100
            ? CheckmarkDoneIcon
            : undefined
        }
        confirmLabel={
          canAddStatus
            ? values.completionRate === 100
              ? t('actions.buttons.completeActionStatus')
              : t('actions.buttons.updateActionStatus')
            : undefined
        }
        cancelLabel={t('cancel')}
        footerPreContent={
          canAddStatus && (
            <Flex gap={8}>
              <Button variant={'outlined'} onPress={onEdit}>
                {t('edit')}
              </Button>
              <ContextMenu
                items={menuItems}
                onAction={onMenuAction}
                testId={'action-status-form-menu'}
              />
            </Flex>
          )
        }
      >
        <Flex direction={'column'} gap={20}>
          {action.description && action.description !== '' && (
            <p className={styles.description}>{action.description}</p>
          )}
          {action.objective && (
            <ObjectiveLink objective={action.objective} isLink={false} />
          )}
          <div className={styles.metadata}>
            <div className={styles.timeline}>
              <DateIcon className={styles.timelineIcon} />
              <div
                className={cn(styles.timelineText, {
                  [styles.overdue]: isActionOverdue,
                })}
              >
                {action.timeLine.endDate &&
                  date.format(action.timeLine.endDate, 'dd MMM')}
              </div>
              {isActionOverdue && <ActionChipOverdue />}
              <CompletionRate
                complete={action.currentStatus?.complete}
                completionRate={action.currentStatus?.completionRate}
              />
            </div>
            <Flex gap={8}>
              <TeamAvatar orgUnit={action.orgUnit} size={'small'} />
              <UserAvatar
                user={action.owner}
                hasLabel={true}
                containerClassName={styles.user}
                showTooltip={false}
              />
            </Flex>
          </div>
        </Flex>

        <ActionStatusFormFields action={action} />
      </Dialog>

      <SendStatusRequestsDialog
        isOpen={isSendStatusRequestOpen}
        onClose={onSendStatusRequestClose}
        strategyElements={[action]}
      />
    </>
  );
};

export default ActionStatusDialog;
