import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useMemo } from 'react';

import Heading from 'shared/components/Heading';
import Layout from 'shared/components/Layout';
import useHandleError from 'shared/errors/useHandleError';
import { useObjective } from 'objective/ObjectiveProvider';
import Spinner from 'shared/spinner/Spinner';
import Flex from 'shared/components/Flex';
import InfoIcon from 'shared/components/InfoIcon';
import { StrategyBoardViewSettingsStaticProvider } from 'strategy/StrategyBoard/StrategyBoardViewSettings';
import StrategyBoardObjectiveList from 'strategy/StrategyBoard/StrategyBoardObjectiveList';

import { useObjectiveAlignmentQuery } from './ObjectiveAlignment.graphql.generated';

const ObjectiveAlignment = () => {
  const { t } = useTranslation();

  const { objective } = useObjective();

  const onError = useHandleError();

  const { data } = useObjectiveAlignmentQuery({
    onError,
    variables: { objectiveId: objective.id },
  });

  const objectives = useMemo(
    () =>
      data
        ? [data.objective, ...data.objective.childObjectives].map(
            (objective) => ({
              ...objective,
              isFiltersMatch: true,
            }),
          )
        : undefined,
    [data],
  );

  if (!objectives) return <Spinner.Circle />;

  return (
    <StrategyBoardViewSettingsStaticProvider>
      <Layout.Content variant={'contained'}>
        <Flex direction={'column'} gap={'2rem'}>
          <Flex justifyContent={'space-between'} gap={8}>
            <Flex alignItems={'center'} css={css({ height: 42 })} gap={8}>
              <Heading level={3} hasMargin={false}>
                {t('objective.alignment.heading')}
              </Heading>

              <InfoIcon
                content={t('objective.alignment.help')}
                isInline={true}
              />
            </Flex>
          </Flex>

          <StrategyBoardObjectiveList objectives={objectives} />
        </Flex>
      </Layout.Content>
    </StrategyBoardViewSettingsStaticProvider>
  );
};

export default ObjectiveAlignment;
