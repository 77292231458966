import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from '@emotion/styled';

import Modal from 'shared/components/Modal';
import type { AssessmentCategory } from 'assessment/assessment.type';
import type { StrategyAssessmentType } from 'types.graphql.generated';
import assessmentTypesByCategory from 'assessment/assessmentTypesByCategory';

import AssessmentChat from './AssessmentChat';
import AssessmentCategorySelection from './AssessmentCategorySelection';
import AssessmentTypeSelection from './AssessmentTypeSelection';
import type { AssessmentFormValues } from './AssessmentForm';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  max-width: 1200px;
  flex-grow: 1;
`;

type Props = {
  formId: string;
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
};

const AssesmentWizardModal = ({
  isOpen,
  onClose,
  isSubmitting,
  formId,
}: Props) => {
  const { t } = useTranslation();

  const { values, resetForm } = useFormikContext<AssessmentFormValues>();

  const [modalOverlayElement, setModalOverlayElement] =
    useState<HTMLDivElement | null>(null);

  const [selectedCategory, setSelectedCategory] =
    useState<AssessmentCategory>();

  const [selectedType, setSelectedType] = useState<StrategyAssessmentType>();

  const [wasSelectionChanged, setWasSelectionChanged] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const type = values.type;

      if (type) {
        const category = Object.keys(assessmentTypesByCategory).find(
          (category) =>
            assessmentTypesByCategory[category as AssessmentCategory].includes(
              type,
            ),
        ) as AssessmentCategory;

        setSelectedCategory(category);
        setSelectedType(type);
        setWasSelectionChanged(false);
      }
    }

    // Do not re-trigger when values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSelectedCategory(undefined);
        setSelectedType(undefined);
        resetForm();
      }, 500);
    }
  }, [isOpen, resetForm]);

  const handleSetSelectedCategory = useCallback(
    (category?: AssessmentCategory) => {
      setSelectedCategory(category);
      setWasSelectionChanged(true);
    },
    [],
  );

  const handleSetSelectedType = useCallback((type?: StrategyAssessmentType) => {
    setSelectedType(type);
    setWasSelectionChanged(true);
  }, []);

  return (
    <Modal
      headerTitle={t('assessment.createAssessment.title')}
      isOpen={isOpen}
      onClose={onClose}
      headerSize={'large'}
      contentSize={'full'}
      overlayRef={setModalOverlayElement}
      hasPadding={false}
    >
      {!selectedCategory ? (
        <Container>
          <Content>
            <AssessmentCategorySelection
              setSelectedCategory={handleSetSelectedCategory}
            />
          </Content>
        </Container>
      ) : !selectedType ? (
        <Container>
          <Content>
            <AssessmentTypeSelection
              category={selectedCategory}
              setSelectedCategory={handleSetSelectedCategory}
              setSelectedType={handleSetSelectedType}
            />
          </Content>
        </Container>
      ) : (
        <AssessmentChat
          formId={formId}
          type={selectedType}
          isSubmitting={isSubmitting}
          modalOverlayElement={modalOverlayElement}
          setSelectedCategory={handleSetSelectedCategory}
          setSelectedType={handleSetSelectedType}
          wasSelectionChanged={wasSelectionChanged}
        />
      )}
    </Modal>
  );
};

export default AssesmentWizardModal;
