import type { StrategyAssessmentType } from 'types.graphql.generated';

import bannerImageByAssessmentType from './bannerImageByAssessmentType';

export type AssessmentBannerImageProps = {
  type: StrategyAssessmentType;
};

const AssessmentBannerImage = ({ type }: AssessmentBannerImageProps) => {
  const Image = bannerImageByAssessmentType[type];

  return <Image />;
};

export default AssessmentBannerImage;
