import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserProfileFragmentDoc } from '../../userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserEmailInputQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserEmailInputQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, employees: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean } }> } } };


export const UserEmailInputDocument = gql`
    query UserEmailInput {
  activeOrg {
    id
    employees(filter: {isActive: true}) {
      edges {
        node {
          ...userProfile
        }
      }
    }
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useUserEmailInputQuery__
 *
 * To run a query within a React component, call `useUserEmailInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailInputQuery(baseOptions?: Apollo.QueryHookOptions<UserEmailInputQuery, UserEmailInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEmailInputQuery, UserEmailInputQueryVariables>(UserEmailInputDocument, options);
      }
export function useUserEmailInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEmailInputQuery, UserEmailInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEmailInputQuery, UserEmailInputQueryVariables>(UserEmailInputDocument, options);
        }
export function useUserEmailInputSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserEmailInputQuery, UserEmailInputQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEmailInputQuery, UserEmailInputQueryVariables>(UserEmailInputDocument, options);
        }
export type UserEmailInputQueryHookResult = ReturnType<typeof useUserEmailInputQuery>;
export type UserEmailInputLazyQueryHookResult = ReturnType<typeof useUserEmailInputLazyQuery>;
export type UserEmailInputSuspenseQueryHookResult = ReturnType<typeof useUserEmailInputSuspenseQuery>;
export type UserEmailInputQueryResult = Apollo.QueryResult<UserEmailInputQuery, UserEmailInputQueryVariables>;