import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Key } from 'react-aria-components';

import { useThemeFilter } from './useThemeFilter';
import FilterMultiSelect from '../FilterMultiSelect';

export type ThemeFilterSelectItem = { id: string; title: string };

const ThemeFilter = () => {
  const { t } = useTranslation();

  const {
    availableThemes,
    selectedThemeIds: themeIds,
    setSelectedThemes: setThemes,
  } = useThemeFilter();

  const items = useMemo(
    () =>
      availableThemes.map((theme) => ({
        id: theme.id,
        title: theme.name || theme.id,
      })),
    [availableThemes],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Key[]) =>
      setThemes(Array.from([...selectedKeys]) as string[]),
    [setThemes],
  );

  const selectionLabel =
    themeIds.length === 0
      ? t('theme.allThemes')
      : `${themeIds.length} ${t('theme.theme', { count: themeIds.length })}`;

  return (
    <FilterMultiSelect
      items={items}
      selectedKeys={themeIds}
      onSelectionChange={onSelectionChange}
      fieldLabel={t('theme.themeMultiSelect.label')}
      selectionLabel={selectionLabel}
    />
  );
};

export default ThemeFilter;
