import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Flex from 'shared/components/Flex';
import DatePicker from 'shared/components/DatePicker';

import { useTimelineFilter } from './useTimelineFilter';

const TimelineFilter = () => {
  const { t } = useTranslation();

  const { startDate, setStartDate, endDate, setEndDate } = useTimelineFilter();

  return (
    <Flex gap={'1rem'} css={css({ padding: '10px 0' })}>
      <DatePicker
        value={startDate}
        onChange={setStartDate}
        labelPrefix={t('from')}
        placeholder={t('allDates')}
        maxDate={endDate}
        css={css({ flexBasis: 0 })}
        grow={true}
      />
      <DatePicker
        value={endDate}
        onChange={setEndDate}
        labelPrefix={t('to')}
        placeholder={t('allDates')}
        minDate={startDate}
        css={css({ flexBasis: 0 })}
        grow={true}
      />
    </Flex>
  );
};

export default TimelineFilter;
