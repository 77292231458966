import type { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useAuth } from 'auth/AuthProvider';
import { authRoutesPaths } from 'auth/auth.routing.paths';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';
import { useRedirect } from 'shared/providers/RedirectProvider/useRedirect';

type AuthGuardProps = PropsWithChildren<{
  forGuestOnly?: boolean;
  forLoggedInOnly?: boolean;
}>;

const AuthGuard = ({
  forLoggedInOnly,
  forGuestOnly,
  children,
}: AuthGuardProps) => {
  const location = useLocation();
  const { user, isLoading } = useAuth();
  const { isValidated } = useUserValidation();
  const { setRedirectPath } = useRedirect();

  useEffect(() => {
    if (!isLoading && forLoggedInOnly && !user) {
      setRedirectPath(location.pathname);
    }
  }, [forLoggedInOnly, isLoading, location.pathname, setRedirectPath, user]);

  const isValidatingUser = user && !isValidated;
  const isShowingSpinner = isLoading || isValidatingUser;

  if (isShowingSpinner) {
    return <GlobalSpinner />;
  }

  if (forGuestOnly && user) {
    return <Navigate to={'/'} />;
  }

  if (forLoggedInOnly && !user) {
    return <Navigate to={authRoutesPaths.signUp()} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
