import { ApolloProvider } from '@apollo/client';
import { useMemo, type PropsWithChildren } from 'react';

import { createApolloClient } from 'shared/config/createApolloClient';
import { useAuth } from 'auth/AuthProvider';
import { useActingOrgKey } from 'org/ActingOrgKeyProvider/useActingOrgKey';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';

const GraphqlProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth();
  const { isValidated } = useUserValidation();
  const { actingOrgKey } = useActingOrgKey();

  const client = useMemo(
    () =>
      createApolloClient(
        isValidated
          ? {
              firebaseUser: user || undefined,
              actingOrgKey,
            }
          : {},
      ),
    [actingOrgKey, isValidated, user],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
