import { css, useTheme } from '@emotion/react';

import srcTiny from 'shared/static/ai/sophie-tiny.png';
import srcSmall from 'shared/static/ai/sophie-small.png';
import srcSquare from 'shared/static/ai/sophie-square.png';
import srcBig from 'shared/static/ai/sophie-big.png';
import srcHuge from 'shared/static/ai/sophie-huge.png';

const borderRadiusBySize = {
  tiny: '50%',
  small: '50%',
  square: 0,
  big: '50%',
  huge: '50%',
};

const srcBySize = {
  tiny: srcTiny,
  small: srcSmall,
  square: srcSquare,
  big: srcBig,
  huge: srcHuge,
};

const sizes = {
  tiny: 24,
  small: 40,
  square: 48,
  big: 100,
  huge: 240,
};

type Props = {
  size: keyof typeof sizes;
  width?: number;
};

const SophieAvatar = ({ size, width }: Props) => {
  const theme = useTheme();

  return (
    <img
      src={srcBySize[size]}
      css={css({
        width: width ?? sizes[size],
        border:
          size === 'square' ? 'none' : `1px solid ${theme.color.strokeLight}`,
        borderRadius: borderRadiusBySize[size],
      })}
    />
  );
};

export default SophieAvatar;
