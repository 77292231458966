import { Fragment } from 'react';
import type { TFunction } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import type { Theme } from '@emotion/react';

import type { ContributionSubject } from 'contribution/contribution.type';
import { TeamAdapter } from 'team/TeamAdapter';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { enumerate } from 'shared/utils/string.utils';
import {
  getActions,
  getInitiatives,
  getMetrics,
  getObjectives,
} from 'contribution/contribution.utils';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import LockedLink from 'shared/components/LockedLink';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';

import type { ContributionLineOfSightSectionUserFragment } from './ContributionLineOfSightSection.graphql.generated';

const Link = styled(NavLink)`
  color: ${(props) => props.theme.color.primary};
`;

export const getObjectivesSentence = (
  t: TFunction,
  theme: Theme,
  subject: ContributionSubject,
  user: ContributionLineOfSightSectionUserFragment,
) => {
  const orgObjectives = user.contributingTo.linkedTopLevelObjectives;

  if (orgObjectives.length === 0) {
    return null;
  }

  const userName = getUserName(t, user.displayName);

  return [
    `${t(`contribution.lineOfSight.objective.objectivePrefix.${subject}`, {
      user: userName,
    })}`,
    ' ',
    `${t(`objective.objective_specific`, {
      count: orgObjectives.length,
    }).toLocaleLowerCase()}`,
    ' ',
    ...enumerate(
      t,
      orgObjectives.map((objective) => (
        <LockedLink
          key={objective.id}
          isActive={canPerformStrategyElementAction(objective, 'READ')}
          isInline={true}
          color={theme.color.primary}
          inactiveColor={theme.color.black}
          decoration={true}
          to={
            user.org
              ? objectiveRoutesPaths.root({
                  params: {
                    objectiveId: objective.id,
                    teamSlug: TeamAdapter.fromOrg(user.org).toParam(),
                  },
                })
              : ''
          }
        >
          {objective.name}
        </LockedLink>
      )),
    ),
    ' ',
    t('contribution.lineOfSight.objective.orgPrefix'),
    ' ',
    user.org ? (
      <Link
        key={'org'}
        to={strategyRoutesPaths.overview.root({
          params: { teamSlug: TeamAdapter.fromOrg(user.org).toParam() },
        })}
      >
        {user.org?.displayName || t('contribution.lineOfSight.objective.org')}
      </Link>
    ) : (
      t('contribution.lineOfSight.objective.org')
    ),
    ' ',
    t('contribution.lineOfSight.objective.strategyPrefix'),
    user.org?.currentStrategy?.name && (
      <Fragment key={'strategy'}>
        {' '}
        <Link
          to={strategyRoutesPaths.overview.root({
            params: { teamSlug: TeamAdapter.fromOrg(user.org).toParam() },
          })}
        >
          {user.org.currentStrategy.name}
        </Link>
      </Fragment>
    ),
    '.',
  ];
};

export const getTeamSentence = (
  t: TFunction,
  theme: Theme,
  subject: ContributionSubject,
  user: ContributionLineOfSightSectionUserFragment,
) => {
  const userName = getUserName(t, user.displayName);

  if (user.org && user.primaryOrgUnit) {
    return (
      <>
        {t(`contribution.lineOfSight.team.hasTeam.${subject}`, {
          user: userName,
        })}{' '}
        <LockedLink
          isInline={true}
          color={theme.color.primary}
          isActive={canPerformOrgUnitAction(user.primaryOrgUnit, 'READ')}
          inactiveColor={theme.color.black}
          decoration={true}
          to={strategyRoutesPaths.overview.root({
            params: {
              teamSlug: TeamAdapter.fromOrgUnit(
                user.primaryOrgUnit,
                user.org.orgKey,
              ).toParam(),
            },
          })}
        >
          {user.primaryOrgUnit.name}
        </LockedLink>
        {'.'}
      </>
    );
  } else {
    return `${t(`contribution.lineOfSight.team.noTeam.${subject}`, {
      user: userName,
    })}.`;
  }
};

export const getOtherTeamsSentence = (
  t: TFunction,
  theme: Theme,
  subject: ContributionSubject,
  user: ContributionLineOfSightSectionUserFragment,
) => {
  const roles = ['LEAD', 'ADMIN']
    .map((role) => ({
      role,
      orgUnits: user.orgUnitMemberships.memberships
        .filter((membership) => membership.roles.includes(role))
        .map((membership) => membership.orgUnit),
    }))
    .filter(({ orgUnits }) => orgUnits.length > 0);

  if (roles.length === 0) return null;

  const rolesBits = roles.map(({ role, orgUnits }) => (
    <>
      {t(`contribution.lineOfSight.otherTeams.role.${role}`)}{' '}
      {enumerate(
        t,
        orgUnits.map((orgUnit) => (
          <LockedLink
            key={orgUnit.id}
            isInline={true}
            color={theme.color.primary}
            inactiveColor={theme.color.black}
            isActive={canPerformOrgUnitAction(orgUnit, 'READ')}
            decoration={true}
            to={strategyRoutesPaths.overview.root({
              params: {
                teamSlug: user.org
                  ? TeamAdapter.fromOrgUnit(orgUnit, user.org.orgKey).toParam()
                  : '',
              },
            })}
          >
            {orgUnit.name}
          </LockedLink>
        )),
      )}
    </>
  ));

  const userName = getUserName(t, user.displayName);

  return (
    <>
      {t(`contribution.lineOfSight.otherTeams.intro.${subject}`, {
        user: userName,
      })}{' '}
      {enumerate(t, rolesBits, true)}
      {'.'}
    </>
  );
};

export const getOwnershipsSentence = (
  t: TFunction,
  subject: ContributionSubject,
  user: ContributionLineOfSightSectionUserFragment,
) => {
  const ownObjectives = getObjectives(user.contributingTo.asOwner);
  const ownMetrics = getMetrics(user.contributingTo.asOwner);
  const ownInitiatives = getInitiatives(user.contributingTo.asOwner);
  const ownActions = getActions(user.contributingTo.asOwner);

  const teamObjectives = getObjectives(user.contributingTo.asTeamMember);
  const teamMetrics = getMetrics(user.contributingTo.asTeamMember);
  const teamInitiatives = getInitiatives(user.contributingTo.asTeamMember);

  const hasOwnObjectives = ownObjectives.length > 0;
  const hasOwnMetrics = ownMetrics.length > 0;
  const hasOwnInitiatives = ownInitiatives.length > 0;
  const hasOwnActions = ownActions.length > 0;

  const hasTeamObjectives = teamObjectives.length > 0;
  const hasTeamMetrics = teamMetrics.length > 0;
  const hasTeamInitiatives = teamInitiatives.length > 0;

  const hasOwnContributions =
    hasOwnObjectives || hasOwnMetrics || hasOwnInitiatives || hasOwnActions;

  const hasTeamContributions =
    hasTeamObjectives || hasTeamMetrics || hasTeamInitiatives;

  const userName = getUserName(t, user.displayName);

  if (hasOwnContributions || hasTeamContributions) {
    const ownContributionsLine = hasOwnContributions
      ? [
          t(`contribution.lineOfSight.ownership.own.prefix.${subject}`, {
            user: userName,
          }),
          ' ',
          ...enumerate(t, [
            hasOwnObjectives &&
              t('count.objective', {
                count: ownObjectives.length,
              }),
            hasOwnMetrics &&
              t('count.metric', {
                count: ownMetrics.length,
              }),
            hasOwnInitiatives &&
              t('count.initiative', {
                count: ownInitiatives.length,
              }),
            hasOwnActions &&
              t('count.action', {
                count: ownActions.length,
              }),
          ]),
        ]
      : [];

    const teamContributionsLine = hasTeamContributions
      ? [
          hasOwnContributions
            ? t(
                `contribution.lineOfSight.ownership.team.prefixWithOwn.${subject}`,
                { user: userName },
              )
            : t(
                `contribution.lineOfSight.ownership.team.prefixWithoutOwn.${subject}`,
                { user: userName },
              ),
          ' ',
          ...enumerate(t, [
            hasTeamObjectives &&
              t('count.objective', {
                count: teamObjectives.length,
              }),
            hasTeamMetrics &&
              t('count.metric', {
                count: teamMetrics.length,
              }),
            hasTeamInitiatives &&
              t('count.initiative', {
                count: teamInitiatives.length,
              }),
          ]),
        ]
      : [];

    return [...ownContributionsLine, ...teamContributionsLine, '.'];
  } else {
    return `${t(`contribution.lineOfSight.ownership.empty.${subject}`, {
      user: userName,
    })}.`;
  }
};

const getUserName = (t: TFunction, displayName?: string) =>
  displayName && displayName !== '' ? displayName : t('user.user');
