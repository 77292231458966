import { useTranslation } from 'react-i18next';

import type { AssessmentCategory } from 'assessment/assessment.type';
import Flex from 'shared/components/Flex';

import AssessmentCategoryCard from './AssessmentCategoryCard';
import SophieGuideBanner from './SophieGuideBanner';

type Props = {
  setSelectedCategory: (category: AssessmentCategory) => void;
};

const AssessmentCategorySelection = ({ setSelectedCategory }: Props) => {
  const { t } = useTranslation();

  const categories: AssessmentCategory[] = [
    'STRATEGY_ANALYSIS',
    'STRESS_TEST',
    'RISK',
    'FUTURE',
    'FINANCIAL',
    'SUSTAINABILITY',
  ];

  return (
    <Flex direction={'column'} gap={40}>
      <SophieGuideBanner
        header={t('assessment.categorySelection.header')}
        content={t('assessment.categorySelection.content')}
      />
      <Flex wrap={'wrap'} gap={16}>
        {categories.map((category) => (
          <AssessmentCategoryCard
            key={category}
            category={category}
            onPress={() => setSelectedCategory(category)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default AssessmentCategorySelection;
