import type { TFunction } from 'react-i18next';

import {
  getActiveActions,
  getDoneActions,
  getInactiveActions,
  getOverdueActions,
} from 'actions/action.utils';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import type { ActionStatus } from 'types.graphql.generated';
import type { ActionsStatsActive } from 'actions/actions.type';

import styles from './ActionsHeader.module.scss';

export const getActionsStats = <TObjective extends ActionsObjectiveFragment>(
  objectives: Maybe<TObjective[]>,
  t: TFunction,
) => {
  const allActions = objectives?.flatMap((objective) => objective.actions);
  const inactiveActions = getInactiveActions(allActions);
  const activeActions = getActiveActions(allActions);
  const doneActions = getDoneActions(allActions);
  const activeAndInactiveActions = [...activeActions, ...inactiveActions];

  const overdueActions = getOverdueActions(activeAndInactiveActions);

  const hasActions = !!allActions?.length;

  const statsActive = [
    {
      name: t('actions.stats.name.inactive'),
      value: hasActions ? inactiveActions?.length ?? 0 : NaN,
      color: styles.inactiveColor,
    },
    {
      name: t('actions.stats.name.active'),
      value: hasActions ? activeActions?.length ?? 0 : NaN,
      color: styles.activeColor,
    },
    {
      name: t('actions.stats.name.done'),
      value: hasActions ? doneActions?.length ?? 0 : NaN,
      color: styles.doneColor,
    },
  ];

  const statOverdue = {
    name: t('actions.stats.name.overdue'),
    value: activeAndInactiveActions?.length
      ? 100 * ((overdueActions?.length || 0) / activeAndInactiveActions?.length)
      : NaN,
    color: styles.overdueColor,
  };

  return {
    activeActionsCount: activeActions?.length ?? 0,
    allActionsLength: allActions?.length,
    hasActions: !!allActions?.length,
    statsActive,
    statOverdue,
  };
};

export const getActionsStatsActive = <
  TStatus extends Pick<ActionStatus, 'statusIndicator'>,
  TAction extends { currentStatus?: TStatus },
>(
  metrics: Maybe<TAction[]>,
) => {
  const initialStats = {
    blocked: 0,
    atRisk: 0,
    onTrack: 0,
    noStatus: 0,
  };

  return metrics
    ? metrics.reduce<ActionsStatsActive>(
        (acc, { currentStatus }) => ({
          noStatus: currentStatus?.statusIndicator
            ? acc.noStatus
            : acc.noStatus + 1,
          blocked:
            currentStatus?.statusIndicator.value === 'BLOCKED'
              ? acc.blocked + 1
              : acc.blocked,
          atRisk:
            currentStatus?.statusIndicator.value === 'AT_RISK'
              ? acc.atRisk + 1
              : acc.atRisk,
          onTrack:
            currentStatus?.statusIndicator.value === 'ON_TRACK'
              ? acc.onTrack + 1
              : acc.onTrack,
        }),
        initialStats,
      )
    : initialStats;
};
