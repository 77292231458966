import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { MembersProps } from 'user/Members';
import Members from 'user/Members';
import { useToasts } from 'shared/toast/useToasts';
import { StrategyObjectivesOrgDocument } from 'strategy/StrategyObjectives/StrategyObjectivesProvider';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useUpdateObjectiveMutation } from 'objective/EditObjective/EditObjective.graphql.generated';
import { UserEmailInputDocument } from 'user/UserEmailInput';

import { getUsersToExclude } from './ObjectiveDetailsSiderMembers.utils';
import {
  ObjectiveDetailsDocument,
  type ObjectiveDetailsQuery,
} from '../ObjectiveDetailsProvider/ObjectiveDetailsProvider.graphql.generated';

type ObjectiveDetailsSiderMembersProps = {
  objective: Pick<
    ObjectiveDetailsQuery['objective'],
    'id' | 'owner' | 'teamMembers'
  > &
    StrategyElementWithAuthorizedActions;
};

const ObjectiveDetailsSiderMembers = ({
  objective,
}: ObjectiveDetailsSiderMembersProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const usersToExcludeFromAddingToTeam = useMemo(
    () => getUsersToExclude(objective),
    [objective],
  );

  const onError = useHandleError();

  const [updateObjective] = useUpdateObjectiveMutation({
    onError,
    refetchQueries: [
      UserEmailInputDocument,
      StrategyObjectivesOrgDocument,
      {
        query: ObjectiveDetailsDocument,
        variables: { objectiveId: objective?.id },
      },
      ContributionMyContributionDocument,
    ],
  });

  const handleAddTeamMember: NonNullable<
    MembersProps['onAddNewMember']
  > = async (data, closeModal) => {
    await updateObjective({
      variables: {
        input: {
          idToUpdate: objective?.id,
          teamMembers: {
            add: [
              {
                user: { emailToSet: data.user },
                ...(data.role && { role: data.role }),
              },
            ],
          },
        },
      },
      onCompleted: () => {
        closeModal();
        addToast({
          children: t('objective.objectiveLayout.addTeamMemberSuccessToast'),
          variant: 'success',
        });
      },
    });
  };

  const handleRemoveTeamMember: NonNullable<
    MembersProps['onRemoveMember']
  > = async (member) => {
    await updateObjective({
      variables: {
        input: {
          idToUpdate: objective?.id,
          teamMembers: {
            idsToDelete: [member.id],
          },
        },
      },
      onCompleted: () => {
        addToast({
          children: t(
            'objective.objectiveLayout.removeTeamMemberSuccessToast',
            {
              name: member.user?.displayName || member.user?.email,
            },
          ),
          variant: 'success',
        });
      },
    });
  };

  if (!objective) return null;

  const canWrite = canPerformStrategyElementAction(objective, 'WRITE');

  return (
    <Members
      owner={objective.owner}
      members={objective.teamMembers.map((teamMember) => ({
        id: teamMember.id,
        role: teamMember.role,
        ...(teamMember.user && {
          user: {
            id: teamMember.user?.id,
            email: teamMember.user?.email,
            displayName: teamMember.user?.displayName,
            photoUrl: teamMember.user?.photoUrl,
            isActive: teamMember.user?.isActive,
            isInActiveOrg: teamMember.user?.isInActiveOrg,
          },
        }),
      }))}
      usersToExclude={usersToExcludeFromAddingToTeam}
      canAddNewMember={canWrite}
      onAddNewMember={handleAddTeamMember}
      canRemoveMember={canWrite}
      onRemoveMember={handleRemoveTeamMember}
    />
  );
};

export default ObjectiveDetailsSiderMembers;
