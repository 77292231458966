import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { InitialStep } from 'shared/components/Steps';
import Steps from 'shared/components/Steps';

export const InsightReportWizardSteps = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  const initialSteps = useMemo<InitialStep[]>(
    () => [
      {
        id: 'objectives',
        name: t('report.insightReportWizard.objectives.name'),
      },
      {
        id: 'statusUpdate',
        name: t('report.insightReportWizard.statusUpdate.name'),
      },
      {
        id: 'successes',
        name: t('report.insightReportWizard.successes.name'),
        form: 'successesForm',
      },
      {
        id: 'challenges',
        name: t('report.insightReportWizard.challenges.name'),
        form: 'challengesForm',
      },
      {
        id: 'actions',
        name: t('report.insightReportWizard.actions.name'),
        form: 'actionsForm',
      },
    ],
    [t],
  );

  return <Steps initialSteps={initialSteps}>{children}</Steps>;
};

export default InsightReportWizardSteps;
