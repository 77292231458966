import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import useScrollToBottom from 'shared/hooks/useScrollToBottom';
import { transientOptions } from 'shared/utils/emotion.utils';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';

import Start from './Start';
import MessageItem from './MessageItem';
import FreeTextPrompt from './FreeTextPrompt';
import Prompt from './Prompt';
import type { PromptMessage } from './AskSophieChat.type';
import { useAskSophieChat } from './AskSophieChatProvider';

const Container = styled.div`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const StartContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MessagesContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-gutter: both-edges;
`;

const MessagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
  margin: 40px 25px 0 25px;
`;

const PromptContainer = styled('div', transientOptions)<{
  $isScrolledToBottom: boolean;
}>`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 24px;
  box-shadow: ${(props) =>
    props.$isScrolledToBottom
      ? 'none'
      : '0px 15px 12px 10px rgba(0, 0, 0, 0.8)'};
  z-index: 10;
`;

type Props = {
  hideFreeText?: boolean;
  infoText: string;
  isOpen: boolean;
  maxWidth?: number;
  promptMessages: PromptMessage[];
};

const AskSophieChat = ({
  isOpen,
  promptMessages,
  infoText,
  hideFreeText,
  maxWidth,
}: Props) => {
  const { t } = useTranslation();
  const {
    isScrolledToBottom,
    scrollToBottom,
    setScrollableDiv,
    setGrowableDiv,
  } = useScrollToBottom();

  const { messages, clearMessages } = useAskSophieChat();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom('smooth');
    }, 250);
  }, [messages.length, scrollToBottom]);

  const isChatEmpty = messages.length === 0;

  useEffect(() => {
    if (!isOpen) {
      clearMessages();
      setIsLoading(false);
    }
  }, [clearMessages, isOpen]);

  return (
    <Container>
      {isChatEmpty ? (
        <StartContainer>
          <Start infoText={infoText} />
        </StartContainer>
      ) : (
        <MessagesContainer ref={setScrollableDiv}>
          <MessagesWrapper>
            <Messages ref={setGrowableDiv} css={css({ maxWidth })}>
              {messages.map((message, index) => (
                <MessageItem
                  key={`${index}-${message.type}`}
                  message={message}
                  setIsLoading={setIsLoading}
                />
              ))}
            </Messages>
          </MessagesWrapper>
        </MessagesContainer>
      )}

      <PromptContainer $isScrolledToBottom={isChatEmpty || isScrolledToBottom}>
        <Flex
          direction={'column'}
          alignItems={'center'}
          gap={8}
          grow={1}
          css={css({ maxWidth: 1200 })}
        >
          {!hideFreeText && <FreeTextPrompt isLoading={isLoading} />}

          {promptMessages.length > 0 && (
            <Prompt messages={promptMessages} isLoading={isLoading} />
          )}
          {messages.length > 0 && (
            <Flex justifyContent={'center'} gap={8}>
              <Button
                variant={'simple'}
                color={'mono'}
                onPress={clearMessages}
                isDisabled={isLoading}
                icon={TrashIcon}
                iconPosition={'start'}
              >
                {t('ai.strategySummary.control.clear')}
              </Button>
            </Flex>
          )}
          <Text size={'tiny'} isCentered={true} css={css({ maxWidth: 500 })}>
            {t('ai.strategySummary.disclaimer')}
          </Text>
        </Flex>
      </PromptContainer>
    </Container>
  );
};

export default AskSophieChat;
