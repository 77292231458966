import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import FieldBox from 'shared/form/FieldBox';
import { DatePickerField } from 'shared/components/DatePicker';
import Flex from 'shared/components/Flex';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import {
  SelectObjectiveInputField,
  SelectObjectiveInputProvider,
} from 'objective/SelectObjectiveInput';
import type { ActionFormValues } from 'actions/EditAction/ActionForm';
import MultiFieldRow from 'shared/form/MultiFieldRow';

type Props = {
  showObjectiveField: boolean;
};

const ActionQuickFormFields = ({ showObjectiveField }: Props) => {
  const { t } = useTranslation();

  const { getFieldMeta } = useFormikContext<ActionFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  return (
    <Flex direction={'column'} gap={20}>
      <Field
        component={OpenTextInputField}
        name={'name'}
        placeholder={t('actions.form.name.placeholder')}
        fontSize={'big'}
        maxLength={100}
        autoFocus={true}
      />

      <Field
        component={OpenTextInputField}
        name={'description'}
        placeholder={t('actions.form.description.placeholder')}
        maxLength={1000}
        inputType={'textarea'}
      />

      <div>
        <MultiFieldRow>
          <FieldBox
            name={'timeLine.endDate'}
            label={t('actions.form.timeline.endDate.label')}
            hasError={hasError('timeLine.endDate')}
            hasMargin={false}
          >
            <Field
              name={'timeLine.endDate'}
              component={DatePickerField}
              minDate={new Date()}
              triggerFullWidth={true}
            />
          </FieldBox>

          <FieldBox
            name={'owner'}
            label={t('actions.form.owner.label')}
            hasError={hasError('owner')}
          >
            <UserEmailInputProvider>
              <Field
                component={UserEmailInputField}
                name={'owner'}
                autoComplete={'false'}
                placeholder={t('actions.form.owner.placeholder')}
              />
            </UserEmailInputProvider>
          </FieldBox>
        </MultiFieldRow>

        {showObjectiveField && (
          <FieldBox
            name={'objective'}
            label={t('actions.form.objective.label')}
            hasMargin={false}
          >
            <SelectObjectiveInputProvider filterByAction={'WRITE'}>
              <Field
                name={'objective'}
                component={SelectObjectiveInputField}
                size={'full'}
              />
            </SelectObjectiveInputProvider>
          </FieldBox>
        )}
      </div>
    </Flex>
  );
};

export default ActionQuickFormFields;
