import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';
import Tooltip from 'shared/components/Tooltip';
import type { Metric, MetricStatus } from 'types.graphql.generated';

import MetricScoreContent from './MetricScoreContent';

const Container = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;

  font-size: 0.857rem;
  height: 26px;
  color: ${({ theme }) => theme.color.typoSecondary};
  background-color: ${({ theme }) => theme.color.backgroundDark};
  padding: 0 6px;
  border-radius: 4px;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type Props = {
  hideUnitOfMeasure?: boolean;
  metric: Pick<Metric, 'unitOfMeasure' | 'startValue' | 'targetValue'> & {
    currentMetricStatus?: Pick<MetricStatus, 'statusValue'>;
  };
};

const MetricScore = ({ metric, hideUnitOfMeasure }: Props) => {
  const { t } = useTranslation();

  const currentValue =
    metric.currentMetricStatus?.statusValue !== undefined &&
    metric.currentMetricStatus.statusValue !== null
      ? metric.currentMetricStatus.statusValue
      : metric.startValue;

  const hasCurrentValue = currentValue !== undefined && currentValue !== null;

  const currentValueLabel = hasCurrentValue ? currentValue : '-';

  const hasTargetValue =
    metric.targetValue !== undefined && metric.targetValue !== null;

  const targetValueLabel = hasTargetValue ? metric.targetValue : '-';

  if (!hasCurrentValue && !hasTargetValue) return null;

  return (
    <Tooltip
      delay={0}
      content={
        <TooltipContainer>
          {metric.unitOfMeasure && <Text>{metric.unitOfMeasure}</Text>}
          <Flex justifyContent={'space-between'} gap={16}>
            {t('metric.actualScore')}
            <div>{currentValueLabel}</div>
          </Flex>
          {hasTargetValue && (
            <Flex justifyContent={'space-between'} gap={16}>
              {t('metric.targetValue')}
              <div>{targetValueLabel}</div>
            </Flex>
          )}
        </TooltipContainer>
      }
    >
      <Container>
        <MetricScoreContent
          metric={metric}
          hideUnitOfMeasure={hideUnitOfMeasure}
        />
      </Container>
    </Tooltip>
  );
};

export default MetricScore;
