import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, type PropsWithChildren } from 'react';
import * as yup from 'yup';
import { formatRFC3339 } from 'date-fns';

import Form from 'shared/form/Form';
import { useToasts } from 'shared/toast/useToasts';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ActionStatus } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import {
  useAddActionStatusMutation,
  type AddActionStatusMutation,
} from 'actions/ActionContextMenu/AddActionStatus.graphql.generated';

export type ActionStatusFormValues = {
  completionRate: number;
};

const validationSchema = yup.object({
  completionRate: yup.number().min(0).max(100),
});

type Props = PropsWithChildren<{
  action: {
    currentStatus?: Pick<ActionStatus, 'completionRate'>;
    id: string;
  };
  formId: string;
  onClose: () => void;
}>;

const ActionStatusForm = ({ formId, action, onClose, children }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { teamAdapter } = useTeamAdapter();

  const onError = useHandleError();

  const [addActionStatus] = useAddActionStatusMutation({
    onCompleted: ({ addActionStatus }: AddActionStatusMutation) =>
      addToast({
        variant: 'success',
        children:
          addActionStatus.completionRate === 100
            ? t(`actions.toasts.addActionStatusSuccessDone`)
            : t(`actions.toasts.addActionStatusSuccessUpdate`),
      }),
    onError,
  });

  const initialValues: ActionStatusFormValues = useMemo(
    () => ({ completionRate: action.currentStatus?.completionRate ?? 0 }),
    [action.currentStatus?.completionRate],
  );

  const handleSubmit = useCallback(
    (values: ActionStatusFormValues) =>
      addActionStatus({
        variables: {
          input: {
            actionId: action.id,
            orgKey: teamAdapter.orgKey,
            complete: values.completionRate === 100,
            completionRate: values.completionRate,
            statusDateTime: formatRFC3339(new Date()),
          },
        },
      }).then(onClose),
    [action.id, addActionStatus, onClose, teamAdapter.orgKey],
  );

  return (
    <Form
      id={formId}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      hidden={true}
    >
      {children}
    </Form>
  );
};

export default ActionStatusForm;
