import styled from '@emotion/styled';

import PromptButton from 'ai/AskSohpieButton/AskSophieSidePanel/PromptButton';

import type { PromptMessage } from './AskSophieChat.type';
import { useAskSophieChat } from './AskSophieChatProvider';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

type Props = {
  isLoading: boolean;
  messages: PromptMessage[];
};

const Prompt = ({ messages, isLoading }: Props) => {
  const {
    objectiveIds,
    metricIds,
    initiativeIds,
    actionIds,
    strategyId,
    addMessage,
  } = useAskSophieChat();

  const createAddSummaryHandler = (promptMessage: PromptMessage) => () => {
    addMessage({ type: 'InputMessage', content: promptMessage.buttonLabel });
    addMessage({
      type: 'SummaryMessage',
      promptMessage,
      context: {
        objectiveIds,
        metricIds,
        initiativeIds,
        actionIds,
        strategyId,
      },
    });
  };

  return (
    <Container>
      {messages.map((message) => (
        <PromptButton
          key={message.buttonLabel}
          onClick={createAddSummaryHandler(message)}
          isDisabled={isLoading}
        >
          {message.buttonLabel}
        </PromptButton>
      ))}
    </Container>
  );
};

export default Prompt;
