import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { PropsWithChildren, Ref } from 'react';
import {
  Dialog,
  Modal as AriaModal,
  ModalOverlay,
} from 'react-aria-components';

import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

import Text from '../Text';
import Button from '../Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.color.strokeLight};
  padding: 20px;
  height: 64px;
  background-color: ${(props) => props.theme.color.white};
  z-index: 10;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HeaderButton = styled(Button)`
  height: 40px;
  min-width: 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.color.white};
  min-height: calc(100% - 64px);
  overflow-y: auto;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--visual-viewport-height);
  background: rgba(0 0 0 / 0.5);
  z-index: 4400;

  &[data-entering] {
    animation: modal-fade 200ms;
  }

  &[data-exiting] {
    animation: modal-fade 300ms reverse;
  }

  @keyframes modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const modalStyle = css`
  height: 100vh;
`;

const dialogStyle = css`
  :focus-visible {
    outline: none;
  }
`;

const modalAnimationStyles = {
  zoom: css`
    &[data-entering] {
      animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    @keyframes modal-zoom {
      from {
        transform: scale(0.8);
      }

      to {
        transform: scale(1);
      }
    }
  `,
  'slide-from-bottom': css`
    &[data-entering] {
      animation: modal-slide-from-bottom 300ms;
    }

    &[data-exiting] {
      animation: modal-slide-from-bottom 300ms reverse ease-in;
    }

    @keyframes modal-slide-from-bottom {
      from {
        transform: translateY(100%);
      }

      to {
        transform: translateY(0);
      }
    }
  `,
};

export type ModalAnimation = 'zoom' | 'slide-from-bottom';

const valueBySize = {
  medium: 1057,
  large: 1200,
  full: '100%',
};

type Props = PropsWithChildren<{
  animation?: ModalAnimation;
  confirmLabel?: string;
  contentSize?: keyof typeof valueBySize;
  formId?: string;
  hasPadding?: boolean;
  headerSize?: keyof typeof valueBySize;
  headerTitle: string;
  isConfirmDisabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onMaximize?: () => void;
  overlayRef?: Ref<HTMLDivElement>;
}>;

const Modal = ({
  formId,
  headerTitle,
  confirmLabel,
  isOpen,
  onClose,
  children,
  hasPadding = true,
  contentSize = 'medium',
  headerSize = contentSize,
  animation = 'slide-from-bottom',
  isConfirmDisabled,
  overlayRef,
}: Props) => {
  const theme = useTheme();

  const modalAnimationStyle = modalAnimationStyles[animation];

  return (
    <ModalOverlay
      ref={overlayRef}
      css={overlayStyle}
      isOpen={isOpen}
      isDismissable={false}
      onOpenChange={(isOpen) => isOpen || onClose()}
    >
      <AriaModal css={[modalStyle, modalAnimationStyle]}>
        <Dialog css={dialogStyle}>
          <Container onClick={(event) => event.stopPropagation()}>
            <Header>
              <HeaderContent css={css({ maxWidth: valueBySize[headerSize] })}>
                <Text variant={'emphasis'} size={'large'} isBold={true}>
                  {headerTitle}
                </Text>
                <HeaderControls>
                  {confirmLabel && (
                    <HeaderButton
                      color={'primary'}
                      type={formId ? 'submit' : 'button'}
                      form={formId}
                      isDisabled={isConfirmDisabled}
                    >
                      {confirmLabel}
                    </HeaderButton>
                  )}

                  <HeaderButton
                    variant={'icon'}
                    icon={CloseIcon}
                    css={css({
                      color: 'black',
                      borderColor: theme.color.strokeMedium,
                    })}
                    onPress={onClose}
                  />
                </HeaderControls>
              </HeaderContent>
            </Header>

            <ContentContainer
              data-testid={'modal-content'}
              css={hasPadding && css({ padding: 20 })}
            >
              <Content css={css({ maxWidth: valueBySize[contentSize] })}>
                {children}
              </Content>
            </ContentContainer>
          </Container>
        </Dialog>
      </AriaModal>
    </ModalOverlay>
  );
};

export default Modal;
