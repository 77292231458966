import styled from '@emotion/styled';

import InputMessageItem from 'ai/AskSophieChat/InputMessageItem';
import SummaryMessageItem from 'ai/AskSophieChat/SummaryMessageItem';
import { transientOptions } from 'shared/utils/emotion.utils';

import type { Message } from './AskSophieChat.type';

const Container = styled('div', transientOptions)<{
  $messageType: Message['type'];
}>`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: ${(props) =>
    props.$messageType === 'SummaryMessage' ? 'flex-start' : 'flex-end'};
`;

type Props = {
  message: Message;
  setIsLoading: (isLoading: boolean) => void;
};

const MessageItem = ({ message, setIsLoading }: Props) => (
  <Container $messageType={message.type}>
    {message.type === 'SummaryMessage' ? (
      <SummaryMessageItem summary={message} setIsLoading={setIsLoading} />
    ) : (
      <InputMessageItem message={message} />
    )}
  </Container>
);

export default MessageItem;
