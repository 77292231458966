import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { RichTextField } from 'shared/components/RichTextInput';
import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import Flex from 'shared/components/Flex';

const ScrollableContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 0 20px 20px 20px;
`;

type Props = {
  overlayElement: HTMLDivElement | null;
};

const AssessmentFormFields = ({ overlayElement }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex direction={'column'} grow={1}>
      <div
        css={css({
          padding: '20px',
        })}
      >
        <Field
          component={OpenTextInputField}
          name={'title'}
          placeholder={t('assessment.fields.title.placeholder')}
          fontSize={'big'}
          maxLength={100}
          autoFocus={true}
        />
      </div>

      <Flex
        direction={'column'}
        grow={1}
        css={css({ position: 'relative', overflowY: 'auto' })}
      >
        <ScrollableContainer>
          <Field
            name={'content'}
            component={RichTextField}
            popupContainerParent={overlayElement}
          />
        </ScrollableContainer>
      </Flex>
    </Flex>
  );
};

export default AssessmentFormFields;
