import type { TFuncKey } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { groupBy, uniq } from 'lodash';
import type { TFunction } from 'i18next';
import { addDays } from 'date-fns';

import Dialog from 'shared/components/Dialog';
import { enumerate } from 'shared/utils/string.utils';
import DatePicker from 'shared/components/DatePicker';
import InputBox from 'shared/components/InputBox';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { useUser } from 'user/UserProvider';
import UserEmailInput, { UserEmailInputProvider } from 'user/UserEmailInput';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import type { StrategyElement } from './useSendStatusRequest';
import useSendStatusRequest from './useSendStatusRequest';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  strategyElements: StrategyElement[];
};

const SendStatusRequestsDialog = ({
  strategyElements,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { sendStatusRequest, isSendingStatusRequest } = useSendStatusRequest();

  const [deadline, setDeadline] = useState<Date | undefined | null>(
    addDays(new Date(), 7),
  );

  const [requester, setRequester] = useState(user.email);

  useEffect(() => {
    if (isOpen) {
      setDeadline(addDays(new Date(), 7));
      setRequester(user.email);
    }
  }, [isOpen, user.email]);

  const handleConfirm = useCallback(
    () =>
      sendStatusRequest(strategyElements, deadline, requester).then(onClose),
    [deadline, onClose, requester, sendStatusRequest, strategyElements],
  );

  const isEmpty = strategyElements.length === 0;
  const isSingle = strategyElements.length === 1;
  const isDisabled = isEmpty || isSendingStatusRequest || !deadline;

  const ownerCount = uniq(
    strategyElements
      .map((strategyElements) => strategyElements.owner?.id)
      .filter(Boolean),
  ).length;

  const strategyElementsByType = groupBy(strategyElements, (strategyElement) =>
    strategyElement.__typename === 'ChildObjective'
      ? 'Objective'
      : strategyElement.__typename,
  );

  const ownerName =
    strategyElements[0]?.owner?.displayName ||
    strategyElements[0]?.owner?.email;

  const subtitle = isSingle ? (
    t('statusRequest.dialog.single', { user: ownerName })
  ) : (
    <>
      {t('statusRequest.dialog.multi.intro')}{' '}
      {t('statusRequest.dialog.multi.owner', { count: ownerCount })}{' '}
      {enumerate(t, [
        getTranslation(
          t,
          'objective.activeObjective',
          strategyElementsByType['Objective'],
        ),
        getTranslation(
          t,
          'metric.activeMetric',
          strategyElementsByType['Metric'],
        ),
        getTranslation(
          t,
          'initiative.activeInitiative',
          strategyElementsByType['Initiative'],
        ),
      ])}
      {'?'}
    </>
  );

  const canChangeRequester = useCanPerformOrgOrOrgUnitAction(
    'WRITE_STRATEGY',
    'WRITE',
  );

  if (isEmpty) return null;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      size={'small'}
      headerTitle={t('statusRequest.action')}
      headerSubtitle={subtitle}
      confirmLabel={t('statusRequest.dialog.submit', {
        count: strategyElements.length,
      })}
      confirmIcon={SendIcon}
      cancelLabel={t('cancel')}
      isConfirmDisabled={isDisabled}
    >
      <InputBox name={'deadline'} label={t('deadline')}>
        <DatePicker
          onChange={setDeadline}
          value={deadline}
          hasError={!deadline}
        />
      </InputBox>

      {canChangeRequester && (
        <InputBox
          name={'requester'}
          label={t('statusRequest.dialog.requester')}
        >
          <UserEmailInputProvider>
            <UserEmailInput
              value={requester}
              onChange={setRequester}
              forceSelectInput={true}
            />
          </UserEmailInputProvider>
        </InputBox>
      )}
    </Dialog>
  );
};

export default SendStatusRequestsDialog;

function getTranslation(
  t: TFunction,
  key: TFuncKey,
  elements?: StrategyElement[],
) {
  const count = elements?.length || 0;

  if (count === 0) return null;

  return `${count} ${t(key, { count })}`;
}
