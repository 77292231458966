import cn from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import styles from './StepsItem.module.scss';
import { useSteps } from '../useSteps';

type ChildrenProps = {
  onStepComplete: () => void;
  onStepError: () => void;
};

export type StepsItemProps = {
  children: ReactNode | ((childrenProps: ChildrenProps) => ReactNode);
  destroyOnLeave?: boolean;
  id: string;
};

const StepsItem = ({
  id,
  children,
  destroyOnLeave = false,
}: StepsItemProps) => {
  const { setStep, completeStep, getStep } = useSteps();
  const currentStep = getStep(id);

  const childrenProps = useMemo<ChildrenProps | undefined>(() => {
    if (currentStep) {
      return {
        onStepComplete: () => completeStep(currentStep.id),
        onStepError: () =>
          setStep(currentStep.id, (previousStep) => ({
            ...previousStep,
            isCompleted: false,
            isError: true,
          })),
      };
    }
    return undefined;
  }, [completeStep, currentStep, setStep]);

  if (!childrenProps) return null;

  const isDisplayed = currentStep?.isActive;

  if (!destroyOnLeave || isDisplayed) {
    return (
      <div
        className={cn(styles.stepsItem, {
          [styles.stepsItemDisplayed]: isDisplayed,
        })}
      >
        {typeof children === 'function' ? children(childrenProps) : children}
      </div>
    );
  }

  return null;
};

export default StepsItem;
