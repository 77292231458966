import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Dialog as AriaDialog,
  Modal,
  ModalOverlay,
} from 'react-aria-components';
import { isValidElement, useCallback, useState } from 'react';
import type { PropsWithChildren, ReactNode } from 'react';
import { isArray, noop } from 'lodash';

import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import { ReactComponent as MaximizeIcon } from 'shared/static/icons/icon-maximize.svg';

import Text from '../Text';
import type { ButtonProps } from '../Button';
import Button from '../Button';
import Flex from '../Flex';

const Container = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border-radius: 20px;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.color.strokeLight};
  padding: 20px;
`;

const HeaderControls = styled.div`
  display: flex;
  gap: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Content = styled.div`
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 350px);
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.color.strokeLight};
`;

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--visual-viewport-height);
  background: rgba(0 0 0 / 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4500;

  @keyframes modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const overlayOpenAnimationStyle = css`
  &[data-entering] {
    animation: modal-fade 200ms;
  }
`;

const overlayCloseAnimationStyle = css`
  &[data-exiting] {
    animation: modal-fade 150ms reverse ease-in;
  }
`;

const modalStyle = css`
  box-shadow: 0 8px 20px rgba(0 0 0 / 0.1);
  border-radius: 6px;
  background: var(--overlay-background);
  border: 1px solid var(--gray-400);
  outline: none;
`;

const dialogStyle = css`
  :focus-visible {
    outline: none;
  }
`;

const modalOpenAnimationStyle = css`
  &[data-entering] {
    animation: modal-zoom 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  @keyframes modal-zoom {
    from {
      transform: scale(0.8);
    }

    to {
      transform: scale(1);
    }
  }
`;

const widthBySize = {
  tiny: '300px',
  small: '470px',
  regular: '640px',
  big: '1024px',
};

type Size = keyof typeof widthBySize;

type Props = PropsWithChildren<{
  animateClose?: boolean;
  animateOpen?: boolean;
  cancelLabel?: string;
  closeOnCancel?: boolean;
  closeOnConfirm?: boolean;
  confirmIcon?: ButtonProps['icon'];
  confirmLabel?: string;
  footerPreContent?: ReactNode;
  formId?: string;
  headerContent?: ReactNode;
  headerPretitle?: string;
  headerSubtitle?: string | string[] | ReactNode;
  headerTitle?: string;
  isConfirmDisabled?: boolean;
  isOpen: boolean;
  onCancel?: () => void | Promise<unknown>;
  onClose: () => void;
  onConfirm?: () => void | Promise<unknown>;
  onMaximize?: () => void;
  size?: Size;
}>;

const Dialog = ({
  formId,
  headerPretitle,
  headerTitle,
  headerSubtitle,
  headerContent,
  footerPreContent,
  confirmLabel,
  confirmIcon,
  isConfirmDisabled,
  cancelLabel,
  isOpen,
  onClose,
  onConfirm = noop,
  onCancel = noop,
  onMaximize,
  closeOnConfirm = true,
  closeOnCancel = true,
  children,
  size = 'regular',
  animateOpen = true,
  animateClose = true,
}: Props) => {
  const hasHeader = headerTitle || headerContent;
  const hasFooter = confirmLabel || cancelLabel;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = useCallback(() => {
    setIsSubmitting(true);
    Promise.resolve(onConfirm())
      .then(closeOnConfirm ? onClose : undefined)
      .finally(() => setIsSubmitting(false));
  }, [closeOnConfirm, onClose, onConfirm]);

  const handleCancel = useCallback(() => {
    setIsSubmitting(true);
    Promise.resolve(onCancel())
      .then(closeOnCancel ? onClose : undefined)
      .finally(() => setIsSubmitting(false));
  }, [closeOnCancel, onCancel, onClose]);

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      css={css({ display: 'none' })}
    >
      <ModalOverlay
        isOpen={isOpen}
        isDismissable={true}
        onOpenChange={(isOpen) => isOpen || onClose()}
        css={[
          overlayStyle,
          animateOpen && overlayOpenAnimationStyle,
          animateClose && overlayCloseAnimationStyle,
        ]}
      >
        <Modal
          css={[
            modalStyle,
            animateOpen && modalOpenAnimationStyle,
            css({ width: widthBySize[size] }),
          ]}
        >
          <AriaDialog css={dialogStyle}>
            <Container>
              {hasHeader && (
                <Header>
                  <Flex
                    direction={'column'}
                    css={css({ alignSelf: 'center' })}
                    grow={1}
                    gap={'0.5rem'}
                  >
                    {headerPretitle && (
                      <Text color={'primary'} size={'small'} isBold={true}>
                        {headerPretitle}
                      </Text>
                    )}

                    <Text variant={'emphasis'} size={'large'} isBold={true}>
                      {headerTitle}
                    </Text>

                    {headerSubtitle && typeof headerSubtitle === 'string' && (
                      <Text>{headerSubtitle}</Text>
                    )}

                    {headerSubtitle &&
                      isArray(headerSubtitle) &&
                      headerSubtitle.map((subtitle, index) => (
                        <div key={`${index}-${subtitle}`}>
                          <Text>{subtitle}</Text>
                        </div>
                      ))}

                    {headerSubtitle && isValidElement(headerSubtitle) && (
                      <Text>{headerSubtitle}</Text>
                    )}

                    {headerContent}
                  </Flex>

                  <HeaderControls>
                    {onMaximize && (
                      <IconContainer onClick={onMaximize}>
                        <MaximizeIcon />
                      </IconContainer>
                    )}
                    <IconContainer onClick={onClose}>
                      <CloseIcon />
                    </IconContainer>
                  </HeaderControls>
                </Header>
              )}

              <Content data-testid={'dialog-content'}>{children}</Content>

              {hasFooter && (
                <Footer>
                  {footerPreContent}

                  <Flex grow={1} />

                  {cancelLabel && (
                    <Button
                      variant={'outlined'}
                      color={'mono'}
                      onPress={handleCancel}
                      isDisabled={isSubmitting}
                    >
                      {cancelLabel}
                    </Button>
                  )}

                  {confirmLabel && (
                    <Button
                      color={'primary'}
                      type={formId ? 'submit' : 'button'}
                      form={formId}
                      onPress={formId ? undefined : handleConfirm}
                      isDisabled={isConfirmDisabled || isSubmitting}
                      icon={confirmIcon}
                      iconPosition={'start'}
                    >
                      {confirmLabel}
                    </Button>
                  )}
                </Footer>
              )}
            </Container>
          </AriaDialog>
        </Modal>
      </ModalOverlay>
    </div>
  );
};

export default Dialog;
