import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { Message } from '../AskSophieChat.type';
import AskSophieChatContext from './AskSophieChat.context';

type Props = PropsWithChildren<{
  actionIds?: string[];
  initialMessages?: Message[];
  initiativeIds?: string[];
  metricIds?: string[];
  objectiveIds?: string[];
  onClearMessages?: () => void;
  onSummaryGenerated?: (content?: string) => void;
  regenerateButtonLabel?: string;
  strategyId?: string;
}>;

const AskSophieChatProvider = ({
  objectiveIds,
  metricIds,
  initiativeIds,
  actionIds,
  strategyId,
  regenerateButtonLabel,
  onSummaryGenerated,
  onClearMessages,
  initialMessages,
  children,
}: Props) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages || []);

  const addMessage = useCallback(
    (message: Message) => setMessages((messages) => [...messages, message]),
    [],
  );

  const clearMessages = useCallback(() => {
    setMessages([]);
    onClearMessages?.();
  }, [onClearMessages]);

  const value = useMemo(
    () => ({
      objectiveIds,
      metricIds,
      initiativeIds,
      actionIds,
      strategyId,
      regenerateButtonLabel,
      onSummaryGenerated,
      messages,
      addMessage,
      clearMessages,
    }),
    [
      actionIds,
      addMessage,
      clearMessages,
      initiativeIds,
      messages,
      metricIds,
      objectiveIds,
      onSummaryGenerated,
      regenerateButtonLabel,
      strategyId,
    ],
  );

  return (
    <AskSophieChatContext.Provider value={value}>
      {children}
    </AskSophieChatContext.Provider>
  );
};

export default AskSophieChatProvider;
