import { useActiveOrg } from 'org/ActiveOrgProvider';

import UserEmailTextInput from './UserEmailTextInput';
import UserEmailSelectInput from './UserEmailSelectInput';

type Props = {
  forceSelectInput?: boolean;
  forceTextInput?: boolean;
  hasError?: boolean;
  name?: string;
  onChange: (value: string) => void;
  value: string;
};

const UserEmailInput = ({
  name,
  value,
  onChange,
  hasError,
  forceTextInput,
  forceSelectInput,
}: Props) => {
  const { activeOrg } = useActiveOrg();

  const isAutoCreateEnabled =
    activeOrg.systemPreferences.doAutoCreateUserAccounts;

  return !forceSelectInput && (isAutoCreateEnabled || forceTextInput) ? (
    <UserEmailTextInput
      name={name}
      value={value}
      onChange={onChange}
      hasError={hasError}
    />
  ) : (
    <UserEmailSelectInput
      name={name}
      value={value}
      onChange={onChange}
      hasError={hasError}
    />
  );
};

export default UserEmailInput;
