import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import type { CreateStrategyWizardProps } from 'strategy/CreateStrategyWizard';
import CreateStrategyWizard from 'strategy/CreateStrategyWizard';
import { isStrategyWizardStepId } from 'strategy/StrategyWizard';
import { strategyRoomRoutesPaths } from 'strategy/StrategyRoom/StrategyRoom.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import { StrategyRoomStrategiesDocument } from 'strategy/StrategyRoomStrategy/StrategyRoomProvider';
import { useGlobalSpinner } from 'shared/spinner/GlobalSpinner';
import { useEditStrategyWizardStrategyLazyQuery } from 'strategy/EditStrategyWizard';
import useHandleError from 'shared/errors/useHandleError';

const StrategyRoomCreateStrategy = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const apolloClient = useApolloClient();

  const { teamAdapter } = useTeamAdapter();

  const { showGlobalSpinner, hideGlobalSpinner } = useGlobalSpinner();

  const onError = useHandleError();

  const [fetchEditStrategyWizardStrategy] =
    useEditStrategyWizardStrategyLazyQuery({ onError });

  const handleClose = useCallback(() => navigate('../'), [navigate]);

  const handleSuccess = useCallback<CreateStrategyWizardProps['onSuccess']>(
    async (strategyId) => {
      showGlobalSpinner();

      const step = searchParams.get('step');

      await fetchEditStrategyWizardStrategy({
        variables: {
          strategyId,
        },
      });

      await apolloClient.refetchQueries({
        include: [StrategyRoomStrategiesDocument],
      });

      navigate(
        `../${strategyRoomRoutesPaths.edit({
          query: {
            strategyId,
            step: isStrategyWizardStepId(step) ? step : undefined,
          },
        })}`,
        { replace: true },
      );

      hideGlobalSpinner();
    },
    [
      apolloClient,
      fetchEditStrategyWizardStrategy,
      hideGlobalSpinner,
      navigate,
      searchParams,
      showGlobalSpinner,
    ],
  );

  return (
    <CreateStrategyWizard
      onClose={handleClose}
      isOpen={true}
      onSuccess={handleSuccess}
      teamAdapter={teamAdapter}
    />
  );
};

export default StrategyRoomCreateStrategy;
