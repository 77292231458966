import { createContext } from 'react';

import type { Message } from '../AskSophieChat.type';

type Value = {
  actionIds?: string[];
  addMessage: (message: Message) => void;
  clearMessages: () => void;
  initiativeIds?: string[];
  messages: Message[];
  metricIds?: string[];
  objectiveIds?: string[];
  onSummaryGenerated?: (content?: string) => void;
  regenerateButtonLabel?: string;
  strategyId?: string;
};

const AskSophieChatContext = createContext<Value | undefined>(undefined);

export default AskSophieChatContext;
