import compact from 'lodash/compact';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as IconEdit } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as IconCheck } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as IconBlock } from 'shared/static/icons/icon-block.svg';
import { ReactComponent as IconSend } from 'shared/static/icons/icon-send.svg';
import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import type { OrgUnit, OrgUnitRole, User } from 'types.graphql.generated';
import { useUser } from 'user/UserProvider';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';
import useCanPerformOrgUnitAction from 'user/ability/useCanPerformOrgUnitAction';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import useDialogState from 'shared/hooks/useDialogState';
import EditUserProfileModal from 'user/EditUserProfileModal';
import DeactivateUserDialog from 'user/DeactivateUserDialog';
import ActivateUserDialog from 'user/ActivateUserDialog';
import RemoveOrgUnitMemberConfirmationModal from 'orgUnit/RemoveOrgUnitMemberConfirmationModal';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import RemoveOrgMemberConfirmationModal from 'orgUnit/RemoveOrgMemberConfirmationModal';

import type {
  TeamMembersListViewContextMenuItem,
  TeamMembersListViewContextMenuItemId,
} from './UserContextMenu.type';
import { useEmailLoginInstructionsMutation } from './UserContextMenu.graphql.generated';

type Props = {
  onRemoveFromOrg?: () => void;
  showRemoveFromOrgUnit: boolean;
  user: Pick<User, 'id' | 'isActive'> & {
    orgUnitMemberships: {
      memberships: {
        orgUnit: Pick<OrgUnit, 'id'>;
        roles: OrgUnitRole[];
      }[];
    };
    primaryOrgUnit?: Pick<OrgUnit, 'id'> & OrgUnitWithAuthorizedActions;
  };
};

const UserContextMenu = ({
  user,
  showRemoveFromOrgUnit,
  onRemoveFromOrg,
}: Props) => {
  const { t } = useTranslation();
  const { user: currentUser } = useUser();
  const { orgUnit } = useOrgUnit();

  const { addToast } = useToasts();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const {
    isOpen: isDeactivateOpen,
    onOpen: onDeactivateOpen,
    onClose: onDeactivateClose,
  } = useDialogState();

  const {
    isOpen: isActivateOpen,
    onOpen: onActivateOpen,
    onClose: onActivateClose,
  } = useDialogState();

  const {
    isOpen: isRemoveFromOrgUnitOpen,
    onOpen: onRemoveFromOrgUnitOpen,
    onClose: onRemoveFromOrgUnitClose,
  } = useDialogState();

  const {
    isOpen: isRemoveFromOrgOpen,
    onOpen: onRemoveFromOrgOpen,
    onClose: onRemoveFromOrgClose,
  } = useDialogState();

  const onError = useHandleError();

  const [emailLoginInstructions] = useEmailLoginInstructionsMutation({
    variables: { userId: user.id },
    onError,
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('userMenu.sendEmail.success'),
      }),
  });

  const isOrgUnit = !!orgUnit;

  const currentOrgUnitMembership = user.orgUnitMemberships.memberships.find(
    (membership) =>
      membership.orgUnit.id === orgUnit?.id &&
      membership.roles.includes('MEMBER'),
  );

  const isOrgUnitMember = !!currentOrgUnitMembership;

  const canManageOrg = useCanPerformOrgAction('WRITE_ACCOUNT');
  const canManageOrgUsers = useCanPerformOrgAction('CREATE_USER');
  const canManageOrgUnit = useCanPerformOrgUnitAction('WRITE');
  const isCurrentUser = user.id === currentUser.id;

  const canSendEmail =
    (canManageOrg || canManageOrgUsers || canManageOrgUnit) && !isCurrentUser;

  const items = useMemo<TeamMembersListViewContextMenuItem[]>(
    () =>
      compact([
        (canManageOrg || canManageOrgUnit || isCurrentUser) && {
          id: 'editProfile',
          title: t('userMenu.editProfile'),
          icon: IconEdit,
        },
        canSendEmail &&
          !isCurrentUser && {
            id: 'sendEmail',
            title: t('userMenu.sendEmail.menuItem'),
            icon: IconSend,
          },
        canManageOrgUsers &&
          !isCurrentUser &&
          user.isActive &&
          !isOrgUnit && {
            id: 'deactivate',
            title: t('userMenu.deactivate.menuItem'),
            icon: IconBlock,
          },
        canManageOrgUsers &&
          !isCurrentUser &&
          !user.isActive &&
          !isOrgUnit && {
            id: 'activate',
            title: t('userMenu.activate.menuItem'),
            icon: IconCheck,
          },
        canManageOrgUsers &&
          !isCurrentUser &&
          !isOrgUnit && {
            id: 'removeFromOrg',
            title: t('userMenu.removeFromOrg.menuItem'),
            icon: TrashIcon,
            variant: 'danger',
          },
        canManageOrgUnit &&
          isOrgUnitMember &&
          showRemoveFromOrgUnit && {
            id: 'removeFromOrgUnit',
            title: t('userMenu.removeFromOrgUnit.menuItem'),
            icon: TrashIcon,
            variant: 'danger',
          },
      ]),
    [
      canManageOrg,
      canManageOrgUnit,
      canManageOrgUsers,
      canSendEmail,
      isCurrentUser,
      isOrgUnit,
      isOrgUnitMember,
      showRemoveFromOrgUnit,
      t,
      user.isActive,
    ],
  );

  const onAction = useCallback<
    ContextMenuOnActionFn<TeamMembersListViewContextMenuItemId>
  >(
    (actionId: TeamMembersListViewContextMenuItemId) => {
      switch (actionId) {
        case 'editProfile':
          onEditOpen();
          break;
        case 'deactivate':
          onDeactivateOpen();
          break;
        case 'activate':
          onActivateOpen();
          break;
        case 'removeFromOrg':
          onRemoveFromOrgOpen();
          break;
        case 'removeFromOrgUnit':
          onRemoveFromOrgUnitOpen();
          break;
        case 'sendEmail':
          emailLoginInstructions();
          break;
      }
    },
    [
      emailLoginInstructions,
      onActivateOpen,
      onDeactivateOpen,
      onEditOpen,
      onRemoveFromOrgOpen,
      onRemoveFromOrgUnitOpen,
    ],
  );

  return (
    <>
      <ContextMenu<TeamMembersListViewContextMenuItem>
        onAction={onAction}
        items={items}
      />

      <EditUserProfileModal
        isOpen={isEditOpen}
        userId={user.id}
        onClose={onEditClose}
      />

      <DeactivateUserDialog
        userId={user.id}
        onClose={onDeactivateClose}
        isOpen={isDeactivateOpen}
      />

      <ActivateUserDialog
        userId={user.id}
        onClose={onActivateClose}
        isOpen={isActivateOpen}
      />

      <RemoveOrgMemberConfirmationModal
        isOpen={isRemoveFromOrgOpen}
        onClose={onRemoveFromOrgClose}
        onRemove={onRemoveFromOrg}
        userId={user.id}
      />

      {currentOrgUnitMembership && (
        <RemoveOrgUnitMemberConfirmationModal
          isOpen={isRemoveFromOrgUnitOpen}
          onClose={onRemoveFromOrgUnitClose}
          orgUnitId={currentOrgUnitMembership.orgUnit.id}
          userId={user.id}
        />
      )}
    </>
  );
};

export default UserContextMenu;
