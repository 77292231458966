import type { SerializedStyles } from '@emotion/react';
import { css, useTheme } from '@emotion/react';
import { useCallback, type PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import LockIcon from 'user/LockIcon';

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

type Props = PropsWithChildren<{
  className?: string;
  color?: string;
  decoration?: boolean;
  decorationOnHover?: boolean;
  inactiveColor?: string;
  isActive?: boolean;
  isInline?: boolean;
  lockIconStyle?: SerializedStyles;
  onPress?: () => void;
  to?: string;
}>;

const LockedLink = ({
  to,
  onPress,
  isActive = true,
  isInline = false,
  decoration = false,
  decorationOnHover = true,
  color,
  inactiveColor,
  lockIconStyle,
  className,
  children,
}: Props) => {
  const theme = useTheme();

  const resolvedColor = color || theme.color.black;
  const resolvedInactiveColor = inactiveColor || resolvedColor;
  const isLocked = !isActive;

  const containerStyle = isInline
    ? css`
        display: inline;
      `
    : css`
        display: flex;
        align-items: center;
        gap: 8px;
      `;

  const lockContainerStyle = isInline
    ? css`
        display: inline;
        margin-left: 2px;

        button {
          display: inline;
          position: relative;

          svg {
            position: absolute;
            top: 2px;
          }
        }
      `
    : undefined;

  const linkStyle = css`
    color: ${isActive ? resolvedColor : resolvedInactiveColor};
    pointer-events: ${isLocked ? 'none' : 'all'};
    text-decoration: ${!isLocked && decoration ? 'underline' : 'none'};

    &:hover {
      cursor: ${isLocked ? 'inherit' : 'pointer'};
      text-decoration: ${!isLocked && decorationOnHover ? 'underline' : 'none'};
    }
  `;

  const handleOnPress = useCallback(
    (event: React.MouseEvent) => {
      if (!onPress || isLocked) return;

      stopPropagation(event);
      onPress();
    },
    [isLocked, onPress],
  );

  return (
    <div css={containerStyle}>
      {to ? (
        <Link
          to={to}
          className={className}
          aria-disabled={isLocked}
          onClick={stopPropagation}
          css={linkStyle}
        >
          {children}
        </Link>
      ) : (
        <span css={linkStyle} onClick={handleOnPress}>
          {children}
        </span>
      )}

      {isLocked && (
        <div css={[lockContainerStyle, lockIconStyle]}>
          <LockIcon />
        </div>
      )}
    </div>
  );
};

export default LockedLink;
