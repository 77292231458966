import cn from 'classnames';

import styles from './LinkTabs.module.scss';
import type { LinkTabItem } from './LinkTabs.type';
import LinkTab from './LinkTab/LinkTab';

export type LinkTabsProps = {
  className?: string;
  items: Array<LinkTabItem>;
};

const LinkTabs = ({ items, className }: LinkTabsProps) => (
  <nav className={cn(styles.container, className)}>
    <ul className={styles.list}>
      {items.map((item, index) => (
        <li className={styles.listItem} key={item.name + index}>
          <LinkTab item={item} />
        </li>
      ))}
    </ul>
  </nav>
);

export default LinkTabs;
