import type { ObjectivesByTheme } from 'objective/objective.utils';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';
import type { StatusFilterValue } from 'shared/filters/StatusFilter';
import { testStatusIndicatorAgainstIds } from 'shared/filters/StatusFilter';
import {
  testPriorityFilters,
  type PriorityFilterValue,
} from 'shared/filters/PriorityFilter';

import type {
  TeamInsightsOverviewTheme,
  TeamInsightsOverviewObjective,
} from './TeamInsightsOverviewProvider.type';

export const filterObjectivesByTheme = (
  objectives: TeamInsightsOverviewObjective[],
  themeIds: string[],
) => {
  if (themeIds.length === 0) {
    return objectives;
  }

  return objectives.filter((objective) =>
    themeIds.some((themeId) => objective.theme?.id === themeId),
  );
};

export const filterObjectivesByStatus = (
  objectives: TeamInsightsOverviewObjective[],
  statuses: StatusFilterValue[],
) =>
  objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        testStatusIndicatorAgainstIds(
          statuses,
          objective.currentObjectiveStatus?.statusIndicator,
        ),
      initiatives: objective.initiatives.filter((initiative) =>
        testStatusIndicatorAgainstIds(
          statuses,
          initiative.currentInitiativeStatus?.statusIndicator,
        ),
      ),
      metrics: objective.metrics.filter((metric) =>
        testStatusIndicatorAgainstIds(
          statuses,
          metric.currentMetricStatus?.statusIndicator,
        ),
      ),
      actions: objective.actions.filter((action) =>
        testStatusIndicatorAgainstIds(
          statuses,
          action.currentStatus?.statusIndicator,
        ),
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );

export const filterObjectivesByCompleted = (
  objectives: TeamInsightsOverviewObjective[],
  showCompleted: boolean,
) =>
  objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        (showCompleted || !objective.currentObjectiveStatus?.complete),
      initiatives: objective.initiatives.filter(
        (initiative) =>
          showCompleted || !initiative.currentInitiativeStatus?.complete,
      ),
      metrics: objective.metrics.filter(
        (metric) => showCompleted || !metric.currentMetricStatus?.complete,
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );

export const filterObjectivesByPriority = (
  objectives: TeamInsightsOverviewObjective[],
  priorities: PriorityFilterValue[],
) =>
  objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        testPriorityFilters(priorities, objective.priority),
      metrics: objective.metrics.filter((metric) =>
        testPriorityFilters(priorities, metric.priority),
      ),
      initiatives: objective.initiatives.filter((initiative) =>
        testPriorityFilters(priorities, initiative.priority),
      ),
      actions: objective.actions.filter((action) =>
        testPriorityFilters(priorities, action.priority),
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );

export const filterObjectivesByDates = (
  objectives: TeamInsightsOverviewObjective[],
  startDate: Maybe<Date>,
  endDate: Maybe<Date>,
  check?: CalendarCheck,
) => {
  const interval = { start: startDate, end: endDate };

  return objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        testStrategyItemAgainstInterval(objective, interval, check),
      initiatives: objective.initiatives.filter((initiative) =>
        testStrategyItemAgainstInterval(initiative, interval, check),
      ),
      metrics: objective.metrics.filter((metric) =>
        testStrategyItemAgainstInterval(metric, interval, check),
      ),
      actions: objective.actions.filter((action) => {
        testStrategyItemAgainstInterval(action, interval, check);
      }),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );
};

export const filterObjectivesByUser = (
  objectives: TeamInsightsOverviewObjective[],
  userIds: string[],
) => {
  if (userIds.length === 0) {
    return objectives;
  }

  return objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        userIds.some((value) => value === objective.owner?.id),
      initiatives: objective.initiatives.filter((initiative) =>
        userIds.some((value) => value === initiative.owner?.id),
      ),
      metrics: objective.metrics.filter((metric) =>
        userIds.some((value) => value === metric.owner?.id),
      ),
      actions: objective.actions.filter((action) =>
        userIds.some((value) => value === action.owner?.id),
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );
};

export const filterObjectivesByCollaboratingTeams = (
  objectives: TeamInsightsOverviewObjective[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const getObjectivesOwners = <
  Objective extends { owner?: User },
  User extends { id: string },
>(
  objectives: Objective[],
): User[] =>
  Object.values(
    objectives.reduce<Record<string, User>>((accumulator, objective) => {
      const user = objective.owner;
      if (user && !accumulator[user.id]) {
        accumulator[user.id] = user;
      }
      return accumulator;
    }, {}),
  );

export const getActiveTheme = (
  objectivesByTheme: ObjectivesByTheme<
    TeamInsightsOverviewObjective,
    TeamInsightsOverviewTheme
  >[],
  themeIds: string[],
): Maybe<TeamInsightsOverviewTheme> => {
  const activeThemeFromFilter = themeIds.at(0);
  if (activeThemeFromFilter) {
    return objectivesByTheme.find(
      ({ theme }) => theme?.id === activeThemeFromFilter,
    )?.theme;
  }
  return undefined;
};

export const getActiveThemeObjectives = (
  objectivesByTheme: ObjectivesByTheme<
    TeamInsightsOverviewObjective,
    TeamInsightsOverviewTheme
  >[],
  themeIds: string[],
): TeamInsightsOverviewObjective[] => {
  const activeThemeFromFilter = themeIds.at(0);
  if (activeThemeFromFilter) {
    return (
      objectivesByTheme.find(({ theme }) => theme?.id === activeThemeFromFilter)
        ?.objectives || []
    );
  }
  return objectivesByTheme.flatMap(({ objectives }) => objectives);
};
