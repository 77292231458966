import { useTranslation } from 'react-i18next';

import type {
  StrategyItemFormProps,
  StrategyItemFormValues,
} from 'strategy/StrategyItemForm';
import StrategyItemForm from 'strategy/StrategyItemForm';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import InfoCard from 'shared/components/InfoCard';
import Sidesheets from 'shared/sidesheets';

export type StrategyWizardMissionValues = StrategyItemFormValues;

type StrategyWizardMissionProps = Pick<
  StrategyItemFormProps,
  'id' | 'className' | 'initialValues' | 'onChange'
>;

const StrategyWizardMission = (props: StrategyWizardMissionProps) => {
  const { t } = useTranslation();

  const renderForm = () => (
    <>
      <Heading level={4} as={5}>
        {t('whatIs', {
          term: t('strategy.mission_specific'),
        })}
      </Heading>
      <StrategyItemForm {...props} />
    </>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('strategy.strategyWizard.steps.mission.infoCard.heading')}
      contentKey={'strategy.strategyWizard.steps.mission.infoCard.content'}
      sideSheetTitle={t('sidesheets.context.strategy.heading')}
      orgTermKey={'strategy.mission'}
    >
      <Sidesheets context={'strategy'} wizardStep={'mission'} />
    </InfoCard>
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
    />
  );
};

export default StrategyWizardMission;
