import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { uniqBy } from 'lodash';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import { useToasts } from 'shared/toast/useToasts';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import useHandleError from 'shared/errors/useHandleError';
import { useUpdateObjectiveMutation } from 'objective/EditObjective/EditObjective.graphql.generated';
import Select from 'shared/components/Select';
import Dialog from 'shared/components/Dialog';

import { useObjectiveSelection } from './ObjectiveSelctionProvider';
import type { StrategyRoomManageObjectivesThemeFragment } from './StrategyRoomThemesManageObjectives.graphql.generated';
import { StrategyRoomThemesManageObjectivesDocument } from './StrategyRoomThemesManageObjectives.graphql.generated';

type ThemeOption = {
  id: string;
  title: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  themes: StrategyRoomManageObjectivesThemeFragment[];
};

const ChangeThemeModal = ({ themes, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { activeOrg } = useActiveOrg();

  const { selectedObjectives, clearSelectedObjectives } =
    useObjectiveSelection();

  const onError = useHandleError();

  const [updateObjective] = useUpdateObjectiveMutation({
    onError,
    refetchQueries: [StrategyRoomThemesManageObjectivesDocument],
  });

  const [selectedTheme, setSelectedTheme] = useState<Maybe<ThemeOption>>();

  const updateObjectives = async () => {
    const result = await Promise.all(
      selectedObjectives.map((objective) =>
        updateObjective({
          variables: {
            input: {
              idToUpdate: objective.id,
              theme: {
                ...(selectedTheme
                  ? { idToSet: selectedTheme.id }
                  : { idToRemove: objective.theme?.id }),
              },
            },
          },
        }),
      ),
    );

    const isSuccess = result.every((result) => !result.errors);

    if (isSuccess) {
      addToast({
        children: t('strategy.strategyRoom.themes.changeTheme.toast.success', {
          count: selectedObjectives.length,
        }),
        variant: 'success',
      });
      clearSelectedObjectives();
    } else {
      addToast({
        children: t('strategy.strategyRoom.themes.changeTheme.toast.error', {
          count: selectedObjectives.length,
        }),
        variant: 'error',
      });
    }

    onClose();
  };

  const activeThemes = themes.filter((theme) => theme.isActive);

  const orgName = activeOrg.displayName || activeOrg.domainNames[0];

  const groups = useMemo(
    () =>
      uniqBy(
        activeThemes.map((theme) => theme.orgUnit),
        'id',
      ).map((orgUnit) => ({
        id: orgUnit?.id || activeOrg.id,
        title: orgUnit?.name || orgName,
      })),
    [activeOrg.id, activeThemes, orgName],
  );

  const options = useMemo(
    () =>
      activeThemes.map((theme) => ({
        id: theme.id,
        title: theme.name || '',
        groupId: theme.orgUnit?.id || activeOrg.id,
      })),
    [activeOrg.id, activeThemes],
  );

  return (
    <Dialog
      headerTitle={t('strategy.strategyRoom.themes.changeTheme.heading')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('confirm')}
      cancelLabel={t('cancel')}
      onConfirm={updateObjectives}
      size={'small'}
    >
      <Space direction={'vertical'}>
        <Space>
          <Text variant={'emphasis'}>
            {t('strategy.strategyRoom.themes.changeTheme.content', {
              count: selectedObjectives.length,
            })}
          </Text>
        </Space>
        <Space>
          <Select<ThemeOption>
            fieldLabel={t('theme.theme')}
            options={options}
            groups={groups}
            value={selectedTheme}
            onChange={setSelectedTheme}
            triggerStyle={css({ minWidth: 200 })}
            emptyOptionLabel={t(
              'strategy.strategyRoom.themes.changeTheme.noTheme',
            )}
          />
        </Space>
      </Space>
    </Dialog>
  );
};

export default ChangeThemeModal;
