import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import ActionCard from 'actions/ActionsGrid/ActionsColumn/ActionCard';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import {
  filterActionsByUser,
  filterActionsByDates,
  sortActions,
  filterActionsByPriority,
  filterActionsByCollaboratingTeams,
  filterActionsByTheme,
} from 'actions/action.utils';
import Heading from 'shared/components/Heading';
import Button from 'shared/components/Button';
import { useCalendarControls } from 'shared/filters/CalendarControls';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';
import { useUserFilter } from 'shared/filters/UserFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import type { ActionPartsFragment } from 'actions/actions.fragment.graphql.generated';
import { useThemeFilter } from 'shared/filters/ThemeFilter';

import styles from './ActionsColumn.module.scss';

type ActionsColumnProps = {
  actions: Maybe<ActionPartsFragment[]>;
  title: 'inactive' | 'active' | 'done';
};

const ActionsColumn = ({ title = 'inactive', actions }: ActionsColumnProps) => {
  const { t } = useTranslation();

  const { orgUnit } = useOrgUnit();

  const { priorities } = usePriorityFilter();
  const { selectedUserIds } = useUserFilter();
  const { selectedThemeIds } = useThemeFilter();
  const { startDate, endDate } = useTimelineFilter();

  const { actionsSort } = useActionsLayout();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const initialCardLimit = 5;
  const [cardLimit, setCardLimit] = useState(initialCardLimit);

  const filteredActions = useMemo(
    () =>
      filterActionsByCollaboratingTeams(
        filterActionsByDates(
          filterActionsByDates(
            filterActionsByPriority(
              filterActionsByTheme(
                filterActionsByUser(actions, selectedUserIds),
                selectedThemeIds,
              ),
              priorities,
            ),
            startDate,
            endDate,
          ),
          calendarIntervalStartDate,
          calendarIntervalEndDate,
          calendarCheck,
        ),
        showCollaboratingTeams,
        orgUnit?.id,
      ),
    [
      actions,
      calendarCheck,
      calendarIntervalEndDate,
      calendarIntervalStartDate,
      endDate,
      orgUnit?.id,
      priorities,
      selectedThemeIds,
      selectedUserIds,
      showCollaboratingTeams,
      startDate,
    ],
  );

  const sortedActions = useMemo(
    () => sortActions(filteredActions, actionsSort),
    [filteredActions, actionsSort],
  );

  const isShowMoreButtonDisplayed =
    sortedActions?.length && sortedActions.length > cardLimit;

  const isShowLessButtonDisplayed =
    sortedActions?.length &&
    cardLimit === sortedActions.length &&
    cardLimit !== initialCardLimit;

  return (
    <motion.div className={styles.column}>
      <header className={styles.columnHeader}>
        <Heading level={5} className={styles.heading}>
          {`${t(`actions.columns.title.${title}.name`)} `}
          <span className={styles.headingNrOfActions}>
            {filteredActions?.length}
          </span>
        </Heading>

        <div
          className={cn(styles.columnHeaderBorder, {
            [styles.columnHeaderBorderDone]: title === 'done',
            [styles.columnHeaderBorderActive]: title === 'active',
            [styles.columnHeaderBorderInactive]: title === 'inactive',
          })}
        />
      </header>

      {sortedActions?.length ? (
        sortedActions
          .slice(0, cardLimit)
          .map((action) => <ActionCard key={action.id} action={action} />)
      ) : (
        <div className={styles.empty}>{t('actions.columns.empty')}</div>
      )}

      {isShowMoreButtonDisplayed ? (
        <Button
          variant={'outlined'}
          className={styles.moreCardsButton}
          onPress={() => setCardLimit(sortedActions.length)}
        >
          <Trans
            i18nKey={'actions.buttons.showMoreCards'}
            values={{
              numberOfCards: sortedActions.length - cardLimit,
            }}
          />
        </Button>
      ) : null}

      {isShowLessButtonDisplayed ? (
        <Button
          variant={'outlined'}
          className={styles.moreCardsButton}
          onPress={() => setCardLimit(initialCardLimit)}
        >
          {t('actions.buttons.showLessCards')}
        </Button>
      ) : null}
    </motion.div>
  );
};

export default ActionsColumn;
