import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Key } from 'react-aria-components';
import { last, without } from 'lodash';

import { getStatusLabel } from './StatusFilter.utils';
import { useStatusFilter } from './useStatusFilter';
import type { StatusFilterValue } from './StatusFilter.context';
import FilterMultiSelect from '../FilterMultiSelect';

export type StatusFilterSelectItemId = StatusFilterValue | 'ALL';

export type StatusFilterSelectItem = { id: StatusFilterSelectItemId };

const itemIds = [
  'ALL',
  'UNKNOWN',
  'AT_RISK',
  'BLOCKED',
  'ON_TRACK',
] satisfies StatusFilterSelectItemId[];

const StatusFilter = () => {
  const { t } = useTranslation();

  const { statuses, setStatuses, clearStatuses } = useStatusFilter();

  const items = useMemo(
    () => itemIds.map((id) => ({ id, title: getStatusLabel(t, id) })),
    [t],
  );

  const selectedKeys = useMemo(
    () => (statuses.length === 0 ? ['ALL'] : statuses),
    [statuses],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Key[]) =>
      last(selectedKeys) === 'ALL'
        ? clearStatuses()
        : setStatuses(without([...selectedKeys], 'ALL') as StatusFilterValue[]),
    [clearStatuses, setStatuses],
  );

  const selectionLabel = selectedKeys.includes('ALL')
    ? t('status.allStatus')
    : selectedKeys.length === 0
    ? t('none')
    : t('status.statusMultiSelect.statusCount', { count: selectedKeys.length });

  return (
    <FilterMultiSelect
      items={items}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      fieldLabel={t('status.statusMultiSelect.label')}
      selectionLabel={selectionLabel}
    />
  );
};

export default StatusFilter;
