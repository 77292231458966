import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListBoxItem, type Key, type Selection } from 'react-aria-components';
import { Button, ListBox } from 'react-aria-components';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import useDialogState from 'shared/hooks/useDialogState';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import Checkbox from 'shared/components/Checkbox';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.color.strokeMedium};

  padding: 4px 0;

  [role='option'] {
    &:focus-visible {
      outline: none;
    }
  }

  [aria-selected='true'] {
    [data-checked='false'] {
      display: none;
    }
  }

  [aria-selected='false'] {
    [data-checked='true'] {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  padding: 10px 8px;

  &:hover {
    background-color: ${(props) => props.theme.color.hoverLight};
    border-radius: 8px;
  }

  svg {
    height: 12px;
    width: 12px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding: 4px 8px;
  border-radius: 8px;

  &:hover {
    background-color: ${(props) => props.theme.color.hoverLight};
  }
`;

export type FilterMultiSelectOption = {
  id: Key;
  title?: string;
};

type Props<T extends FilterMultiSelectOption> = {
  fieldLabel: string;
  items: T[];
  onSelectionChange: (keys: Key[]) => void;
  renderItem?: (item: T) => ReactNode;
  selectedKeys: Key[];
  selectionLabel: string;
};

const FilterMultiSelect = <T extends FilterMultiSelectOption>({
  items,
  selectedKeys,
  onSelectionChange,
  fieldLabel,
  selectionLabel,
  renderItem,
}: Props<T>) => {
  const { isOpen, onToggle } = useDialogState();

  const wrappedOnSelectionChange = useCallback(
    (selection: Selection) => {
      if (selection === 'all') {
        onSelectionChange([]);
      } else {
        onSelectionChange([...selection]);
      }
    },
    [onSelectionChange],
  );

  return (
    <Container>
      <StyledButton onPress={onToggle}>
        <Flex gap={4}>
          <Text color={'regular'}>{fieldLabel}:</Text>
          {selectionLabel}
        </Flex>

        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </StyledButton>

      <ListBox
        aria-label={fieldLabel}
        onSelectionChange={wrappedOnSelectionChange}
        items={items}
        css={[
          !isOpen && css({ display: 'none', marginBottom: 8 }),
          css({ marginBottom: 8 }),
        ]}
        selectionMode={'multiple'}
        selectedKeys={selectedKeys}
      >
        {(item: T) => (
          <ListBoxItem>
            <ItemContainer>
              <Checkbox checked={true} />
              <Checkbox checked={false} />
              {renderItem ? renderItem(item) : item.title}
            </ItemContainer>
          </ListBoxItem>
        )}
      </ListBox>
    </Container>
  );
};

export default FilterMultiSelect;
