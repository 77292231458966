import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AiWandIcon } from 'shared/static/icons/icon-ai-wand.svg';
import Button from 'shared/components/Button';
import AiLoadingButton from 'ai/AiLoadingButton';
import SophieAvatar from 'ai/SophieAvatar/SophieAvatar';
import { getAiSuggestionField } from 'ai/getAiSuggestionField';
import useTemperature from 'ai/useTemperature';
import useDebouncedValue from 'shared/hooks/useDebouncedValue';
import SideCard from 'shared/components/SideCard';

import type { SuggestionAction } from './SuggestedItem';
import SuggestedItem from './SuggestedItem';
import { useSuggestions } from './SuggestionsProvider';

const WandContainer = styled.div`
  position: absolute;
  right: -8px;
  bottom: -8px;
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.color.white};
`;

const SuggestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  buttonLabel: string;
  buttonLabelAfterFirstTrigger: string;
  suggestionAction: SuggestionAction;
  title: string;
  titleFieldName: string;
};

const AiSuggestions = ({
  title,
  buttonLabel,
  buttonLabelAfterFirstTrigger,
  suggestionAction,
  titleFieldName,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    isTriggered,
    suggestions,
    fetchSuggestions,
    stopSuggestions,
    isAiAssistantActive,
    variables,
    hasVariables,
    isComplete,
  } = useSuggestions();

  const debouncedIsTriggered = useDebouncedValue(250, isTriggered);

  const [visibleSuggestionsCount, setVisibleSuggestionsCount] = useState(0);

  const { temperature, increaseTemperature } = useTemperature(1, 1.5, 0.1);

  const maxSuggestions = 20;

  useEffect(() => {
    setVisibleSuggestionsCount(0);
  }, [hasVariables, variables]);

  if (!isAiAssistantActive) {
    return null;
  }

  const nonEmptySuggestions = suggestions?.filter((suggestion) =>
    getAiSuggestionField(suggestion, titleFieldName),
  );

  const visibleSuggestions =
    nonEmptySuggestions?.slice(0, visibleSuggestionsCount) || [];

  const isLoading =
    visibleSuggestions.length < visibleSuggestionsCount && !isComplete;

  const isMaxSuggestionsReached = visibleSuggestionsCount === maxSuggestions;

  const isShowingAllSuggestions =
    nonEmptySuggestions &&
    visibleSuggestionsCount >= nonEmptySuggestions.length;

  const isButtonDisabled = !hasVariables;

  const showResetButton =
    isMaxSuggestionsReached || (isShowingAllSuggestions && isComplete);

  const buttonContent =
    visibleSuggestionsCount === 0 ? buttonLabel : buttonLabelAfterFirstTrigger;

  const handleFetchSuggestions = () => {
    fetchSuggestions(temperature);
    setVisibleSuggestionsCount(visibleSuggestionsCount + 5);
  };

  const handleResetSuggestions = () => {
    setVisibleSuggestionsCount(0);
    stopSuggestions();
    increaseTemperature();
  };

  return (
    <SideCard
      title={title}
      backgroundColor={theme.legacyColor.colorAiPurpleLightBackground}
      iconBackgroundColor={theme.color.white}
      icon={
        <>
          <SophieAvatar size={'square'} />
          <WandContainer>
            <AiWandIcon />
          </WandContainer>
        </>
      }
    >
      {debouncedIsTriggered && visibleSuggestions.length > 0 && (
        <SuggestionList>
          {visibleSuggestions.map((result, index) => (
            <SuggestedItem
              key={`${index}-${result.typeAsText}`}
              index={index}
              suggestion={result}
              titleFieldName={titleFieldName}
              suggestionAction={suggestionAction}
            />
          ))}
        </SuggestionList>
      )}

      {isLoading ? (
        <AiLoadingButton />
      ) : showResetButton ? (
        <Button
          variant={'outlined'}
          onPress={handleResetSuggestions}
          fullWidth={true}
        >
          {t('ai.suggestions.resetButton')}
        </Button>
      ) : (
        <Button
          variant={'outlined'}
          onPress={handleFetchSuggestions}
          fullWidth={true}
          isDisabled={isButtonDisabled}
        >
          {buttonContent}
        </Button>
      )}
    </SideCard>
  );
};

export default AiSuggestions;
