import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrgForEditQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrgForEditQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgLead?: { __typename: 'UserLimited', id: string, displayName?: string | undefined, email: string } | undefined, industry?: { __typename: 'Industry', name?: string | undefined, description?: string | undefined } | undefined, systemPreferences: { __typename: 'SystemPreferences', authenticationMethods: Array<Types.AuthenticationMethod>, doAutoCreateUserAccounts: boolean, doSendEmails: boolean, scimUserProvisioningEnabled: boolean, showLinksInEmailsEnabled: boolean, userHighlightsEmailEnabled: boolean } } };

export type EditOrgOrgFragment = { __typename: 'Org', id: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgLead?: { __typename: 'UserLimited', id: string, displayName?: string | undefined, email: string } | undefined, industry?: { __typename: 'Industry', name?: string | undefined, description?: string | undefined } | undefined, systemPreferences: { __typename: 'SystemPreferences', authenticationMethods: Array<Types.AuthenticationMethod>, doAutoCreateUserAccounts: boolean, doSendEmails: boolean, scimUserProvisioningEnabled: boolean, showLinksInEmailsEnabled: boolean, userHighlightsEmailEnabled: boolean } };

export const EditOrgOrgFragmentDoc = gql`
    fragment editOrgOrg on Org {
  id
  displayName
  displayNameAbbreviated
  backgroundColorAsHex
  orgLead {
    id
    displayName
    email
  }
  industry {
    name
    description
  }
  systemPreferences {
    authenticationMethods
    doAutoCreateUserAccounts
    doSendEmails
    scimUserProvisioningEnabled
    showLinksInEmailsEnabled
    userHighlightsEmailEnabled
  }
}
    `;
export const GetOrgForEditDocument = gql`
    query GetOrgForEdit {
  activeOrg {
    ...editOrgOrg
  }
}
    ${EditOrgOrgFragmentDoc}`;

/**
 * __useGetOrgForEditQuery__
 *
 * To run a query within a React component, call `useGetOrgForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgForEditQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgForEditQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgForEditQuery, GetOrgForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgForEditQuery, GetOrgForEditQueryVariables>(GetOrgForEditDocument, options);
      }
export function useGetOrgForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgForEditQuery, GetOrgForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgForEditQuery, GetOrgForEditQueryVariables>(GetOrgForEditDocument, options);
        }
export function useGetOrgForEditSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrgForEditQuery, GetOrgForEditQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrgForEditQuery, GetOrgForEditQueryVariables>(GetOrgForEditDocument, options);
        }
export type GetOrgForEditQueryHookResult = ReturnType<typeof useGetOrgForEditQuery>;
export type GetOrgForEditLazyQueryHookResult = ReturnType<typeof useGetOrgForEditLazyQuery>;
export type GetOrgForEditSuspenseQueryHookResult = ReturnType<typeof useGetOrgForEditSuspenseQuery>;
export type GetOrgForEditQueryResult = Apollo.QueryResult<GetOrgForEditQuery, GetOrgForEditQueryVariables>;