import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { User } from 'types.graphql.generated';
import { getNodesFromConnection } from 'shared/graphql/utils';
import useHandleError from 'shared/errors/useHandleError';

import type { UserEmailInputItem } from '../UserEmailInput.type';
import {
  getUserEmailInputItems,
  sortUsersAlphabetically,
} from './UserEmailInputProvider.utils';
import type { UserEmailInputProviderContextValue } from './UserEmailInputProvider.context';
import { UserEmailInputProviderContext } from './UserEmailInputProvider.context';
import { useUserEmailInputQuery } from './UserEmailInputProvider.graphql.generated';

export type UserEmailInputProviderProps = PropsWithChildren<{
  usersToExclude?: Array<Pick<User, 'id'>>;
}>;

const UserEmailInputProvider = ({
  children,
  usersToExclude,
}: UserEmailInputProviderProps) => {
  const onError = useHandleError();

  const { data: orgEmployeesData } = useUserEmailInputQuery({
    onError,
  });

  const userEmailInputItems = useMemo<UserEmailInputItem[]>(() => {
    return getUserEmailInputItems({
      usersToExclude,
      users: sortUsersAlphabetically(
        orgEmployeesData
          ? getNodesFromConnection(orgEmployeesData.activeOrg.employees)
          : [],
      ),
    });
  }, [orgEmployeesData, usersToExclude]);

  const contextValue = useMemo<UserEmailInputProviderContextValue>(
    () => ({
      items: userEmailInputItems,
    }),
    [userEmailInputItems],
  );

  return (
    <UserEmailInputProviderContext.Provider value={contextValue}>
      {children}
    </UserEmailInputProviderContext.Provider>
  );
};

export default UserEmailInputProvider;
