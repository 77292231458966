import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as ViewSettingsIcon } from 'shared/static/icons/icon-view-settings.svg';
import useDialogState from 'shared/hooks/useDialogState';
import SideModal from 'shared/components/SideModal';
import Button from 'shared/components/Button';
import Text from 'shared/components/Text';
import Switch from 'shared/components/Switch';
import Flex from 'shared/components/Flex';
import Checkbox from 'shared/components/CheckboxV2';

import { useStrategyBoardViewSettings } from './useStrategyBoardViewSettings';

const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
`;

const SubField = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 16px 12px;
  cursor: pointer;
  user-select: none;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.strokeLight};
`;

const StrategyBoardViewSettingsButton = () => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const {
    isUserVisible,
    isOrgUnitVisible,
    isMetricsVisible,
    isMetricScoreVisible,
    isInitiativesVisible,
    isInitiativeMilestonesVisible,
    isActionsVisible,
    isActionProgressVisible,
    toggleUserVisible,
    toggleOrgUnitVisible,
    toggleMetricsVisible,
    toggleMetricScoreVisible,
    toggleInitiativesVisible,
    toggleActionsVisible,
    toggleActionProgressVisible,
    toggleInitiativeMilestonesVisible,
  } = useStrategyBoardViewSettings();

  return (
    <>
      <div css={css({ position: 'relative' })}>
        <Button
          icon={ViewSettingsIcon}
          variant={'icon'}
          css={css({ width: 42, height: 42, boxSizing: 'border-box' })}
          onPress={onOpen}
        />
      </div>

      <SideModal
        headerTitle={t('viewSettings.title')}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Flex
          direction={'column'}
          gap={24}
          css={css({ margin: '24px 20px 0 20px' })}
        >
          <Flex direction={'column'} gap={10}>
            <Text>{t('viewSettings.showOwnership')}</Text>
            <div>
              <Field onClick={toggleUserVisible}>
                <Text color={'black'}>{t('user.user')}</Text>
                <Switch value={isUserVisible} onToggle={toggleUserVisible} />
              </Field>
              <Separator />
              <Field onClick={toggleOrgUnitVisible}>
                <Text color={'black'}>{t('team.team')}</Text>
                <Switch
                  value={isOrgUnitVisible}
                  onToggle={toggleOrgUnitVisible}
                />
              </Field>
              <Separator />
            </div>
          </Flex>

          <Flex direction={'column'} gap={10}>
            <Text>{t('viewSettings.showStrategyElements')}</Text>
            <div>
              <Field onClick={toggleMetricsVisible}>
                <Text color={'black'}>{t('metric.metric_other')}</Text>
                <Switch
                  value={isMetricsVisible}
                  onToggle={toggleMetricsVisible}
                />
              </Field>
              <SubField onClick={toggleMetricScoreVisible}>
                <Checkbox
                  value={isMetricScoreVisible}
                  onChange={toggleMetricScoreVisible}
                />
                <Text color={'black'}>{t('viewSettings.score')}</Text>
              </SubField>
              <Separator />
              <Field onClick={toggleInitiativesVisible}>
                <Text color={'black'}>{t('initiative.initiative_other')}</Text>
                <Switch
                  value={isInitiativesVisible}
                  onToggle={toggleInitiativesVisible}
                />
              </Field>
              <SubField onClick={toggleInitiativeMilestonesVisible}>
                <Checkbox
                  value={isInitiativeMilestonesVisible}
                  onChange={toggleInitiativeMilestonesVisible}
                />
                <Text color={'black'}>
                  {t('viewSettings.initiativeMilestones')}
                </Text>
              </SubField>
              <Separator />
              <Field onClick={toggleActionsVisible}>
                <Text color={'black'}>{t('actions.action_other')}</Text>
                <Switch
                  value={isActionsVisible}
                  onToggle={toggleActionsVisible}
                />
              </Field>
              <SubField onClick={toggleActionProgressVisible}>
                <Checkbox
                  value={isActionProgressVisible}
                  onChange={toggleActionProgressVisible}
                />
                <Text color={'black'}>{t('viewSettings.actionProgress')}</Text>
              </SubField>
              <Separator />
            </div>
          </Flex>
        </Flex>
      </SideModal>
    </>
  );
};

export default StrategyBoardViewSettingsButton;
