import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditUserProfileModalQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type EditUserProfileModalQuery = { __typename: 'Query', user: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, authorities: Array<Types.Authority>, primaryOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined } };


export const EditUserProfileModalDocument = gql`
    query EditUserProfileModal($userId: ID!) {
  user(id: $userId) {
    id
    email
    displayName
    photoUrl
    authorities
    primaryOrgUnit {
      id
      name
    }
  }
}
    `;

/**
 * __useEditUserProfileModalQuery__
 *
 * To run a query within a React component, call `useEditUserProfileModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditUserProfileModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditUserProfileModalQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEditUserProfileModalQuery(baseOptions: Apollo.QueryHookOptions<EditUserProfileModalQuery, EditUserProfileModalQueryVariables> & ({ variables: EditUserProfileModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditUserProfileModalQuery, EditUserProfileModalQueryVariables>(EditUserProfileModalDocument, options);
      }
export function useEditUserProfileModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditUserProfileModalQuery, EditUserProfileModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditUserProfileModalQuery, EditUserProfileModalQueryVariables>(EditUserProfileModalDocument, options);
        }
export function useEditUserProfileModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EditUserProfileModalQuery, EditUserProfileModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EditUserProfileModalQuery, EditUserProfileModalQueryVariables>(EditUserProfileModalDocument, options);
        }
export type EditUserProfileModalQueryHookResult = ReturnType<typeof useEditUserProfileModalQuery>;
export type EditUserProfileModalLazyQueryHookResult = ReturnType<typeof useEditUserProfileModalLazyQuery>;
export type EditUserProfileModalSuspenseQueryHookResult = ReturnType<typeof useEditUserProfileModalSuspenseQuery>;
export type EditUserProfileModalQueryResult = Apollo.QueryResult<EditUserProfileModalQuery, EditUserProfileModalQueryVariables>;