import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import { TeamTeamHierarchyOrgUnitFragmentDoc } from '../../team/TeamTeamHierarchy/TeamTeamHierarchyProvider/TeamTeamHierarchyProvider.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignResultsQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['ID']['input'];
}>;


export type CampaignResultsQuery = { __typename: 'Query', campaign: { __typename: 'Campaign', id: string, name: string, deadline?: Date | undefined, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, completionRate: { __typename: 'CampaignCompletionRate', countEngaged: number, percentEngaged: number }, timeLine: { __typename: 'TimeLine', startDate?: Date | undefined, endDate?: Date | undefined }, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, themes: { __typename: 'ThemeConnection', totalCount: number } } | undefined, targetOrgUnitTree: Array<{ __typename: 'OrgUnit', orgUnitMembershipsCascadingTotalCount: number, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, questions: Array<{ __typename: 'CampaignQuestion', id: string, questionType: Types.CampaignQuestionType }>, allUserResponses: Array<{ __typename: 'CampaignUserResponse', id: string, user: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, isInActiveOrg: boolean, primaryOrgUnit?: { __typename: 'OrgUnit', id: string, parentOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> } | undefined }, comments: Array<{ __typename: 'CampaignComment', id: string, text: string, auditRecord?: { __typename: 'AuditRecord', createDateTime?: Date | undefined } | undefined }>, answers: Array<{ __typename: 'CampaignAnswer', id: string, rating: Types.LikertScaleRating, question: { __typename: 'CampaignQuestion', id: string, questionType: Types.CampaignQuestionType } }> }> } };

export type CampaignResultsOrgUnitFragment = { __typename: 'OrgUnit', orgUnitMembershipsCascadingTotalCount: number, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export const CampaignResultsOrgUnitFragmentDoc = gql`
    fragment campaignResultsOrgUnit on OrgUnit {
  ...teamTeamHierarchyOrgUnit
  orgUnitMembershipsCascadingTotalCount
}
    ${TeamTeamHierarchyOrgUnitFragmentDoc}`;
export const CampaignResultsDocument = gql`
    query CampaignResults($campaignId: ID!) {
  campaign(id: $campaignId) {
    id
    name
    deadline
    state {
      stage
    }
    completionRate {
      countEngaged
      percentEngaged
    }
    timeLine {
      startDate
      endDate
    }
    orgUnit {
      id
      name
      themes {
        totalCount
      }
    }
    targetOrgUnitTree {
      ...campaignResultsOrgUnit
    }
    questions {
      id
      questionType
    }
    allUserResponses {
      id
      user {
        id
        ...userProfile
        primaryOrgUnit {
          id
          parentOrgUnitTree {
            id
          }
        }
      }
      comments {
        id
        text
        auditRecord {
          createDateTime
        }
      }
      answers {
        id
        rating
        question {
          id
          questionType
        }
      }
    }
  }
}
    ${CampaignResultsOrgUnitFragmentDoc}
${UserProfileFragmentDoc}`;

/**
 * __useCampaignResultsQuery__
 *
 * To run a query within a React component, call `useCampaignResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignResultsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCampaignResultsQuery(baseOptions: Apollo.QueryHookOptions<CampaignResultsQuery, CampaignResultsQueryVariables> & ({ variables: CampaignResultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignResultsQuery, CampaignResultsQueryVariables>(CampaignResultsDocument, options);
      }
export function useCampaignResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignResultsQuery, CampaignResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignResultsQuery, CampaignResultsQueryVariables>(CampaignResultsDocument, options);
        }
export function useCampaignResultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CampaignResultsQuery, CampaignResultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignResultsQuery, CampaignResultsQueryVariables>(CampaignResultsDocument, options);
        }
export type CampaignResultsQueryHookResult = ReturnType<typeof useCampaignResultsQuery>;
export type CampaignResultsLazyQueryHookResult = ReturnType<typeof useCampaignResultsLazyQuery>;
export type CampaignResultsSuspenseQueryHookResult = ReturnType<typeof useCampaignResultsSuspenseQuery>;
export type CampaignResultsQueryResult = Apollo.QueryResult<CampaignResultsQuery, CampaignResultsQueryVariables>;