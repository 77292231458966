import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';
import type { StrategyAssessmentType } from 'types.graphql.generated';
import AssessmentImage from 'assessment/AssessmentImage';

const Card = styled(Flex)`
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.strokeLight};
  cursor: pointer;
  width: 386px;
`;

type Props = {
  onPress: () => void;
  type: StrategyAssessmentType;
};

const AssessmentTypeCard = ({ type, onPress }: Props) => {
  const { t } = useTranslation();

  return (
    <Card direction={'column'} gap={20} onClick={onPress}>
      <AssessmentImage type={type} />
      <Text size={'big'} color={'black'} isBold={true}>
        {t(`assessment.type.${type}.title`)}
      </Text>
      <Text color={'black'}>{t(`assessment.type.${type}.description`)}</Text>
    </Card>
  );
};

export default AssessmentTypeCard;
