import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserOrgUnitsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UserOrgUnitsQuery = { __typename: 'Query', user: { __typename: 'User', id: string, primaryOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined, orgUnitMemberships: { __typename: 'OrgUnitMembershipList', memberships: Array<{ __typename: 'OrgUnitMembership', orgUnit: { __typename: 'OrgUnit', id: string } }> } } };


export const UserOrgUnitsDocument = gql`
    query UserOrgUnits($id: ID!) {
  user(id: $id) {
    id
    primaryOrgUnit {
      id
    }
    orgUnitMemberships {
      memberships {
        orgUnit {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useUserOrgUnitsQuery__
 *
 * To run a query within a React component, call `useUserOrgUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrgUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrgUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserOrgUnitsQuery(baseOptions: Apollo.QueryHookOptions<UserOrgUnitsQuery, UserOrgUnitsQueryVariables> & ({ variables: UserOrgUnitsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrgUnitsQuery, UserOrgUnitsQueryVariables>(UserOrgUnitsDocument, options);
      }
export function useUserOrgUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrgUnitsQuery, UserOrgUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrgUnitsQuery, UserOrgUnitsQueryVariables>(UserOrgUnitsDocument, options);
        }
export function useUserOrgUnitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserOrgUnitsQuery, UserOrgUnitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserOrgUnitsQuery, UserOrgUnitsQueryVariables>(UserOrgUnitsDocument, options);
        }
export type UserOrgUnitsQueryHookResult = ReturnType<typeof useUserOrgUnitsQuery>;
export type UserOrgUnitsLazyQueryHookResult = ReturnType<typeof useUserOrgUnitsLazyQuery>;
export type UserOrgUnitsSuspenseQueryHookResult = ReturnType<typeof useUserOrgUnitsSuspenseQuery>;
export type UserOrgUnitsQueryResult = Apollo.QueryResult<UserOrgUnitsQuery, UserOrgUnitsQueryVariables>;