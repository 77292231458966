import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePreviousDistinct } from 'react-use';

import type {
  Step as StepType,
  InitialStep,
  StepsContextValue,
  Step,
} from './Steps.type';
import { StepsContext } from './Steps.context';
import { createStep } from './Steps.utils';
import StepsItem from './StepsItem';
import StepsNavigation from './StepsNavigation';

export type StepsChildrenProps = StepsContextValue;

type StepsProps = {
  children: ((childrenProps: StepsChildrenProps) => ReactNode) | ReactNode;
  initialSteps: InitialStep[];
  onAllCompleted?: () => void;
  onStepChange?: (newStep: StepType) => void;
  startFromStep?: string;
};

const Steps = ({
  onAllCompleted,
  onStepChange,
  initialSteps,
  startFromStep,
  children,
}: StepsProps) => {
  const [steps, setSteps] = useState<StepType[]>([]);
  const [disabledStepIds, setDisabledStepIds] = useState<StepType['id'][]>([]);
  const [hiddenStepIds, setHiddenStepIds] = useState<StepType['id'][]>([]);

  const activeStep = useMemo<Step | undefined>(
    () => steps.find((step) => step.isActive),
    [steps],
  );

  const [previousStep, setPreviousStep] = useState<Step | undefined>(
    activeStep,
  );

  const getStep = useCallback<StepsContextValue['getStep']>(
    (stepId) => steps.find((step) => step.id === stepId)!,
    [steps],
  );

  const setStep = useCallback<StepsContextValue['setStep']>(
    (stepId, setStepFn) => {
      setSteps((previousSteps) =>
        previousSteps.map((previousStep) =>
          previousStep.id === stepId ? setStepFn(previousStep) : previousStep,
        ),
      );
    },
    [],
  );

  const addStep = useCallback<StepsContextValue['addStep']>((id, data) => {
    setSteps((previousSteps) => [...previousSteps, createStep({ id, data })]);
  }, []);

  const setActiveStep = useCallback<StepsContextValue['setActiveStep']>(
    (stepId) => {
      setPreviousStep(activeStep);
      setSteps((previousSteps) =>
        previousSteps.map((previousStep) => ({
          ...previousStep,
          isActive: previousStep.id === stepId,
        })),
      );
    },
    [activeStep],
  );

  const goToNextStep = useCallback<StepsContextValue['goToNextStep']>(
    (referenceStepId) => {
      const activeStepIndex = steps.findIndex((step) =>
        referenceStepId ? step.id === referenceStepId : step.isActive,
      );
      const newActiveStepIndex =
        activeStepIndex + 1 === steps.length
          ? activeStepIndex
          : activeStepIndex + 1;
      const newActiveStepId = steps[newActiveStepIndex].id;
      setActiveStep(newActiveStepId);
    },
    [setActiveStep, steps],
  );

  const goToPreviousStep = useCallback<
    StepsContextValue['goToPreviousStep']
  >(() => {
    const activeStepIndex = steps.findIndex((step) => step.isActive);
    const newActiveStepIndex =
      activeStepIndex === 0 ? activeStepIndex : activeStepIndex - 1;
    const newActiveStepId = steps[newActiveStepIndex].id;
    setActiveStep(newActiveStepId);
  }, [setActiveStep, steps]);

  const previousActiveStep = usePreviousDistinct(
    activeStep,
    (previousStep, nextStep) => previousStep?.id === nextStep?.id,
  );

  const completeStep = useCallback<StepsContextValue['completeStep']>(
    (stepId) => {
      setStep(stepId, (previousStep) => ({
        ...previousStep,
        isError: false,
        isInProgress: false,
        isCompleted: true,
      }));
    },
    [setStep],
  );

  const resetSteps = useCallback(() => {
    steps.forEach((step) =>
      setStep(step.id, (previousStep) => ({
        ...previousStep,
        isError: false,
        isInProgress: false,
        isCompleted: false,
      })),
    );

    setActiveStep(startFromStep || steps[0]?.id);
  }, [setActiveStep, setStep, startFromStep, steps]);

  const submitSteps = useCallback<StepsContextValue['submitSteps']>(
    (onSuccess, onError) => {
      setTimeout(() => {
        const stepWithError = steps.find((step) => step.isError);
        if (stepWithError) {
          setActiveStep(stepWithError.id);
          onError?.();
        } else {
          onSuccess?.();
        }
      }, 500);
    },
    [steps, setActiveStep],
  );

  useEffect(() => {
    const newSteps = initialSteps
      .filter((step) => !hiddenStepIds.includes(step.id))
      .map((initialStep, initialStepIndex, initialStepsArray) => {
        const isFirst = initialStepIndex === 0;
        const isLast = initialStepIndex === initialStepsArray.length - 1;

        const { id, ...restInitialStep } = initialStep;

        return createStep({
          data: { ...restInitialStep, isFirst, isLast },
          id,
        });
      });

    setSteps(newSteps);
  }, [hiddenStepIds, initialSteps]);

  useEffect(() => {
    if (steps.length && steps.every((step) => !step.isActive)) {
      setSteps((previousSteps) =>
        previousSteps.map((previousStep, previousStepIndex) => ({
          ...previousStep,
          isActive: startFromStep
            ? startFromStep === previousStep.id
            : previousStepIndex === 0,
        })),
      );
    }
  }, [startFromStep, steps]);

  const areAllStepsCompleted = useMemo(
    () => !!steps.length && steps.every((step) => step.isCompleted),
    [steps],
  );

  useEffect(() => {
    if (areAllStepsCompleted) {
      onAllCompleted?.();
    }
  }, [areAllStepsCompleted, onAllCompleted]);

  useEffect(() => {
    if (previousActiveStep) {
      onStepChange?.(previousActiveStep);
    }
  }, [previousActiveStep, onStepChange]);

  const stepsWithFlags = useMemo(
    () =>
      steps.map((step) => ({
        ...step,
        isDisabled: disabledStepIds.includes(step.id),
      })),
    [disabledStepIds, steps],
  );

  useEffect(() => {
    if (activeStep && hiddenStepIds.includes(activeStep.id)) {
      goToNextStep(activeStep.id);
    }
  }, [activeStep, goToNextStep, hiddenStepIds]);

  const stepsContextValue = useMemo<StepsContextValue>(
    () => ({
      setDisabledSteps: setDisabledStepIds,
      setHiddenSteps: setHiddenStepIds,
      addStep,
      steps: stepsWithFlags,
      setStep,
      getStep,
      setActiveStep,
      goToNextStep,
      goToPreviousStep,
      completeStep,
      activeStep,
      previousStep,
      submitSteps,
      resetSteps,
    }),
    [
      activeStep,
      addStep,
      completeStep,
      getStep,
      goToNextStep,
      goToPreviousStep,
      previousStep,
      resetSteps,
      setActiveStep,
      setStep,
      stepsWithFlags,
      submitSteps,
    ],
  );

  return (
    <StepsContext.Provider value={stepsContextValue}>
      {typeof children === 'function' ? children(stepsContextValue) : children}
    </StepsContext.Provider>
  );
};

Steps.Item = StepsItem;
Steps.Navigation = StepsNavigation;

export default Steps;
