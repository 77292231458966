import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-use';
import { useTranslation } from 'react-i18next';
import { isEqual, sortBy } from 'lodash';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { Report, StatusCommentType, User } from 'types.graphql.generated';
import Steps, { useSteps } from 'shared/components/Steps';
import type { StatusCommentsFormValues } from 'shared/status/StatusCommentsForm';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import type { TeamAdapter } from 'team/TeamAdapter';
import InsightsReportWizardProvider from 'report/InsightReportWizard/InsightReportWizardProvider';
import { RequestStatusUpdateFormReportDocument } from 'report/RequestStatusUpdateForm/RequestStatusUpdateForm.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';
import type { SelectableStrategyItemsProps } from 'strategy/SelectableStrategyItems';
import SelectableStrategyItems from 'strategy/SelectableStrategyItems';
import SubmissionProvider from 'shared/providers/SubmissionProvider/SubmissionProvider';
import StrategyProvider, { useStrategy } from 'strategy/StrategyProvider';

import InsightReportWizardControls from './InsightReportWizardControls';
import InsightReportWizardSteps from './InsightReportWizardSteps';
import {
  resolveStatusCommentListInput,
  resolveInitialValues,
} from './InsightReportWizard.graphql.utils';
import { createReportName } from './InsightReportWizard.utils';
import InsightReportWizardStatusUpdate from './InsightReportWizardStatusUpdate';
import type {
  InsightReportWizardActionsProps,
  InsightReportWizardActionsValues,
} from './InsightReportWizardActions';
import Actions from './InsightReportWizardActions';
import type {
  InsightReportWizardChallengesProps,
  InsightReportWizardChallengesValues,
} from './InsightReportWizardChallenges';
import Challenges from './InsightReportWizardChallenges';
import type {
  InsightReportWizardSuccessesProps,
  InsightReportWizardSuccessesValues,
} from './InsightReportWizardSuccesses';
import Successes from './InsightReportWizardSuccesses';
import {
  useInsightReportWizardCreateReportMutation,
  useInsightReportWizardReportQuery,
  useInsightReportWizardUpdateReportMutation,
} from './InsightReportWizard.graphql.generated';
import styles from './InsightReportWizard.module.scss';

export type InsightReportWizardVariant = 'create' | 'edit';

export type InsightReportWizardValues = {
  actions?: InsightReportWizardActionsValues;
  challenges?: InsightReportWizardChallengesValues;
  initiativeIds: string[];
  metricIds: string[];
  objectiveIds: string[];
  successes?: InsightReportWizardSuccessesValues;
};

export type InsightReportWizardProps = Pick<
  ModalProps,
  'onClose' | 'isOpen'
> & {
  onCreateReport?: (report: Pick<Report, 'id'>) => void;
  onPublishReport?: (report: Pick<Report, 'id'>) => void;
  owner?: Pick<User, 'id'>;
  reportId?: Report['id'];
  teamAdapter: TeamAdapter;
};

const InsightReportWizard = ({
  isOpen,
  onClose,
  teamAdapter,
  owner,
  onPublishReport,
  onCreateReport,
  reportId: propReportId,
}: InsightReportWizardProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { goToNextStep, completeStep, setStep, steps, setActiveStep } =
    useSteps();

  const hasStepsInProgress = steps.some((step) => step.isInProgress);

  const [values, { set: setValues, setAll: setAllValues }] =
    useMap<InsightReportWizardValues>({
      objectiveIds: [],
      metricIds: [],
      initiativeIds: [],
    });

  const [reportId, setReportId] = useState<Report['id'] | undefined>(
    propReportId,
  );

  const onError = useHandleError();

  const { data: reportData } = useInsightReportWizardReportQuery({
    skip: !reportId,
    variables: { id: reportId || '' },
    onError,
  });

  const [createReport] = useInsightReportWizardCreateReportMutation({
    onError,
  });

  const [updateReport] = useInsightReportWizardUpdateReportMutation({
    onError,
    refetchQueries: [
      {
        query: RequestStatusUpdateFormReportDocument,
        variables: { id: reportId },
      },
    ],
  });

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] =
    useState(false);

  const variant: InsightReportWizardVariant = reportData ? 'edit' : 'create';

  const initialValues = useMemo(
    () => (reportData ? resolveInitialValues(reportData.report) : undefined),
    [reportData],
  );

  useEffect(() => {
    if (initialValues) {
      setAllValues(initialValues);
    }
  }, [initialValues, setAllValues]);

  const [initialStepInitialized, setInitialStepInitialized] = useState(false);

  useEffect(() => {
    if (initialStepInitialized) return;

    if (reportId) {
      if (!reportData) return;

      if (reportData.report.reportStage === 'STATUS_REQUESTED') {
        setTimeout(() => {
          setActiveStep('statusUpdate');
        }, 0);
      }
    }

    setInitialStepInitialized(true);
  }, [initialStepInitialized, reportData, reportId, setActiveStep]);

  const [{ metricsCount, objectivesCount, initiativesCount }, setCount] =
    useState<{
      initiativesCount: number;
      metricsCount: number;
      objectivesCount: number;
    }>({
      initiativesCount: 0,
      metricsCount: 0,
      objectivesCount: 0,
    });

  const handleChangeSelectedCount = useCallback<
    NonNullable<SelectableStrategyItemsProps['onChangeSelectedCount']>
  >((params) => {
    setCount(params);
  }, []);

  const handleUpdateStatusComments = useCallback(
    (params: {
      onCompleted: () => void;
      type: StatusCommentType;
      values: StatusCommentsFormValues;
    }) => {
      const { values, type, onCompleted } = params;

      if (reportData) {
        const previousComments =
          reportData.report.reportStatus.statusComments.filter(
            (statusComment) => statusComment.type === type,
          );

        updateReport({
          onCompleted,
          variables: {
            input: {
              idToUpdate: reportId,
              reportStatus: {
                statusComments: resolveStatusCommentListInput({
                  newComments: values,
                  previousComments: previousComments,
                  type,
                }),
              },
            },
          },
        });
      }
    },
    [updateReport, reportData, reportId],
  );

  const handleChangeSuccesses = useCallback<
    NonNullable<InsightReportWizardSuccessesProps['onChange']>
  >(
    (values) => {
      setValues('successes', values);
      setStep('successes', (previousStep) => ({
        ...previousStep,
        isCompleted: false,
        isInProgress: true,
      }));
    },
    [setValues, setStep],
  );

  const handleChangeChallenges = useCallback<
    NonNullable<InsightReportWizardChallengesProps['onChange']>
  >(
    (values) => {
      setValues('challenges', values);
      setStep('challenges', (previousStep) => ({
        ...previousStep,
        isCompleted: false,
        isInProgress: true,
      }));
    },
    [setValues, setStep],
  );

  const handleChangeActions = useCallback<
    NonNullable<InsightReportWizardActionsProps['onChange']>
  >(
    (values) => {
      setValues('actions', values);
      setStep('actions', (previousStep) => ({
        ...previousStep,
        isCompleted: false,
        isInProgress: true,
      }));
    },
    [setValues, setStep],
  );

  const handleObjectivesChange = useCallback<
    NonNullable<SelectableStrategyItemsProps['onObjectiveChange']>
  >((newValues) => setValues('objectiveIds', newValues), [setValues]);

  const handleMetricsChange = useCallback<
    NonNullable<SelectableStrategyItemsProps['onMetricChange']>
  >((newValues) => setValues('metricIds', newValues), [setValues]);

  const handleInitiativesChange = useCallback<
    NonNullable<SelectableStrategyItemsProps['onInitiativeChange']>
  >((newValues) => setValues('initiativeIds', newValues), [setValues]);

  const modalHeading =
    variant === 'edit'
      ? t('report.editInsightReport')
      : t('report.createInsightReport');

  const { strategy } = useStrategy();

  const modalHeadingDescription = useMemo(() => {
    if (variant === 'edit' && reportData) {
      return reportData?.report.name;
    }
    return createReportName({
      strategy,
      creationDate: new Date(),
    });
  }, [variant, reportData, strategy]);

  return (
    <>
      <Modal
        size={'full'}
        isOpen={isOpen}
        onClose={() =>
          hasStepsInProgress ? setIsConfirmationModalOpened(true) : onClose()
        }
        heading={modalHeading}
        headingDescription={modalHeadingDescription}
        scrollType={'outer'}
        renderHeaderContent={() => (
          <Steps.Navigation className={styles.stepsNavigation} />
        )}
        renderFooterContent={() => (
          <InsightReportWizardControls
            initiativesCount={initiativesCount}
            metricsCount={metricsCount}
            objectivesCount={objectivesCount}
            reportId={reportId}
          />
        )}
      >
        <Steps.Item id={'objectives'}>
          <SelectableStrategyItems
            id={'objectives'}
            teamAdapter={teamAdapter}
            values={{
              objectiveIds: values.objectiveIds,
              metricIds: values.metricIds,
              initiativeIds: values.initiativeIds,
            }}
            onObjectiveChange={handleObjectivesChange}
            onMetricChange={handleMetricsChange}
            onInitiativeChange={handleInitiativesChange}
            onChangeSelectedCount={handleChangeSelectedCount}
            onSubmit={async ({ objectives: reportObjectives }) => {
              if (reportId) {
                if (!initialValues) return;

                const objectivesChanged = !areArraysEqual(
                  initialValues.objectiveIds,
                  values.objectiveIds,
                );

                const metricsChanged = !areArraysEqual(
                  initialValues.metricIds,
                  values.metricIds,
                );

                const initiativesChanged = !areArraysEqual(
                  initialValues.initiativeIds,
                  values.initiativeIds,
                );

                if (objectivesChanged || metricsChanged || initiativesChanged) {
                  await updateReport({
                    variables: {
                      input: {
                        idToUpdate: reportId,
                        reportObjectives,
                        reportStage: 'DRAFT',
                      },
                    },
                  });
                }
              } else {
                const result = await createReport({
                  variables: {
                    input: {
                      name: createReportName({
                        strategy,
                        creationDate: new Date(),
                      }),
                      owner: {
                        idToSet: owner?.id as string,
                      },
                      reportObjectives,
                      ...teamAdapter.toInput(),
                    },
                  },
                });

                setReportId(result.data?.addReport.id);

                if (result.data?.addReport) {
                  onCreateReport?.({ id: result.data.addReport.id });
                }
              }

              goToNextStep();
            }}
          />
        </Steps.Item>
        <Steps.Item id={'statusUpdate'}>
          <InsightReportWizardStatusUpdate
            report={reportData?.report}
            objectivesCount={values.objectiveIds.length || 0}
          />
        </Steps.Item>
        <Steps.Item id={'successes'} destroyOnLeave={true}>
          <Successes
            id={'successesForm'}
            reportId={reportData?.report.id}
            initialValues={values.successes}
            onChange={handleChangeSuccesses}
            onSubmit={(values) => {
              handleUpdateStatusComments({
                values,
                type: 'SUCCESS_COMMENT',
                onCompleted: () => completeStep('successes'),
              });
            }}
          />
        </Steps.Item>
        <Steps.Item id={'challenges'} destroyOnLeave={true}>
          <Challenges
            id={'challengesForm'}
            reportId={reportData?.report.id}
            initialValues={values.challenges}
            onChange={handleChangeChallenges}
            onSubmit={(values) => {
              handleUpdateStatusComments({
                values,
                type: 'CHALLENGE_COMMENT',
                onCompleted: () => completeStep('challenges'),
              });
            }}
          />
        </Steps.Item>
        <Steps.Item id={'actions'} destroyOnLeave={true}>
          <Actions
            id={'actionsForm'}
            reportId={reportData?.report.id}
            initialValues={values.actions}
            onChange={handleChangeActions}
            onSubmit={(values, _, submitOptions) => {
              handleUpdateStatusComments({
                values,
                type: 'ACTION_COMMENT',
                onCompleted: () => {
                  completeStep('actions');

                  if (reportId && submitOptions.updateReportStage) {
                    updateReport({
                      variables: {
                        input: {
                          idToUpdate: reportId,
                          reportStage: 'PUBLISHED',
                        },
                      },
                      onCompleted: ({ updateReport }) => {
                        addToast({
                          children: t(
                            'report.insightReportWizard.publishReportSuccessToast',
                          ),
                          variant: 'success',
                        });
                        onPublishReport?.({ id: updateReport.id });
                      },
                    });
                  }
                },
              });
            }}
          />
        </Steps.Item>
      </Modal>

      <ConfirmationDialog
        isOpen={hasStepsInProgress && isOpen && isConfirmationModalOpened}
        onClose={() => setIsConfirmationModalOpened(false)}
        onConfirm={onClose}
      >
        {t('report.insightReportWizard.confirmationModal.content')}
      </ConfirmationDialog>
    </>
  );
};

const InsightsReportWizardWithProviders = (props: InsightReportWizardProps) => (
  <StrategyProvider>
    <SubmissionProvider>
      <InsightReportWizardSteps>
        <InsightsReportWizardProvider>
          <InsightReportWizard {...props} />
        </InsightsReportWizardProvider>
      </InsightReportWizardSteps>
    </SubmissionProvider>
  </StrategyProvider>
);

export default InsightsReportWizardWithProviders;

function areArraysEqual(a?: string[], b?: string[]): boolean {
  return isEqual(sortBy(a), sortBy(b));
}
