import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Select from 'shared/components/Select';
import Text from 'shared/components/Text';
import type { Priority } from 'types.graphql.generated';

export type PriorityFieldValue = Pick<Priority, 'number' | 'label'>;

type SelectValue = {
  id: number;
  title: string;
};

type Props = {
  fullWidth?: boolean;
  hasError?: boolean;
  onChange: (value?: PriorityFieldValue) => void;
  priorities?: PriorityFieldValue[];
  value?: PriorityFieldValue;
};

const PrioritySelect = ({
  value,
  priorities,
  onChange,
  fullWidth,
  hasError,
}: Props) => {
  const { t } = useTranslation();

  const emptyOption = {
    id: -1,
    title: t('objective.objectiveForm.priority.emptyOptionLabel'),
  };

  const selectValue = value ? priorityToOption(value) : emptyOption;

  const priorityOptions = priorities?.map(priorityToOption) || [];
  const options = [emptyOption, ...priorityOptions];

  const handleChange = (value: Maybe<SelectValue>) => {
    const newValue = value
      ? value.id === emptyOption.id
        ? undefined
        : optionToPriority(value)
      : undefined;

    onChange(newValue);
  };

  return (
    <Select<SelectValue>
      fieldLabel={t('priority.priority')}
      triggerStyle={fullWidth ? undefined : css({ width: '20rem' })}
      fullWidth={fullWidth}
      value={selectValue}
      options={options}
      onChange={handleChange}
      placeholder={
        <Text>{t('objective.objectiveForm.priority.placeholder')}</Text>
      }
      hasError={hasError}
    />
  );
};

export default PrioritySelect;

const priorityToOption = (priority: PriorityFieldValue) => ({
  id: priority.number,
  title: priority.label,
});

const optionToPriority = (priority: SelectValue) => ({
  number: priority.id,
  label: priority.title,
});
