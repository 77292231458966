import type { SerializedStyles } from '@emotion/react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback, useMemo, useRef, type ReactNode } from 'react';
import type { Key } from 'react-aria-components';
import {
  Button,
  ListBox,
  ListBoxItem,
  Popover,
  Select as AriaSelect,
  SelectValue,
  ListBoxSection,
  Header,
} from 'react-aria-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TriangleIcon } from 'shared/static/icons/icon-triangle.svg';
import useDialogState from 'shared/hooks/useDialogState';
import useElementWidth from 'shared/hooks/useElementWidth';

const StyledButton = styled(Button)`
  all: unset;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 9.5px;
  height: 42px;

  border: 1px solid ${({ theme }) => theme.color.strokeDark};
  border-radius: 12px;

  background-color: ${({ theme }) => theme.color.white};

  user-select: none;

  &:focus-within {
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary} !important;
  }

  svg {
    height: 8.75px;
    width: 8.75px;
  }
`;

const StyledListBox = styled(ListBox)`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.neutral3};
  border-radius: 8px;
  box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.06);
  overflow-x: hidden;
  overflow-y: auto;
  max-height: inherit;
`;

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.color.typoSecondary};
  font-size: 0.75rem;
  font-weight: 700;
  padding: 6.5px 1rem;
  text-transform: uppercase;
  user-select: none;
`;

const StyledListBoxItem = styled(ListBoxItem)<{ $isSelected: boolean }>`
  padding: 6.5px 1rem;

  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.color.white : theme.color.black};

  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.color.primary : theme.color.white};

  border-radius: 8px;
  border: 2px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.color.primary : theme.color.white};

  &:focus-within {
    border-color: ${({ theme }) => theme.color.primary};
  }

  &:focus {
    outline: none;
  }
`;

type SelectOptionGroup = {
  id: string;
  title: string;
};

type SelectOption = {
  groupId?: string;
  id: string | number;
  title: string;
};

const emptyOptionId = 'empty';

type Props<T extends SelectOption> = {
  emptyOptionLabel?: string;
  fieldLabel: string;
  fullWidth?: boolean;
  groups?: SelectOptionGroup[];
  hasError?: boolean;
  isDisabled?: boolean;
  onChange: (value: Maybe<T>) => void;
  options: T[];
  placeholder?: ReactNode;
  renderOption?: (item: T) => ReactNode;
  triggerStyle?: SerializedStyles;
  value: Maybe<T>;
  valueLabel?: string;
};

const Select = <T extends SelectOption>({
  fieldLabel,
  valueLabel,
  value,
  onChange,
  options,
  groups,
  renderOption,
  triggerStyle,
  emptyOptionLabel,
  fullWidth,
  placeholder,
  hasError,
  isDisabled,
}: Props<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const triggerElementRef = useRef<HTMLButtonElement>(null);
  const triggerWidth = useElementWidth(triggerElementRef);

  const { isOpen, setIsOpen } = useDialogState();

  const emptyOption = useMemo(
    () =>
      emptyOptionLabel
        ? { id: emptyOptionId, title: emptyOptionLabel, groupId: undefined }
        : undefined,
    [emptyOptionLabel],
  );

  const allOptions = useMemo(
    () => [emptyOption, ...options].filter(Boolean),
    [emptyOption, options],
  );

  const handleSelectionChange = useCallback(
    (key: Key) => {
      if (key === emptyOptionId) {
        onChange(undefined);
      } else {
        const selectedOption = options.find((option) => option.id === key);
        onChange(selectedOption);
      }
    },
    [onChange, options],
  );

  const computedValueLabel = valueLabel
    ? valueLabel
    : value
    ? renderOption
      ? renderOption(value)
      : value.title
    : emptyOptionLabel || placeholder || t('none');

  const optionsByGroup = [undefined, ...(groups || [])]
    .map((group) => ({
      group,
      options: allOptions.filter((option) => option.groupId === group?.id),
    }))
    .filter(({ options }) => options.length > 0);

  return (
    <AriaSelect<T>
      aria-label={fieldLabel}
      selectedKey={value?.id}
      onSelectionChange={handleSelectionChange}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <StyledButton
        ref={triggerElementRef}
        css={[
          isOpen && css({ borderColor: theme.color.black }),
          fullWidth && css({ width: '100%' }),
          hasError && css({ borderColor: theme.color.error }),
          isDisabled &&
            css({
              borderColor: theme.color.strokeLight,
              backgroundColor: theme.color.hoverLight,
              pointerEvents: 'none',
            }),
          triggerStyle,
        ]}
      >
        <SelectValue>{computedValueLabel}</SelectValue>
        <TriangleIcon
          color={isDisabled ? theme.color.strokeDark : theme.color.black}
        />
      </StyledButton>

      <Popover offset={4}>
        <StyledListBox css={css({ minWidth: triggerWidth })}>
          {optionsByGroup.map(({ group, options }) => (
            <ListBoxSection key={group?.id || -1}>
              {group && <StyledHeader>{group.title}</StyledHeader>}

              {options.map((option) => (
                <StyledListBoxItem
                  key={option.id}
                  id={option.id}
                  textValue={option.title}
                  $isSelected={option.id === value?.id}
                >
                  {option === emptyOption
                    ? option.title
                    : renderOption
                    ? renderOption(option as T)
                    : option.title}
                </StyledListBoxItem>
              ))}
            </ListBoxSection>
          ))}
        </StyledListBox>
      </Popover>
    </AriaSelect>
  );
};

export default Select;
