import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { OrgUnitForDeleteFragmentDoc } from '../../../orgUnit/useDeleteOrgUnit/useDeleteOrgUnit.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyTeamsFromCurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTeamsFromCurrentUserQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: string, isInActiveOrg: boolean, org?: { __typename: 'Org', id: string, orgKey: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, employeesCount?: number | undefined, currentUserAuthorizedActions: Array<Types.OrgAuthorizedAction>, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, currentStrategy?: { __typename: 'Strategy', id: string } | undefined } | undefined, primaryOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> } | undefined } };

export type MyTeamsFromActiveOrgQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTeamsFromActiveOrgQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, orgKey: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, employeesCount?: number | undefined, currentUserAuthorizedActions: Array<Types.OrgAuthorizedAction>, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, currentStrategy?: { __typename: 'Strategy', id: string } | undefined } };

export type MyTeamsOrgFragment = { __typename: 'Org', id: string, orgKey: string, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, employeesCount?: number | undefined, currentUserAuthorizedActions: Array<Types.OrgAuthorizedAction>, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, currentStrategy?: { __typename: 'Strategy', id: string } | undefined };

export type MyTeamsOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> };

export type MyTeamsChildOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined, orgUnitMembershipsCascadingTotalCount: number, childOrgUnits: Array<{ __typename: 'OrgUnit', id: string }>, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }>, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export const MyTeamsChildOrgUnitFragmentDoc = gql`
    fragment myTeamsChildOrgUnit on OrgUnit {
  id
  name
  nameAbbreviated
  backgroundColorAsHex
  orgUnitMembershipsCascadingTotalCount
  childOrgUnits {
    id
  }
  childOrgUnitTree {
    id
  }
  parentOrgUnit {
    id
  }
}
    `;
export const MyTeamsOrgFragmentDoc = gql`
    fragment myTeamsOrg on Org {
  id
  orgKey
  displayName
  displayNameAbbreviated
  employeesCount
  currentUserAuthorizedActions
  orgUnits {
    ...myTeamsChildOrgUnit
    ...orgUnitForDelete
  }
  currentStrategy {
    id
  }
}
    ${MyTeamsChildOrgUnitFragmentDoc}
${OrgUnitForDeleteFragmentDoc}`;
export const MyTeamsOrgUnitFragmentDoc = gql`
    fragment myTeamsOrgUnit on OrgUnit {
  id
  name
  nameAbbreviated
  backgroundColorAsHex
  orgUnitMembershipsCascadingTotalCount
  childOrgUnits {
    ...myTeamsChildOrgUnit
  }
  childOrgUnitTree {
    id
  }
}
    ${MyTeamsChildOrgUnitFragmentDoc}`;
export const MyTeamsFromCurrentUserDocument = gql`
    query MyTeamsFromCurrentUser {
  currentUser {
    id
    isInActiveOrg
    org {
      ...myTeamsOrg
    }
    primaryOrgUnit {
      ...myTeamsOrgUnit
    }
  }
}
    ${MyTeamsOrgFragmentDoc}
${MyTeamsOrgUnitFragmentDoc}`;

/**
 * __useMyTeamsFromCurrentUserQuery__
 *
 * To run a query within a React component, call `useMyTeamsFromCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamsFromCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamsFromCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTeamsFromCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>(MyTeamsFromCurrentUserDocument, options);
      }
export function useMyTeamsFromCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>(MyTeamsFromCurrentUserDocument, options);
        }
export function useMyTeamsFromCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>(MyTeamsFromCurrentUserDocument, options);
        }
export type MyTeamsFromCurrentUserQueryHookResult = ReturnType<typeof useMyTeamsFromCurrentUserQuery>;
export type MyTeamsFromCurrentUserLazyQueryHookResult = ReturnType<typeof useMyTeamsFromCurrentUserLazyQuery>;
export type MyTeamsFromCurrentUserSuspenseQueryHookResult = ReturnType<typeof useMyTeamsFromCurrentUserSuspenseQuery>;
export type MyTeamsFromCurrentUserQueryResult = Apollo.QueryResult<MyTeamsFromCurrentUserQuery, MyTeamsFromCurrentUserQueryVariables>;
export const MyTeamsFromActiveOrgDocument = gql`
    query MyTeamsFromActiveOrg {
  activeOrg {
    ...myTeamsOrg
  }
}
    ${MyTeamsOrgFragmentDoc}`;

/**
 * __useMyTeamsFromActiveOrgQuery__
 *
 * To run a query within a React component, call `useMyTeamsFromActiveOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamsFromActiveOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamsFromActiveOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTeamsFromActiveOrgQuery(baseOptions?: Apollo.QueryHookOptions<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>(MyTeamsFromActiveOrgDocument, options);
      }
export function useMyTeamsFromActiveOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>(MyTeamsFromActiveOrgDocument, options);
        }
export function useMyTeamsFromActiveOrgSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>(MyTeamsFromActiveOrgDocument, options);
        }
export type MyTeamsFromActiveOrgQueryHookResult = ReturnType<typeof useMyTeamsFromActiveOrgQuery>;
export type MyTeamsFromActiveOrgLazyQueryHookResult = ReturnType<typeof useMyTeamsFromActiveOrgLazyQuery>;
export type MyTeamsFromActiveOrgSuspenseQueryHookResult = ReturnType<typeof useMyTeamsFromActiveOrgSuspenseQuery>;
export type MyTeamsFromActiveOrgQueryResult = Apollo.QueryResult<MyTeamsFromActiveOrgQuery, MyTeamsFromActiveOrgQueryVariables>;