import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { StrategyBoardViewSettingsContext } from './StrategyBoardViewSettings.context';
import { useViewSetting } from './useViewSetting';

const userVisibleKey = 'strategyBoardUserVisible';
const orgUnitVisibleKey = 'strategyBoardOrgUnitVisible';
const metricsVisibleKey = 'strategyBoardMetricsVisible';
const metricScoreVisibleKey = 'strategyBoardMetricScoreVisible';
const initiativesVisibleKey = 'strategyBoardInitiativesVisible';
const initiativeMilestonesVisibleKey =
  'strategyBoardInitiativeMilestonesVisible';
const actionsVisibleKey = 'strategyBoardActionsVisible';
const actionProgressVisibleKey = 'strategyBoardActionProgressVisible';

const StrategyBoardViewSettingsProvider = ({ children }: PropsWithChildren) => {
  const [isUserVisible, toggleUserVisible] = useViewSetting(
    userVisibleKey,
    true,
  );

  const [isOrgUnitVisible, toggleOrgUnitVisible] = useViewSetting(
    orgUnitVisibleKey,
    false,
  );

  const [isMetricsVisible, toggleMetricsVisible] = useViewSetting(
    metricsVisibleKey,
    true,
  );

  const [isMetricScoreVisible, toggleMetricScoreVisible] = useViewSetting(
    metricScoreVisibleKey,
    false,
  );

  const [isInitiativesVisible, toggleInitiativesVisible] = useViewSetting(
    initiativesVisibleKey,
    true,
  );

  const [isActionsVisible, toggleActionsVisible] = useViewSetting(
    actionsVisibleKey,
    false,
  );

  const [isActionProgressVisible, toggleActionProgressVisible] = useViewSetting(
    actionProgressVisibleKey,
    false,
  );

  const [isInitiativeMilestonesVisible, toggleInitiativeMilestonesVisible] =
    useViewSetting(initiativeMilestonesVisibleKey, false);

  const value = useMemo(
    () => ({
      isUserVisible,
      isOrgUnitVisible,
      isMetricsVisible,
      isMetricScoreVisible,
      isInitiativesVisible,
      isInitiativeMilestonesVisible,
      isActionsVisible,
      isActionProgressVisible,
      toggleUserVisible,
      toggleOrgUnitVisible,
      toggleMetricsVisible,
      toggleMetricScoreVisible,
      toggleInitiativesVisible,
      toggleInitiativeMilestonesVisible,
      toggleActionsVisible,
      toggleActionProgressVisible,
    }),
    [
      isActionProgressVisible,
      isActionsVisible,
      isInitiativeMilestonesVisible,
      isInitiativesVisible,
      isMetricScoreVisible,
      isMetricsVisible,
      isOrgUnitVisible,
      isUserVisible,
      toggleActionProgressVisible,
      toggleActionsVisible,
      toggleInitiativeMilestonesVisible,
      toggleInitiativesVisible,
      toggleMetricScoreVisible,
      toggleMetricsVisible,
      toggleOrgUnitVisible,
      toggleUserVisible,
    ],
  );

  return (
    <StrategyBoardViewSettingsContext.Provider value={value}>
      {children}
    </StrategyBoardViewSettingsContext.Provider>
  );
};

export default StrategyBoardViewSettingsProvider;
