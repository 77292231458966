import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import Flex from 'shared/components/Flex';
import TeamImage from 'team/TeamImage';
import TeamLink from 'team/TeamLink';
import UserAvatar from 'user/UserAvatar';
import MetricScoreContent from 'metric/MetricScore/MetricScoreContent';
import { ReactComponent as ChartIcon } from 'shared/static/icons/icon-chart.svg';
import { ReactComponent as ProcessIcon } from 'shared/static/icons/icon-process.svg';

import type {
  StrategyBoardInitiative,
  StrategyBoardItem,
  StrategyBoardMetric,
} from '../StrategyBoard.type';

const Column = styled.div`
  display: flex;
  justify-content: center;
  width: 22px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundDark};
  border-radius: 4px;

  width: 24px;
  height: 24px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

type Props = {
  item: StrategyBoardItem;
};

const StrategyBoardItemTooltip = ({ item }: Props) => {
  const { activeOrg } = useActiveOrg();

  const { owner, orgUnit } = item;

  const initiativeMilestoneAverageCompletion =
    item.__typename === 'Initiative'
      ? getInitiativeMilestoneAverageCompletion(item)
      : undefined;

  return (
    <Flex direction={'column'} gap={8} css={css({ margin: 4 })}>
      {item.__typename === 'Metric' && metricHasValue(item) && (
        <Flex alignItems={'center'} gap={14}>
          <Column>
            <IconContainer>
              <ChartIcon />
            </IconContainer>
          </Column>
          <MetricScoreContent metric={item} />
        </Flex>
      )}
      {item.__typename === 'Initiative' &&
        initiativeMilestoneAverageCompletion !== undefined && (
          <Flex alignItems={'center'} gap={14}>
            <Column>
              <IconContainer>
                <ProcessIcon />
              </IconContainer>
            </Column>
            {initiativeMilestoneAverageCompletion}
            {'%'}
          </Flex>
        )}
      {item.__typename === 'Action' &&
        item.currentStatus?.completionRate !== undefined && (
          <Flex alignItems={'center'} gap={14}>
            <Column>
              <IconContainer>
                <ProcessIcon />
              </IconContainer>
            </Column>
            {item.currentStatus.completionRate}
            {'%'}
          </Flex>
        )}
      {owner && (
        <Flex alignItems={'center'} gap={14}>
          <Column>
            <UserAvatar user={owner} showTooltip={false} />
          </Column>
          {owner.displayName && owner.displayName !== ''
            ? owner.displayName
            : owner.email}
        </Flex>
      )}
      {orgUnit !== undefined && (
        <Flex alignItems={'center'} gap={14}>
          <Column>
            <TeamLink orgUnit={orgUnit}>
              <TeamImage orgUnit={orgUnit} size={'micro'} />
            </TeamLink>
          </Column>
          {orgUnit ? orgUnit.name : activeOrg.displayName}
        </Flex>
      )}
    </Flex>
  );
};

export default StrategyBoardItemTooltip;

export function metricHasValue(item: StrategyBoardMetric) {
  return (
    (item.startValue !== undefined && item.startValue !== null) ||
    (item.targetValue !== undefined && item.targetValue !== null) ||
    (item.currentMetricStatus?.statusValue !== undefined &&
      item.currentMetricStatus?.statusValue !== null)
  );
}

export function getInitiativeMilestoneAverageCompletion(
  item: StrategyBoardInitiative,
) {
  return item.currentInitiativeStatus &&
    item.currentInitiativeStatus.milestoneStatuses.length > 0
    ? Math.round(
        item.currentInitiativeStatus.milestoneStatuses.reduce(
          (result, milestone) => result + (milestone.completionRate || 0),
          0,
        ) / item.currentInitiativeStatus.milestoneStatuses.length,
      )
    : 0;
}
