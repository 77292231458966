import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import type { AssessmentCategory } from 'assessment/assessment.type';
import AssessmentCategoryImage from 'assessment/AssessmentCategoryImage';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';
import { getAssessmentCategoryName } from 'assessment/assessment.utils';

const Card = styled(Flex)`
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.strokeLight};
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px rgba(65, 67, 80, 0.08);
  width: 386px;
`;

type Props = {
  category: AssessmentCategory;
  onPress: () => void;
};

const AssessmentCategoryCard = ({ category, onPress }: Props) => {
  const { t } = useTranslation();

  const categoryName = getAssessmentCategoryName(t, category);

  return (
    <Card direction={'column'} gap={20} onClick={onPress}>
      <AssessmentCategoryImage type={category} />
      <Text size={'big'} color={'black'} isBold={true} isCentered={true}>
        {categoryName}
      </Text>
    </Card>
  );
};

export default AssessmentCategoryCard;
