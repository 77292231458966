import { css } from '@emotion/react';

import UserAvatar from 'user/UserAvatar';
import { useUser } from 'user/UserProvider';

import ChatBubble from './ChatBubble';
import { Spacer } from './SummaryMessageItem';
import type { InputMessage } from './AskSophieChat.type';

type Props = {
  message: InputMessage;
};

const InputMessageItem = ({ message }: Props) => {
  const { user } = useUser();

  return (
    <>
      <Spacer />
      <ChatBubble $messageType={'InputMessage'}>{message.content}</ChatBubble>
      <div css={css({ marginTop: 9 })}>
        <UserAvatar user={user} size={'big'} />
      </div>
    </>
  );
};

export default InputMessageItem;
