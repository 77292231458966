import { ReactComponent as FlagIcon } from 'shared/static/icons/icon-flag.svg';
import type { ObjectiveStatus, OrgUnit, Theme } from 'types.graphql.generated';

import type {
  StrategyObjectivesObjectiveFragment,
  StrategyObjectivesThemeFragment,
} from '../strategy/StrategyObjectives/StrategyObjectivesProvider/StrategyObjectivesProvider.graphql.generated';

export type ObjectivesStatsActive = {
  atRisk: number;
  blocked: number;
  noStatus: number;
  onTrack: number;
};

export const getObjectiveIcon = () => FlagIcon;

export type ObjectivesByTheme<
  Objective extends { id: string; theme?: { id: string } },
  Theme extends { id: string },
> = {
  objectives: Objective[];
  theme?: Theme;
};

type FilteredObjectives = {
  objectives: Omit<StrategyObjectivesObjectiveFragment, 'theme'>[];
  theme?: StrategyObjectivesThemeFragment | undefined;
};

type filterObjectivesFnc = (
  objectivesByTheme: Array<FilteredObjectives>,
  currentThemes: Array<Theme['id'] | null>,
) => Array<FilteredObjectives>;

export const filterObjectives: filterObjectivesFnc = (
  objectivesByTheme,
  currentThemes,
) =>
  objectivesByTheme.filter(({ theme }) => {
    if (
      !currentThemes.length ||
      currentThemes.includes(theme?.id as string) ||
      (!theme && currentThemes.includes(null))
    ) {
      return true;
    }
    return false;
  });

export const groupObjectivesByTheme = <
  Objective extends { id: string; theme?: Theme },
  Theme extends { id: string; orderNumber?: Maybe<number> },
>(
  objectives: Objective[],
  alwaysIncludeNoTheme = false,
  initialThemes: Theme[] = [],
): Array<ObjectivesByTheme<Objective, Theme>> => {
  const noTheme = { theme: undefined, objectives: [] };

  const initialAccumulator = initialThemes.reduce<
    Record<string | 'noTheme', { objectives: Objective[]; theme?: Theme }>
  >(
    (accumulator, theme) => ({
      ...accumulator,
      [theme.id]: { theme, objectives: [] },
    }),
    {},
  );

  const values = Object.values(
    objectives.reduce<
      Record<string | 'noTheme', { objectives: Objective[]; theme?: Theme }>
    >(
      (accumulator, objective) => {
        const objectivesByTheme = objective.theme
          ? accumulator[objective.theme.id] || {
              theme: objective.theme,
              objectives: [],
            }
          : accumulator.noTheme || noTheme;

        return {
          ...accumulator,
          [objective.theme?.id || 'noTheme']: {
            ...objectivesByTheme,
            objectives: [...objectivesByTheme.objectives, objective],
          },
        };
      },
      alwaysIncludeNoTheme
        ? { ...initialAccumulator, noTheme }
        : initialAccumulator,
    ),
  );

  return values;
};

export const sortObjectivesByTheme = <
  Objective extends { id: string; theme?: { id: string } },
  Theme extends {
    id: string;
    isActive: boolean;
    orderNumber?: Maybe<number>;
    orgUnit?: { id: string };
  },
  SpecificObjectivesByTheme extends ObjectivesByTheme<Objective, Theme>,
>(
  objectivesByTheme: SpecificObjectivesByTheme[],
  bottomTopOrgUnitTree: Pick<OrgUnit, 'id'>[],
  noThemeFirst = false,
): SpecificObjectivesByTheme[] => {
  const topBottomParentOrgUnitTreeIds = [
    undefined,
    ...[...bottomTopOrgUnitTree].reverse().map((orgUnit) => orgUnit.id),
  ];

  return [...objectivesByTheme].sort((previousValue, nextValue) => {
    // Handle missing theme
    if (!previousValue.theme) return noThemeFirst ? -1 : 1;
    if (!nextValue.theme) return noThemeFirst ? 1 : -1;

    const previousOrgUnitIndex = topBottomParentOrgUnitTreeIds.indexOf(
      previousValue.theme.orgUnit?.id,
    );
    const nextOrgUnitIndex = topBottomParentOrgUnitTreeIds.indexOf(
      nextValue.theme.orgUnit?.id,
    );

    // Handle org unit not in hierarchy
    if (previousOrgUnitIndex === -1) return 1;
    if (nextOrgUnitIndex === -1) return -1;

    // Handle different org units
    if (previousOrgUnitIndex !== nextOrgUnitIndex) {
      return previousOrgUnitIndex > nextOrgUnitIndex ? 1 : -1;
    }

    // Handle archived
    if (!previousValue.theme.isActive) return 1;
    if (!nextValue.theme.isActive) return -1;

    // Handle order number
    const previousOrderNumber = previousValue.theme.orderNumber;
    const nextOrderNumber = nextValue.theme.orderNumber;

    if (
      previousOrderNumber !== undefined &&
      previousOrderNumber !== null &&
      nextOrderNumber !== undefined &&
      nextOrderNumber !== null &&
      previousOrderNumber !== nextOrderNumber
    ) {
      return previousOrderNumber > nextOrderNumber ? 1 : -1;
    }

    return 0;
  });
};

export const getObjectivesThemes = <
  ITheme extends Pick<Theme, 'id' | '__typename' | 'isActive'> & {
    objectives: IObjective[];
  },
  IObjective extends { theme?: ITheme },
>(
  objectives: Maybe<IObjective[]>,
) => [
  ...new Set(
    objectives?.reduce<ITheme[]>(
      (acc, { theme }) => (theme ? [...acc, theme] : acc),
      [],
    ),
  ),
];

export const getObjectivesActive = <
  TObjective extends {
    currentObjectiveStatus?: Pick<ObjectiveStatus, 'complete'>;
  },
>(
  objectives: Maybe<TObjective[]>,
) =>
  objectives?.filter(
    ({ currentObjectiveStatus }) => !currentObjectiveStatus?.complete,
  );

export const getObjectivesStatsActive = <
  TStatus extends Pick<ObjectiveStatus, 'statusIndicator'>,
  TObjective extends { currentObjectiveStatus?: TStatus },
>(
  objectives: Maybe<TObjective[]>,
) => {
  const initialStats = {
    blocked: 0,
    atRisk: 0,
    onTrack: 0,
    noStatus: 0,
  };

  return objectives
    ? objectives.reduce<ObjectivesStatsActive>(
        (acc, { currentObjectiveStatus }) => ({
          noStatus: currentObjectiveStatus?.statusIndicator.value
            ? acc.noStatus
            : acc.noStatus + 1,
          blocked:
            currentObjectiveStatus?.statusIndicator.value === 'BLOCKED'
              ? acc.blocked + 1
              : acc.blocked,
          atRisk:
            currentObjectiveStatus?.statusIndicator.value === 'AT_RISK'
              ? acc.atRisk + 1
              : acc.atRisk,
          onTrack:
            currentObjectiveStatus?.statusIndicator.value === 'ON_TRACK'
              ? acc.onTrack + 1
              : acc.onTrack,
        }),
        initialStats,
      )
    : initialStats;
};
