import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import {
  getAssessmentTypeDescription,
  getAssessmentTypeTitle,
} from 'assessment/assessment.utils';
import AssessmentImage from 'assessment/AssessmentImage';
import type { StrategyAssessmentType } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.white};
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  padding: 20px;
  width: 346px;
`;

const ImageWrapper = styled.div`
  border-radius: 8px;

  svg {
    width: 100%;
  }
`;

type Props = {
  type: StrategyAssessmentType;
};

const AssessmentInputMessageContent = ({ type }: Props) => {
  const { t } = useTranslation();

  return (
    <Container direction={'column'} gap={20}>
      <ImageWrapper>
        <AssessmentImage type={type} />
      </ImageWrapper>

      <Flex direction={'column'} gap={'8px'}>
        <Text color={'black'} isBold={true} isUppercase={true}>
          {getAssessmentTypeTitle(t, type)}
        </Text>
        <Text color={'black'}>{getAssessmentTypeDescription(t, type)}</Text>
      </Flex>
    </Container>
  );
};

export default AssessmentInputMessageContent;
