import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { uniqBy } from 'lodash';

import { useTeamAdapter } from 'team/TeamAdapter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';
import { sortObjectivesByTheme } from 'objective/objective.utils';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter/useCollaboratingTeamsFilter';
import { useStatusFilter } from 'shared/filters/StatusFilter';
import { useUserFilter } from 'shared/filters/UserFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';
import { useThemeFilter } from 'shared/filters/ThemeFilter';

import { getStrategyThemes } from '../StrategyBoard.utils';
import type { StrategyBoardContextValue } from './StrategyBoardProvider.context';
import { StrategyBoardContext } from './StrategyBoardProvider.context';
import {
  useStrategyBoardOrgQuery,
  useStrategyBoardOrgUnitQuery,
} from './StrategyBoardProvider.graphql.generated';
import {
  filterThemesByStatusIndicator,
  filterThemesByDates,
  getObjectivesOwners,
  filterThemesByUser,
  filterThemesByPriority,
  filterThemesByCollaboratingTeams,
  filterThemesByCompleted,
  filterThemesByTheme,
} from './StrategyBoardProvider.utils';

type StrategyBoardProviderProps = PropsWithChildren<object>;

const StrategyBoardProvider = ({ children }: StrategyBoardProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const { orgUnit } = useOrgUnit();

  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const onError = useHandleError();

  const { data: strategyBoardOrgData, loading: isStrategyBoardOrgLoading } =
    useStrategyBoardOrgQuery({
      skip: !teamAdapter.isOrg,
      onError,
    });

  const {
    data: strategyBoardOrgUnitData,
    loading: isStrategyBoardOrgUnitLoading,
  } = useStrategyBoardOrgUnitQuery({
    variables: { orgUnitId: teamAdapter.keyArg },
    skip: teamAdapter.isOrg,
    onError,
  });

  const objectives = useMemo(
    () =>
      (teamAdapter.isOrg
        ? strategyBoardOrgData?.activeOrg
        : strategyBoardOrgUnitData?.orgUnit
      )?.objectives.edges.map((edge) => ({
        ...edge.node,
        isFiltersMatch: true,
      })) || [],
    [
      strategyBoardOrgData?.activeOrg,
      strategyBoardOrgUnitData?.orgUnit,
      teamAdapter.isOrg,
    ],
  );

  const { selectedThemeIds, setAvailableThemes } = useThemeFilter();
  const { statuses } = useStatusFilter();
  const { priorities } = usePriorityFilter();

  const { selectedUserIds, setAvailableUsers } = useUserFilter();

  const { startDate, endDate } = useTimelineFilter();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  useEffect(() => {
    setAvailableUsers(getObjectivesOwners(objectives));
  }, [objectives, setAvailableUsers]);

  useEffect(() => {
    const themes = uniqBy(
      objectives.map((objective) => objective.theme).filter(Boolean),
      'id',
    );

    setAvailableThemes(themes);
  }, [objectives, setAvailableThemes]);

  const contextValue = useMemo<StrategyBoardContextValue>(() => {
    const filteredObjectives = filterThemesByCollaboratingTeams(
      filterThemesByTheme(
        filterThemesByUser(
          filterThemesByDates(
            filterThemesByDates(
              filterThemesByCompleted(
                filterThemesByStatusIndicator(
                  filterThemesByPriority(objectives, priorities),
                  statuses,
                ),
                showCompleted,
              ),
              startDate,
              endDate,
            ),
            calendarIntervalStartDate,
            calendarIntervalEndDate,
            calendarCheck,
          ),
          selectedUserIds,
        ),
        selectedThemeIds,
      ),
      showCollaboratingTeams,
      orgUnit?.id,
    ).filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );

    const sortedAndFilteredObjectives = orderBy(
      filteredObjectives,
      ['theme', 'theme.isActive', 'theme.orderNumber'],
      ['asc', 'desc', 'asc'],
    );

    const themes = getStrategyThemes(sortedAndFilteredObjectives).filter((t) =>
      t.theme ? t.theme.isActive : true,
    );

    const sortedThemes = sortObjectivesByTheme(
      themes,
      strategyBoardOrgUnitData
        ? [
            ...strategyBoardOrgUnitData.orgUnit.parentOrgUnitTree,
            strategyBoardOrgUnitData.orgUnit,
          ].reverse()
        : [],
    );

    return {
      isStrategyBoardLoading: teamAdapter.isOrg
        ? isStrategyBoardOrgLoading && !strategyBoardOrgData
        : isStrategyBoardOrgUnitLoading && !strategyBoardOrgUnitData,
      objectives,
      themes: sortedThemes,
    };
  }, [
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    endDate,
    isStrategyBoardOrgLoading,
    isStrategyBoardOrgUnitLoading,
    objectives,
    orgUnit?.id,
    priorities,
    selectedThemeIds,
    selectedUserIds,
    showCollaboratingTeams,
    showCompleted,
    startDate,
    statuses,
    strategyBoardOrgData,
    strategyBoardOrgUnitData,
    teamAdapter.isOrg,
  ]);

  if (isPrioritiesLoading) return <Spinner.Circle />;

  return (
    <StrategyBoardContext.Provider value={contextValue}>
      {children}
    </StrategyBoardContext.Provider>
  );
};

export default StrategyBoardProvider;
